import React, {useEffect, useState} from 'react'
import CheckoutFSBig from './SpecificDevices/CheckoutFSBig'
import {useDispatch, useSelector} from "react-redux";
import {updateCart} from "../../Store/reducers/shoppingCartReducer";
import CheckoutFSSmall from "./SpecificDevices/CheckoutFSSmall";
import {brandIDs} from "../../data/Store/storeData";

export default function CheckoutFS() {
    const dispatcher = useDispatch();
    const {baskets, unverifiedBasket, ingredientsInCart, recipesInCart} = useSelector(state => state.cart);
    const screen = useSelector(state => state.global.mobileScreen);
    const [company, setCompany] = useState("AMAZON");
    const [brandID, setBrandID] = useState("QW1hem9uIEZyZXNo")
    useEffect(() => {
        console.log(company)
        updateCart(dispatcher, recipesInCart, company)
        setBrandID(brandIDs[company])
    }, [recipesInCart, dispatcher, company])


    if (screen) {
        return (<CheckoutFSBig baskets={baskets} unverifiedBasket={unverifiedBasket} ingredientList={ingredientsInCart}
                               recipes={recipesInCart}/>)
    }
    return (
        <CheckoutFSSmall baskets={baskets} unverifiedBasket={unverifiedBasket} ingredientList={ingredientsInCart}
                         recipes={recipesInCart} setCompany={setCompany} company={company}
                         brandID={brandID}/>
    )
}
