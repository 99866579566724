import { debugOn } from '../data/constants';

export function debug(message, debugOverride) {
    if(debugOverride){
        console.groupCollapsed(message);
        console.trace();
        console.groupEnd();
    }else if(debugOn)
    {
        console.groupCollapsed(message);
        console.trace();
        console.groupEnd();
    }
}