import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {IconButton} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import {useSelector} from "react-redux";
import {Search} from "@material-ui/icons";
const useStyles = makeStyles(theme => ({

    holder: {
        display: 'flex',
        flexDirection: 'row',
    },
    right: {
        height: 'fit-content',
        flex: 1,
        right: 0,
        flexDirection: 'row',
        marginRight: 0,
        paddingLeft: 20,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 'auto',
    },
    qty: {
        width: '40px',
        paddingRight: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    name: {
        width: '50px',
    },
    qtyType: {
        width: '60px',
    },
    icon: {
        padding: 0,
    },
    invalid:{
      backgroundColor: "#ff9999",
        borderRadius:"4px",
    }
}));

export default function NewEditIngredientBig({ingredient, setEditingIngredient, updateRecipe, valid}) {

    const classes = useStyles();
    const qtyTypes = useSelector(state => state.global.qtyTypes)




    function capitalize(str) {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function handleQty(qty) {
        if ((typeof qty === 'string' || qty instanceof String) && (qty.endsWith('.') || qty === '')) {
            return (qty)
        }
        qty = parseFloat(qty)
        //No DP if more than 10 whats the point?!
        if (qty > 10) {
            qty = Math.round((qty + Number.EPSILON))
        }
        //if less than 10 2dp
        else if (qty < 10) {
            qty = Math.round((qty + Number.EPSILON) * 100) / 100
        }
        return qty
    }

    return (
        <div className={`${classes.holder} ${valid? "": classes.invalid}`} >
            <div className={classes.name}>
                <Typography className={classes.left}
                            variant="body1">{capitalize(ingredient.name)}</Typography>
            </div>
            <div className={classes.right}>
                <div className={classes.qty}>
                    <TextField value={handleQty(ingredient.qty)}
                               onChange={x => updateRecipe({
                                   id: ingredient.ingredientId,
                                   qty: x.target.value,
                                   qtyType: ingredient.qtyType
                               }, true)}/>
                </div>
                {(<TextField
                    disabled={true}
                    className={classes.qtyType}
                    value={qtyTypes.filter(x=> x.qtyType ===ingredient.qtyType )[0].qtyTypeNice}
                    inputProps={{'aria-label': 'Without label'}}
                />)}
                <IconButton className={classes.icon} aria-label="Delete"
                            onClick={() => updateRecipe({delete: true, id: ingredient.ingredientId}, true)}>
                    <Delete/>
                </IconButton>
                <IconButton className={classes.icon} aria-label="Search"
                            onClick={()=>setEditingIngredient(ingredient)}>
                    <Search/>
                </IconButton>
            </div>
        </div>
    )


}
