import React, {useState} from 'react'
import Creatable from 'react-select/creatable'
import makeAnimated from 'react-select/animated'

import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
    addIngredient,
    relinkIngredient,
    searchIngredients,
    updateIngredient
} from '../../../data/Ingredient/ingredientData'

const animatedComponents = makeAnimated();

const useStyles = makeStyles(theme => ({
    card: {
        width: 345,
        margin: 10
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));




export default function EditIngredient({ingredient, refresh,qtyTypes}) {
    const classes = useStyles();
    const id = ingredient.id;
    const [name, setName] = useState(ingredient.name);
    const [names, setNames] = useState(ingredient.names);
    const [mlToGram, setMlToGram] = useState(ingredient.mlToGram);
    const [weightPerItem, setWeightPerItem] = useState(ingredient.weightPerItem);
    const [labels, setLabels] = useState(ingredient.labels || []);
    const [amazonBasicID, setAmazonBasicID] = useState(ingredient.amazonBasicId || null);
    const [amazonBasicQty, setAmazonBasicQty] = useState(ingredient.amazonBasicQTY || null);
    const [amazonBasicQtyType, setAmazonBasicQtyType] = useState(ingredient.amazonBasicQTYType || null);
    const [morissonsBasicID, setMorissonsBasicID] = useState(ingredient.morissonsBasicId || null);
    const [morissonsBasicQty, setMorissonsBasicQty] = useState(ingredient.morissonsBasicQTY || null);
    const [morissonsBasicQtyType, setMorissonsBasicQtyType] = useState(ingredient.morissonsBasicQTYType || null);
    const [selectedIng, setSelected] = useState({ingredientName: "Test", ingredientId: "Poo"})
    const [options, setOptions] = useState([])

    function createIngredient(){
        return {
            name: name,
            names: getNames(),
            labels: labels,
            amazonBasicId: amazonBasicID,
            amazonBasicQTY: amazonBasicQty,
            amazonBasicQTYType:amazonBasicQtyType,
            morissonsBasicId: morissonsBasicID,
            morissonsBasicQTY: morissonsBasicQty,
            morissonsBasicQTYType: morissonsBasicQtyType,
            weightPerItem:weightPerItem,
            mlToGram: mlToGram,
            validated: true
        }
    }

    function getNames(){
        let x = new Set()
        x.add(name);
        if(names){
            x = new Set(names.concat(name))
        }    
        
        return Array.from(x)
    }

    function saveUpdate() {
        if (!id) {
            return (
                <IconButton aria-label="Save" onClick={()=>addIngredient(createIngredient(),refresh)}>
                    <SaveIcon />
                </IconButton>
            )
        }
        else {
            return (
                <IconButton aria-label="Update" onClick={() => updateIngredient(id, createIngredient(),refresh)}>
                    <SaveIcon />
                </IconButton>
            )
        }
    }

    return (
        <Card variant="outlined" className={classes.card} >
            <TextField label="ingredient name" value={name} onChange={(event) => { setName(event.target.value.toUpperCase()); }} />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Synonyms
                    </Typography>
                <Creatable closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={!names ? null: names.map((data, idx) => ({ label: data, value: idx }))}
                    onChange={(event) => setNames(event ? event.map((x) => (x.label.toUpperCase())) : [])}
                    isMulti
                />
                <TextField type="number" label="ml->gram" value={mlToGram} onChange={(event) => { setMlToGram(event.target.value)}} />
                <TextField type="number" label="weight per item" value={weightPerItem} onChange={(event) => { setWeightPerItem(event.target.value)}} />

                <TextField type="text" label="Amazon Id" value={amazonBasicID} onChange={(event) => { setAmazonBasicID(event.target.value)}} />
                <TextField type="number" label="Amazon Qty" value={amazonBasicQty} onChange={(event) => { setAmazonBasicQty(event.target.value)}} />
                
                <Typography variant="body2" color="textSecondary" component="p">
                    Amazon Qty Type
                </Typography>
                <Select
                    labelId="Amazon Qty Type"
                    value={amazonBasicQtyType}
                    onChange={(e) => setAmazonBasicQtyType(e.target.value)}
                >
                    {Object.entries(qtyTypes).map((x) => (<MenuItem  key={x[0]} value={x[0]}>{x[1]}</MenuItem>))}
                </Select>
                <br/>
                <TextField type="text" label="morissons Id" value={morissonsBasicID} onChange={(event) => { setMorissonsBasicID(event.target.value)}} />
                <TextField type="number" label="morissons Qty" value={morissonsBasicQty} onChange={(event) => { setMorissonsBasicQty(event.target.value)}} />

                <Typography variant="body2" color="textSecondary" component="p">
                    morissons Qty Type
                </Typography>
                <Select
                    labelId="morissons Qty Type"
                    value={morissonsBasicQtyType}
                    onChange={(e) => setMorissonsBasicQtyType(e.target.value)}
                >
                    {Object.entries(qtyTypes).map((x) => (<MenuItem  key={x[0]} value={x[0]}>{x[1]}</MenuItem>))}
                </Select>
                <Typography variant="body2" color="textSecondary" component="p">
                    Labels
                    </Typography>
                <Creatable closeMenuOnSelect={true}
                    components={animatedComponents}
                    defaultValue={labels.map((data, idx) => ({ label: data, value: idx }))}
                    isMulti
                    onChange={(event) => setLabels(event ? event.map((x) => (x.label.toUpperCase())) : [])}
                />
                <CardActions disableSpacing>
                    {saveUpdate()}
                </CardActions>

                <Creatable closeMenuOnSelect={true}
                           components={animatedComponents}
                           value={{label: selectedIng.name, value: selectedIng}}
                           onInputChange={(e) => {
                               if (e.length > 2) searchIngredients(e).then((x) => {
                                   if (x.length > 0) {
                                       setOptions(x);
                                       setSelected(x[0])
                                   }
                               })
                           }}
                           options={options.map((ing) => ({label: ing.name, value: ing}))}
                           onChange={(e) => {
                               setSelected(e.value)
                           }}
                />
                <CardActions disableSpacing>
                    <IconButton aria-label="Save" onClick={()=>relinkIngredient(id,selectedIng.id)}>
                        <SaveIcon />
                    </IconButton>
                </CardActions>

            </CardContent>
        </Card>
    )

}
