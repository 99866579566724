import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {searchRecipeDebounce} from "../../../data/Recipe/RecipeHelper";
import {useLocation} from "react-router-dom";
import {SearchForRecipe} from "../../../Store/reducers/recipeReducer";
import ChipInput from "material-ui-chip-input";
import {addAlert} from "../../../Store/reducers/alertReducer";
import {Box} from "@material-ui/core";
import {USER} from "../../../Store/reducers/globalReducer";

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    background: {
        width: "100%",
        backgroundColor: '#f5f5f5'
    }

}));


export default function SearchBar({userID}) {
    const classes = useStyles();
    const location = useLocation();
    const dispatcher = useDispatch()
    const searchText = useSelector(state => state.recipe.searchText)
    const recipeUser = useSelector(state => state.global.recipeUser)


    useEffect(() => {
            const searchParams = queryString.parse(location.search);
            if (searchParams.type) {
                dispatcher({type: SearchForRecipe, payload: searchParams})
            } else {
                searchRecipeDebounce("", dispatcher, userID)
            }
        }
        , [location, userID, dispatcher])


    function handleAddChip(chip) {
        if(recipeUser===USER){
            addAlert(dispatcher,"We currently do not support user search! Don't worry, this will be added soon", "error")
        }
        else if (searchText) {
            addAlert(dispatcher, "Currently we only support 1 search term, please delete before searching", "error")
        } else {
            searchRecipeDebounce(chip, dispatcher, userID)
        }
    }

    function handleDeleteChip(chip, index) {
        if (searchText) {
            searchRecipeDebounce("", dispatcher, userID)
        }
        console.log("chip Delete")
    }

    return (
        <Box style={{display:"flex", flexDirection:"column"}}>
        <ChipInput className={classes.background}
                   label={"Search"}
                   allowDuplicates={true}
                   value={searchText ? [searchText] : []}
                   onAdd={(chip) => handleAddChip(chip)}
                   onDelete={(chip, index) => handleDeleteChip(chip, index)}
        />
        </Box>
    )
}