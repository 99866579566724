import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addRecipeToCart, removeItemFromCart, UpdateItemInCart} from "../../../Store/reducers/shoppingCartReducer";
import {selectRecipe} from "../../../data/Recipe/RecipeHelper";
import PresentableRecipeCardBig from "./SpecificDevice/PresentableRecipeCardBig";
import {EditRecipeInRecipes} from "../../../Store/reducers/recipeReducer";
import {Delete, ScreenShare, StopScreenShare} from "@material-ui/icons";
import {deleteRecipe, togglePubicPrivate} from "../../../data/Recipe/recipeData";
import {addAlert} from "../../../Store/reducers/alertReducer";
import PresentableRecipeCardSmall from "./SpecificDevice/PresentableRecipeCardSmall";
import CheckoutRecipeCardBig from "./SpecificDevice/CheckoutRecipeCardBig";
import {clearAndNav, nav} from "../../../helper/navHelper";

export default function PresentableRecipe({recipe, checkout, size}) {
    const inBasket = useSelector(state => state.cart.recipesInCart.some(e => e.id === recipe.id));
    const screen = useSelector(state=> state.global.mobileScreen);
    const user = useSelector(state => state.global.user)
    const dispatcher = useDispatch();
    const {id} = recipe
    const history = useHistory();
    const [commands, setCommands] = useState([]);

    useEffect(() => {
        let tempCommands = []

        if (user && user.sub === recipe.userId) {
            if (recipe.madePublic) {
                tempCommands.push({
                    title: "Make Private",
                    conf: "Are you sure you want to make this recipe private?",
                    icon: <StopScreenShare/>,
                    method: () => togglePubicPrivate(recipe).then((x) => {
                        if(x) {
                            addAlert(dispatcher, "Recipe Made Private", "info")
                        }
                        else {
                            addAlert(dispatcher, "Unable to make recipe private due to", "error")
                        }

                    })
                });
            } else {
                tempCommands.push({
                    title: "Make Public",
                    conf: "Are you sure you want to make this recipe public? This must be your own recipe, and not be subject to copyright!",
                    icon: <ScreenShare/>,
                    method: () => togglePubicPrivate(recipe).then((x) => {
                        if(x) {
                            addAlert(dispatcher, "Recipe Made Public", "info")
                        }
                        else {
                            addAlert(dispatcher, "Unable to make recipe public due to", "error")
                        }

                    })
                })
            }
            tempCommands.push({
                title: "Delete",
                conf: "Are you sure you want to delete this recipe?",
                icon: <Delete/>,
                method: (x) => deleteRecipe(x).then(() => {
                    history.push({pathname: '/MyProfile'})
                    addAlert(dispatcher, "deleted Recipe", "info")
                })
            })
        }

        setCommands(tempCommands)

    }, [recipe, dispatcher, history, screen, user])

    function recipeClick() {
        selectRecipe(id, recipe.reqPortions, dispatcher)
        const params = {recipeId: id, reqPortions: recipe.reqPortions}
        nav(dispatcher, "/Recipe", recipe, params)
    }

    function userClick(id) {
        const params = {id:id}
        clearAndNav(dispatcher, "/User", null, params)
    }

    function basketClick() {
        if (inBasket) {
            removeItemFromCart(dispatcher, recipe.id)
        } else {
            addRecipeToCart(dispatcher, recipe.id, recipe.reqPortions)
        }
    }

    function update(value) {
        if (inBasket) {
            dispatcher({type: UpdateItemInCart, payload: {...value, id: recipe.id}})
        }
        dispatcher({type: EditRecipeInRecipes, payload: {...value, id: recipe.id}})
    }


    if (checkout) {
        return (<div>
            <CheckoutRecipeCardBig size={size} commands={commands} recipe={recipe} inBasket={inBasket}
                                   basketClick={basketClick}
                                   recipeClick={recipeClick} update={update}/>
        </div>)
    } else {
        return (
            <div>
                {screen && (
                    <PresentableRecipeCardBig commands={commands} recipe={recipe} inBasket={inBasket}
                                              basketClick={basketClick}
                                              recipeClick={recipeClick}
                                              userClick={userClick}
                                              update={update}/>)}
                {!screen && (<PresentableRecipeCardSmall commands={commands} recipe={recipe} inBasket={inBasket}
                                                         basketClick={basketClick}
                                                         recipeClick={recipeClick}
                                                         userClick={userClick}
                                                         update={update}/>)}
            </div>
        )
    }
}
