import {get, put, post, deleteRequest} from '../../helper/restHelper';
import {jsonHeader} from '../constants'
import { debug } from '../../helper/logHelper';
let debugOn = false;


export async function  pullQtys() {
    try {
        debug("Pulling Qtys",debugOn)
        let data = (await get('QtyType')).json()
        debug(data,debugOn)
        return(data)
    } catch (error) {
        console.log(error)
    }
}

export async function  pullConversionFactors() {
    try {
        debug("Pulling ConversionFactors",debugOn)
        let data = (await get('ConversionFactors')).json()
        debug(data,debugOn)
        return(data)
    } catch (error) {
        console.log(error)
    }
}

export async function  validateIngredients(ingredientString) {
    debug("validate Ingredient", debugOn)
    debug(ingredientString, debugOn)
    let data = (await post('ValidateIngredients', ingredientString, jsonHeader)).json()
    debug(data,debugOn)
    return(data)
}

export async function updateIngredient(id, ingredient, refresh) {
    ingredient['id'] = id
    debug("Updating Ingredient", debugOn)
    debug(ingredient, debugOn)
    await post('Ingredient', JSON.stringify(ingredient), jsonHeader)
    refresh()
}

export async function addIngredient(ingredient) {
    debug("add Ingredient", debugOn)
    //debug(ingredient, debugOn)
    await put('Ingredient', JSON.stringify(ingredient), jsonHeader)
}

export async function deleteIngredient(id) {
    debug("delete Ingredient", debugOn)
    //debug(ingredient, debugOn)
    let data = (await deleteRequest(`Ingredient?ingredientId=${id}`))
    debug(data, debugOn)
}

export async function pullIngredients(){
    debug("Pulling Ingredient",debugOn)
        let data = (await get('Ingredient')).json()
        debug(data,debugOn)
        return(data)
}
export async function searchIngredients(searchTerm){
    debug("Pulling Ingredient",debugOn)
    let data = (await get('IngredientSearch?SearchTerm='+searchTerm)).json()
    debug(data,debugOn)
    return(data)
}

export async function relinkIngredient(childID, parentID){
    debug("Pulling Ingredient",debugOn)
    let data = (await post(`reLinkIngredient?childId=${childID}&parentID=${parentID}`))
    debug(data.status, debugOn)
    if(data.status===200)
    {
        debug("IN", debugOn)
        deleteIngredient(childID);
    }
    debug(data,debugOn)
    return(data)
}

export function emptyIngredient() {
    return [{
        id: "1",
        name: "",
        names: [],
        labels: [],
        defaultQtyTypeVol: "MILLILITRE",
        defaultQtyTypeWeight: "GRAMS"
    }]

}
