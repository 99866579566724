import React from 'react'
import {convertType} from '../../../data/Ingredient/ingredientHelper';
import {useDispatch, useSelector} from "react-redux";
import IngredientBig from "./SpecificDevice/Big/IngredientBig";
import {editIngredient} from "../../../data/Recipe/RecipeHelper"

export default function Ingredient({ingredient, multiFactor, addToCart, unverified}) {
    const conversionFactors = useSelector(state=> state.global.conversionFactors)
    const dispatcher = useDispatch()
    function update(qtyType) {
        editIngredient(dispatcher, {id: ingredient.ingredientId,
            qtyType: qtyType,
            qty: convertType(ingredient, qtyType, conversionFactors)})

    }

    function capitalize(str) {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function handleQty(qty) {
        //No DP if more than 10 whats the point?!
        if (qty > 10) {
            qty = Math.round((qty + Number.EPSILON))
        }
        //if less than 10 2dp
        else if (qty < 10) {
            qty = Math.round((qty + Number.EPSILON) * 100) / 100
        }
        return qty
    }

    return (
        <div>
            <IngredientBig ingredient={ingredient} multiFactor={multiFactor} unverified={unverified} addToCart={addToCart} capitalize={capitalize} handleQty={handleQty} update={update}/>
        </div>
    )


}
