export function pullValidQtyTypes(ingredient, conversionFactors) {
    let baseType = conversionFactors["ALL"][ingredient.qtyType]["baseType"];
    let options = []
    options.push(...Object.values(conversionFactors[baseType]))
    if (ingredient.mlToGram) {
        if (baseType === "VOLUME") {
            options.push(...Object.values(conversionFactors["WEIGHT"]))
            options.push(...Object.values(conversionFactors["ITEM"]))
        }
        if (baseType === "WEIGHT") {
            options.push(...Object.values(conversionFactors["VOLUME"]))
        }
    }

    if (ingredient.weightPerItem && baseType === "WEIGHT") {
        options.push(...Object.values(conversionFactors["ITEM"]))
    }

    if (baseType === "ITEM" && ingredient.weightPerItem) {
        options.push(...Object.values(conversionFactors["WEIGHT"]))
        if (ingredient.mlToGram) {
            options.push(...Object.values(conversionFactors["VOLUME"]))
        }
    }
    return options
}
export function combineIngredientAndDetails(ingredients, ingredientDetails, conversionFactors){

    ingredients = ingredients.map((x) => {
        Object.assign(x, ingredientDetails[x.ingredientId])
        if(conversionFactors) {
            x.validTypes = pullValidQtyTypes(x, conversionFactors)
        }
        return x
    });
    return ingredients
}
export function convertType(ingredient, target, conversionFactors) {
    let newType = conversionFactors["ALL"][target];
    let baseType = conversionFactors["ALL"][ingredient.qtyType]["baseType"];
    let newQty = ingredient.qty;
    if (baseType === "ITEM") {
        //Fist convert to gram
        newQty = ingredient.qty * ingredient.weightPerItem;
        if (newType.baseType === "WEIGHT") {
            return newQty / newType.defaultConversionFactor;
        }
        else if (newType.baseType === "VOLUME") {
            return ((newQty / ingredient.mlToGram) / newType.defaultConversionFactor)
        }
        else if (newType.baseType === "ITEM") {
            return (newQty / ingredient.weightPerItem)
        }
    }
    if (baseType === "WEIGHT") {
        //Fist convert to gram
        newQty = ingredient.qty * conversionFactors["ALL"][ingredient.qtyType].defaultConversionFactor;
        if (newType.baseType === "WEIGHT") {
            return (newQty / newType.defaultConversionFactor);

        }
        else if (newType.baseType === "VOLUME") {
            return (newQty * ingredient.mlToGram / newType.defaultConversionFactor)

        }
        else if (newType.baseType === "ITEM") {
            return (newQty / ingredient.weightPerItem)
        }
    }
    if (baseType === "VOLUME") {
        //Fist convert to ml
        newQty = (ingredient.qty * conversionFactors["ALL"][ingredient.qtyType].defaultConversionFactor);
        if (newType.baseType === "WEIGHT") {
            return ((newQty / ingredient.mlToGram) / newType.defaultConversionFactor);
        }
        else if (newType.baseType === "VOLUME") {
            return (newQty / newType.defaultConversionFactor)
        }
        else if (newType.baseType === "ITEM") {
            return (newQty / ingredient.weightPerItem)
        }
    }
}
