import {post} from "../../helper/restHelper";
import {jsonHeader} from "../constants";

export async function pullShoppingInformation(recipes, company) {
    let payload = { company: company, recipes: Array.from(recipes.values()).map((value) => ({ recipeID: value.id, portions: value.reqPortions })) }
    let cartObject = await post('Shopping', JSON.stringify(payload), jsonHeader)
        .then(res => res.json())
        .catch(err => err);
    return cartObject
}

export const brandIDs = {AMAZON:"QW1hem9uIEZyZXNo", MORRISONS:"TW9ycmlzb25z"}
