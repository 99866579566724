import React, {useEffect, useState} from 'react'

import Button from '@material-ui/core/Button';

import {AgGridReact} from 'ag-grid-react';
import {emptyIngredient, pullIngredients, pullQtys} from '../../data/Ingredient/ingredientData'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIngredient from './Cards/EditableIngredientCard';


export default function IngredientGrid() {

    const [ingredients, setIngredients] = useState([])
    const [qtyTypes, setQtyTypes] = useState([])
    const [ingredientToEdit, setIngredientToEdit] = useState(emptyIngredient())
    const [open, setOpen] = useState(false)
    
    const [gridApi, setGridApi] = useState()
    useEffect(() => {
        pullIngredients().then((x)=> { setIngredients(x)})
        pullQtys().then(setQtyTypes)
    }, []);

    function refreshIngredients(){
      setOpen(false)
      pullIngredients().then((x)=> { setIngredients(x)})
    }
   // let modules = [ClientSideRowModelModule];
    let header = [
        {headerName: "Ingredient Name", field: "name",sortable: true, initialWidth:100, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},
        {headerName: "Synonyms", field: "names",sortable: true, initialWidth:80, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},
        {headerName: "ML Too Gram", field: "mlToGram",sortable: true, initialWidth:80},
        {headerName: "Weight Per Item", field: "weightPerItem",sortable: true, initialWidth:80},
        {headerName: "Labels", field: "labels",sortable: true, initialWidth:80, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},
        {headerName: "Amazon Id", field: "amazonBasicId",sortable: true, initialWidth:80, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},
        {headerName: "Amazon Qty", field: "amazonBasicQTY",sortable: true, initialWidth:80, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},
        {headerName: "Amazon Qty Type", field: "amazonBasicQTYType",sortable: true, initialWidth:80, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},
        {headerName: "Morrisons Id", field: "morissonsBasicId",sortable: true, initialWidth:80, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},
        {headerName: "Morrisons Qty", field: "morissonsBasicQTY",sortable: true, initialWidth:80, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},
        {headerName: "Morrisons Qty Type", field: "morissonsBasicQTYType",sortable: true, initialWidth:80, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},
        {headerName: "validated", field: "validated",sortable: true, initialWidth:80, filter: 'agTextColumnFilter', filterParams: { buttons: ['reset', 'apply'],},},

    ]

    function onQuickFilterChanged() {
      gridApi.setQuickFilter(document.getElementById('quickFilter').value);
    };

    function onGridReady(params)
    {
      setGridApi(params.api);
      console.log(params.columnApi.columnController)
      //gridApi.sizeColumnsToFit();
    }

    function triggerUpdate(params)
    {
      setIngredientToEdit(params.data)
      setOpen(true)
    }

    function newIngredient()
    {
      setIngredientToEdit(emptyIngredient())
      setOpen(true)
    }

    return (
        <div
        className="ag-theme-balham"
        style={{
        height: '100%',
        width: '100%',
        overflowX:'scroll'}}
      >
            <input
              type="text"
              onInput={() => onQuickFilterChanged()}
              id="quickFilter"
              placeholder="quick filter..."
            />
            <Button onClick={()=>newIngredient()} color="primary">
                                Add Ingredient
                            </Button>
            <Dialog
                        open={open}
                        onClose={()=>setOpen(false)}
                        scroll="paper"
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title">Edit Ingredient</DialogTitle>
                        <DialogContent dividers={true}>
                          <EditIngredient ingredient={ingredientToEdit} refresh={refreshIngredients} qtyTypes={qtyTypes}/>  
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>setOpen(false)} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
          <AgGridReact
            rowSelection="single"
            onRowClicked={triggerUpdate}

            columnDefs={header}
            rowData={ingredients}
            suppressHorizontalScroll={false}
            defaultColDef={{ resizable: true }}
            onGridReady={onGridReady}
          >
        </AgGridReact>
        </div>

    )
}
