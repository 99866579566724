import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {AccessTime, Kitchen} from '@material-ui/icons';
import {Box, Button, CardHeader, Icon} from '@material-ui/core';
import Ingredient from '../../../Ingredients/Ingredient'
import MenuIcon from '../../../../Toolbar/Menu/CardMenu';
import Collapse from "@material-ui/core/Collapse";
import {Chip} from "material-ui";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: '1vh'
    },
    card: {
        width: '90vw',
        margin: 'auto',
        marginBottom: '50px',
        minWidth: "250px",
        borderRadius: 20,

    },
    CardContent: {
        display: 'flex',
        flexDirection: "column",
        padding: '10%',
    },
    media: {
        minWidth: "250px",
        minHeight: "250px",
        display: 'Block',
        margin: 'auto',
        width: "80vw",
        height: "80vw",
        borderRadius: 20,
    },
    mediaDetails: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        display: 'flex',
        justifyContent: 'space-around',
        background: '#F0F0F0',
    },
    mediaDetail: {
        textAlign: 'center',
        flexDirection: 'column',
    },
    about: {
        height: "100%",
        marginBottom: 10
    },
    top: {
        display: 'flex',
        flexDirection: "column",
        marginBottom: 20
    },
    bottom: {
        display: 'flex',
        flexDirection: "row",
    },
    left: {
        width: 200,
        borderRadius: 20,
        flexBasis: '33.33%',
        display: 'flex',
        flexDirection: "column",
    },
    right: {
        flex: 1,

        flexDirection: 'column',
        paddingLeft: 20,
        display: 'flex',

    },
    header: {
        display: 'flex',
    },
    menuIcon: {
        float: 'right',
        marginTop: '-10px'
    },
    title: {
        flex: 1,
    },
    button: {
        minWidth: "100px",
        width: "38vw",
        height: "4.4em"
    },
    buttons: {
        margin: "auto",
        paddingTop: "2vh",
        display: "flex",
        paddingBottom: "2vh"
    },
    labelBox: {
        width:"80vw",
        marginLeft:"auto",
        marginRight:"auto",
        flexWrap: "wrap",
        paddingBottom: "5px"
    },
    chip: {
        margin: "5px"
    }

}));

export default function PresentableRecipeSmall({
                                                   chipClick,
                                                   recipe,
                                                   commands,
                                                   userClick,
                                                   inBasket,
                                                   basketClick,
                                                   multiFactor,
                                                   portionChange
                                               }) {
    const classes = useStyles();
    const {recipeName, timeInMinutes, imageData, ingredients, method, about, reqPortions, labels} = recipe
    const [recipeTab, setRecipeTab] = useState()

    function methodClick() {
        setRecipeTab(!recipeTab)
    }
    console.log(commands)
    return (
        <div className={classes.root}>
            <Card variant="outlined" className={classes.card}>
                <CardHeader
                    style={{width:"80vw",alignItems:"flex-start", minHeight: "5em", marginLeft:"auto", marginRight:"auto"}}
                    titleTypographyProps={{variant: "subtitle1", className: classes.title}}
                    title={recipeName}
                    action={commands.length > 0 ? <MenuIcon recipe={recipe} commands={commands}/> : null}
                    subheader={
                        <Button onClick={() => userClick(recipe.user.id)} style={{padding: 0}}>
                            <Typography variant="caption" color="textPrimary"
                                        component="p">
                                Recipe By: {recipe.user ? recipe.user.username : "N/A"}
                            </Typography>
                        </Button>
                    }
                />
                <Box display={"flex"} className={classes.labelBox}>
                    {labels.map(label=> <Chip key={label} style={{margin:1}} className={classes.chip} onClick={()=>chipClick(label)} >{label}</Chip>)}
                </Box>
                <CardMedia
                    className={classes.media}
                    image={imageData ? imageData : process.env.PUBLIC_URL + "/notFoundImage.svg"}
                    style={imageData ? {} : {backgroundSize: "50%"}}
                    title={recipeName}
                >
                    <div className={classes.mediaDetails}>
                        <div className={classes.mediaDetail}>
                            <Icon color="secondary">
                                <AccessTime/>
                            </Icon>
                            <Typography>{timeInMinutes}</Typography>
                        </div>
                        <div className={classes.mediaDetail}>
                            <Icon color="secondary">
                                <Kitchen/>
                            </Icon>
                            <Typography>{ingredients.length}</Typography>
                        </div>
                    </div>
                </CardMedia>
                <CardContent>
                    <Typography className={classes.about} variant="body2" color="textSecondary" component="p">
                        {about}
                    </Typography>
                    <div className={classes.buttons}>
                        <Box mr={"10px"}>
                            {recipeTab && <Button className={classes.button} variant="contained" color="secondary.dark"
                                                  onClick={methodClick}>Ingredients</Button>}
                            {!recipeTab &&
                                <Button className={classes.button} variant="contained" color="secondary"
                                        onClick={methodClick}>Method</Button>}
                        </Box>
                        <Box ml={"10px"}>
                            {inBasket && <Button className={classes.button} variant="contained" color="primary"
                                                 onClick={basketClick}>Remove From Basket</Button>}
                            {!inBasket && <Button className={classes.button} variant="contained" color="secondary"
                                                  onClick={basketClick}>Add To Basket</Button>}
                        </Box>
                    </div>
                    <Collapse in={!recipeTab} className={classes.ingredients}>
                        <TextField
                            id="standard-number"
                            label="No Portions"
                            type="number"
                            defaultValue={reqPortions}
                            onChange={(x) => portionChange(x.target.value)}
                        />
                        <br/>
                        {ingredients.map(x => (
                            <div key={x.ingredientId}><Ingredient ingredient={x} multiFactor={multiFactor}/><Divider/>
                            </div>))}
                    </Collapse>
                    <Collapse in={recipeTab} className={classes.method}>
                        {method.split("\n").map((x, idx) =>
                            <Typography key={idx} variant="body2" color="textPrimary" component="p">
                                {x}
                                <br/>
                            </Typography>
                        )}
                    </Collapse>
                </CardContent>
            </Card>
        </div>
    )
}
