import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import SearchBar from "../../../Toolbar/SearchBar/SearchBar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import {ArrowDropDown} from "@material-ui/icons";
import CardMedia from "@material-ui/core/CardMedia";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";


const useStyles = makeStyles(theme => ({
    main:{
        display: "flex",
        flexDirection: "column",
    },
    background: {
        flexDirection: "column",
        backgroundColor: '#f5f5f5'
    },
    details: {
        textAlign: 'left',
    },
    fill: {
        position:"relative",
        display:"flex",
        flexDirection:"row"
    },
    avatar: {
        margin: '15px',
        width: '80px',
        height: '80px',
    },
    about:{
        width: "90vw",
        marginLeft:"auto",
        marginRight:"auto"
    },
    username:{
        textTransform:"capitalize",
        paddingBottom:"10px"
    },
    scroll:{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexGrow: 1,
        height: "100vh",
    },
    down:{
        marginTop:"1px",
    }

}));


export default function UserFSSmall({commands, user }) {
    const classes = useStyles();
    const [anchorEl2, setAnchorEl2] = useState(null);
    return (
        <Box display="flex" className={classes.main}>
            <Box className={classes.background} display="flex">
                <Box className={classes.fill}>
                    <Menu
                        MenuListProps={{
                            'dense': true
                        }}
                        variant={"selectedMenu"}
                        id="PantryLife"
                        anchorEl={anchorEl2}
                        open={Boolean(anchorEl2)}
                        onClose={() => setAnchorEl2(null)}
                    >
                        {commands.map((menuItem) => <MenuItem key={menuItem.title} onClick={() => {
                            menuItem.method();
                            setAnchorEl2(null)
                        }}>
                            {menuItem.icon}{menuItem.title}
                        </MenuItem>)}
                    </Menu>
                    <CardMedia
                        className={classes.avatar}
                        image={user.imageData ? user.imageData : process.env.PUBLIC_URL+"/notFoundImage.svg"}
                        style={{borderRadius:"5px"}}
                        title={user.username}
                    />
                    <Box display="flex" style={{flexDirection:"column-reverse"}}>
                        <Typography className={classes.username} variant="h6">{user.username}'s Pantry
                        {commands && commands.length>0&&<IconButton style={{padding:"3px"}} onClick={(e) => setAnchorEl2(e.currentTarget)}>
                            <ArrowDropDown color="primary" />
                        </IconButton>}
                        </Typography>
                    </Box>

                </Box>
                <Box className={classes.details} flexGrow={1} display="flex" flexDirection="column">
                    <Box>
                        <Typography className={classes.about} variant="subtitle2" >{user.about}</Typography>
                    </Box>
                </Box>
            </Box>
            <Divider className={classes.down}/>
            <SearchBar userID={user.id}/>

        </Box>
    )
}
