import {makeStyles, Typography} from '@material-ui/core'
import React from 'react'


const useStyles = makeStyles((theme) => ({
    privacy: {
        marginTop:theme.spacing(2),
        marginLeft:"auto",
        marginRight:"auto",
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80vw', // Fix IE 11 issue.
        backgroundColor: 'white',
        '& h4': {
            width: "80vw",
        },
        '& p': {
            width: "80vw",
        },
        '& th':{
            padding:'10px',
            verticalAlign: 'top',
            border:'1px solid black',
            backgroundColor:"#cbcbcb"
        },
        '& td':{

            border:'1px solid black',
            verticalAlign: 'top',
            padding:'10px'
        },
        '& table':{
            border:'1px solid black',
            borderCollapse:'collapse'
            
        }


    },
}));
export default function Privacy() {
    const classes = useStyles();
    return (
        <div className={classes.privacy}>
            <Typography variant="h2">Privacy Policy</Typography>
            <br />
            <Typography variant="h4">About this Privacy Policy:</Typography>
            <Typography component={'span'} variant="body1">
                This Privacy Policy explains what personal information we hold about you, how we collect it, and how we use and may share information about you. It relates to any personal information we receive from via:
                <ul>
                    <li>Our Websites: thepantry.life and any other The Pantry Life website that links to this Privacy Policy</li>
                    <li>our Social Pages: social media pages and accounts for “The Pantry Life”</li>
                    <li>our Contact channels: where you provide or submit your personal information to us by email, phone, SMS, on social media (for example in response to a competition or promotion), in letters and in person.</li>
                    <li>our relationships with business partners, licensees and suppliers (where you are their staff member, officer or agent).</li>
                </ul>
                It also relates to any personal information you provide or submit to us by email, phone, SMS, on social media (for example in response to a competition or promotion), in Messenger and other chat bots, in letters and in person.
This Privacy Policy should be read together with our:
                <ul>
                    <li>Cookies Policy</li>
                    <li>Websites’ Terms of Use</li>
                    <li>Terms for Contributions</li>
                </ul>
                There may be other privacy policies or terms and conditions that apply to certain services we provide or promotions/competitions we run. Please always read these when you participate in these promotions or register for these services.
            </Typography>
            <Typography variant="h4">Who we are</Typography>
            <Typography variant="body1" >
                When you visit our Websites, use our Services, interact with us (including via our Social Pages) or your personal information is otherwise submitted to us, The Pantry Life Ltd (‘us’ and ‘we’) is a ‘data controller’ and gathers and uses certain information about you. When we do so we are regulated under the General Data Protection Regulation which applies across the European Union (and still applies to the United Kingdom after Brexit) and local applicable laws.
            </Typography>
            <Typography variant="h4" >How we collect information about you:</Typography>
            <Typography component={'span'} variant="body1" >
                The types of information we collect about you depends on how you interact with us. There are three ways in which we may collect your personal information:
            <ul>
                    <li>directly from you;</li>
                    <li>from other sources; and</li>
                    <li>automatically.</li>
                </ul>
                For more details about the types of information we receive from these and other sources, including where it is held and where it came from, see the section 'More about the information we collect and hold' at the bottom of this policy.
            </Typography>
            <Typography variant="h4" >Information we receive directly from you:</Typography>
            <Typography component={'span'} variant="body1" >
                Whenever you interact with us directly, we receive information from you, for example when you:
                <ul>
                    <li>correspond with us, for example by using the contact forms on our Websites, by using Facebook Messenger, or by emailing us</li>
                    <li>sign-up for an account with thepantry.life</li>
                    <li>sign up to receive any of our marketing or newsletters</li>
                    <li>subscribe to one of our Services </li>
                    <li>enter any competitions or prize draws we run</li>
                    <li>comment on recipes and content on the Websites</li>
                    <li>submit content to us, for example when you post recipes on the Websites or use one of our hastags on your public social media account in response to one of our competitions or tag us in a tweet or other social media post (please also read our Terms for Contributions)</li>
                </ul>
            </Typography>
            <Typography variant="h4" >Information received from other sources: </Typography>
            <Typography component={'span'} variant="body1" >
                Sometimes we also obtain information about you from other sources, for example when you:
                <ul>
                    <li>use any of our Services or Website Functions which enable you to sign-in via a third party service, for example if you log-in to your thepantry.life account with Facebook - we receive some of your account information</li>
                    <li>complete one of our surveys – we receive your answers and any personal information you provide within your answers from Survey Monkey</li>
                    <li>are a staff member, officer or agent of a company that is our potential, current or recent business partner, licensee or supplier</li>
                    <li>have personal information about you which is publicly accessible such as on Companies House or another government website.</li>
                </ul>
            </Typography>
            <Typography variant="h4" >Information we collect automatically:</Typography>
            <Typography variant="body1" >
                When you access and browse any of our Websites or use our Services, we collect information about your usage and activity on our Websites or Services using certain technologies, such as cookies and web beacons.
                Depending on the cookie settings in your browser and the cookie preferences you set when you first access our Websites or Services, our third-party service providers, advertisers, and/or partners may also place, view, edit, or set their own cookies. If you would like to find out more about cookies, including how we use them and what choices are available to you, please refer to our Cookie Policy.
            </Typography>
            <Typography variant="h4" >How we use your personal information: </Typography>
            <Typography component={'span'} variant="body1" >We explain the ways in which we plan to use your personal information and the legal basis on which we rely for each use in the table below and in our Cookie Policy.
            Generally, the reasons we use your personal information can be categorised as follows:
                <ul>
                    <li>to personalise our Services to you</li>
                    <li>to enable you to partake in our prize draws or competitions</li>
                    <li>to maintain and improve our Services and our Websites’ infrastructure</li>
                    <li>to sell advertising space on the Websites – this allows us to provide certain sections of our Websites to you for free</li>
                    <li>if you have opted-in to this, or where otherwise allowed by law, to market our products and services to you</li>
                    <li>to manage relationships and contracts with our potential, current or recent business partners, licensees and suppliers, where you are their staff member, officer or agent.</li>
                </ul>
            Where we propose to use your personal information for any other uses we will ensure that we notify you first (and if required, obtain your consent).
            </Typography>
            <Typography variant="h4" >Who we share your personal information with: </Typography>
            <Typography component={'span'} variant="body1" >
                With Third-Party Partners
                We work with third parties who may need to have access to your personal information to provide us with services or to help us to provide and improve our Services and Websites. In the section 'More about the information we collect and hold' at the bottom of this policy and the paragraphs below we name (or where this is not possible, names the categories) third parties with whom the information we collect and hold about you may be shared.
                <ul>
                    <li><b>Vendors and service providers</b></li>
                </ul>
                We may provide information and content to vendors and service providers who support our business, such as by providing technical infrastructure services, security, communication (e.g. email, file sharing), analysing how our Websites and services are used, providing customer service, conducting surveys, or providing fraud detection service.
We may also need to share personal information with other parties, such as external contractors and our professional advisers and potential purchasers of some or all of our business or on a re-structuring. Usually, information will be anonymised but this may not always be possible. We contractually bind all third parties with whom we share personal information to keep your information confidential and to use it only for the purpose of providing their services and pursuant to the applicable privacy legislation in the EU. We may also be required to share some personal information as required to comply with the law. Some of these third-party recipients may be based outside the European Economic Area — for further information including on how we safeguard your personal data when this occurs, see the section about ‘Transfer of your information out of the EEA’ below.
<ul>
                    <li><b>Government and law enforcement bodies</b></li>
                </ul>
                We may disclose your personal data as permitted by law in order to investigate, prevent or take action regarding suspected or confirmed illegal activities, violation of our intellectual property rights, violation of our Terms and Conditions or other agreements, or as required by law or by a government or law enforcement body.
            </Typography>
            <Typography variant="h4" >Whether information has to be provided by you, and if so, why:</Typography>
            <Typography variant="body1" >
                We’ve outlined in the table below, why each category of personal information we collect from you is required from us to be able to perform the purpose to which it relates and the possible consequences of failing to provide such information. We seek to ensure that our information collection and processing is always proportionate. We will inform you at the point of collecting information from you, whether you are required to provide the information to us or not. We will notify you of any changes to information we collect from you or to the purposes for which we collect and process it.
            </Typography>
            <Typography variant="h4" >How long your Personal Information will be kept:</Typography>
            <Typography component={'span'} variant="body1" >
                thepantry.life account holders
                When you cancel your thepantry.life account we delete all your personal information but we keep a record of the fact that your account was deleted for a reasonable period of time. If you have not logged-in to your account for a long time, we will send you an email to let you know that your account has been set to a ‘dormant’ status and that it will be deleted if you do not take action within one month on the email date. Please note we may retain information from deleted accounts to comply with the law, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Terms of Use and take other actions permitted by law.
                Other personal information
                We will keep your personal information while we are providing any Services to you (including any marketing or newsletter services) or whilst we have a business relationship with our business partner, licensee or supplier of which you are a staff member, officer, or agent. Thereafter, we will keep your personal information for as long as is necessary:
                <ul>
                    <li>to respond to any questions, complaints or claims made by you or on your behalf</li>
                    <li>to show that we treated you fairly</li>
                    <li>to keep records required by law</li>
                    <li>to establish, exercise or defend legal claims.</li>
                </ul>
                We will not retain your personal information for longer than necessary for the purposes set out in this policy and when it is no longer necessary to retain your personal information, we will delete or anonymise it. Different retention periods apply for different types of personal information. If you require further details, please see below ‘How to contact us’.
            </Typography>
            <Typography variant="h4" >Reasons we can use your personal information: </Typography>
            <Typography component={'span'} variant="body1" >
                This section addresses the legal basis for processing your personal data. Some processing is addressed in multiple sections set out below because more than one legal basis may apply depending on the circumstances or service and to make it easy for you.

                The legal bases on which we rely in each instance where we collect and use your personal data, generally, are:
                <ul>
                    <li>as necessary to fulfil and enforce our Terms of Use, Terms for Contributions and any other contract we have with you (such as if you supply goods or services to us)</li>
                    <li>consistent with your consent</li>
                    <li>as necessary for our (or others’) legitimate interests, including our interests in providing safe Website/Services, fraud detection, tailoring your experience on our Websites/Services, knowing how customers use our Website/Services and products, keeping our Websites/Services updated and relevant, developing our business and informing our marketing strategy, and managing our relationships and fulfilling contracts with business partners, licensees and suppliers, but only if these are not overridden by your interests, rights or freedoms.</li>
                </ul>
            </Typography>
            <Typography variant="h4" >Transfer of your information out of the EEA: </Typography>
            <Typography component={'span'} variant="body1" >
                To achieve the reasons we described above for using your personal information , it is sometimes necessary for us to share your personal information outside the European Economic Area (EEA), eg:
                 <ul>
                    <li>with your and our service providers located outside the EEA (if any)</li>
                    <li>if you are based outside the EEA</li>
                </ul>
            These transfers are subject to special rules under European and UK data protection law. Prior to permitting such transfers, we carry out assessments to ensure all personal information transferred will be kept secure. Our standard practice is to use standard data protection model contract clauses that have been approved by the European Commission (as permitted under Article 46(2) of the General Data Protection Regulation) or to only transfer your data to companies that are registered with the Privacy Shield (for transfers to the USA). If you would like further information please see ‘How to contact us’ below.
            </Typography>
            <Typography variant="h4" >Your rights: </Typography>
            <Typography component={'span'} variant="body1" >
                Under the General Data Protection Regulation you have a number of important rights free of charge. In summary, you have the right to access your personal data and require that we rectify any errors in the data that we hold, or request that we erase your personal data subject to certain limitations. In some circumstances, you can also require that we restrict the way we process your personal data, object to its processing or request a copy of your personal data for the purposes of transmitting elsewhere. Where we have requested and obtained your consent to process particular information, you may withdraw that consent at any time. However if we do not hold all the data we need to administer contracts, orders, competitions or challenges you entered into, we may not be able to provide you with these benefits any longer.
                For further information on each of those rights, including the circumstances in which they apply, see the Guidance from the UK Information Commissioner’s Office (ICO) on individuals rights under the General Data Protection Regulation.
                If you would like to exercise any of those rights, please send your request to us via either of the methods described below under “How to Contact us” and:
                <ul>
                    <li>let us have enough information to identify you (eg your full name and contact details - including your email address); and</li>
                    <li>let us know what right you want to exercise and the information to which your request relates (eg details of the specific information you require and any relevant dates).</li>
                </ul>
                Please note that we may ask you to provide proof of identity when considering your request.
            </Typography>
            <Typography variant="h4" >Keeping your personal information secure:</Typography>
            <Typography variant="body1" >
                We have appropriate security measures in place to prevent personal information from being accidentally lost, or used or accessed in an unauthorised way. We limit access to your personal information to those who have a genuine business need to know it. Those processing your information will do so only in an authorised manner and are subject to a duty of confidentiality.
                Our website uses HTTPS which means that all data transferred from your web browser to our servers is encrypted. Our data servers are located in the United Kingdom. We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.
                For detailed information on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online risks, please visit www.getsafeonline.org. Get Safe Online is supported by HM Government and leading businesses.
            </Typography>
            <Typography variant="h4" >Changes to this Privacy Policy:</Typography>
            <Typography variant="body1" >
                This Privacy Policy was published on 23 December 2020. We may change this Privacy Policy from time to time; when we do we will post the changes on the Website. Where the changes are significant, we will inform all our registered users by email. Where required by law, will we obtain your consent to make these changes.
            </Typography>
            <Typography variant="h4" >How to contact us:</Typography>
            <Typography variant="body1" >
                Please contact us if you have any questions about this Privacy Policy or the information we hold about you.
                If you wish to contact us please email us at privacy@thepantry.life.
            </Typography>

            <Typography variant="h4" >Your rights and how to complain:</Typography>
            <Typography component={'span'} variant="body1" >
                Under data protection law, you have rights including:
                <ul>
                    <li>
                        Your right of access
                        <ul>
                            <li>You have the right to ask us for copies of your personal information.</li>
                        </ul>
                    </li>
                    <li>
                        Your right to rectification
                        <ul>
                            <li>You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
                        </ul>
                    </li>
                    <li>
                        Your right to erasure
                        <ul>
                            <li>You have the right to ask us to erase your personal information in certain circumstances.</li>
                        </ul>
                    </li>
                    <li>
                        Your right to restriction of processing
                        <ul>
                            <li>You have the right to ask us to restrict the processing of your personal information in certain circumstances.</li>
                        </ul>
                    </li>
                    <li>
                        Your right to object to processing
                        <ul>
                            <li>You have the the right to object to the processing of your personal information in certain circumstances.</li>
                        </ul>
                    </li>
                    <li>
                        Your right to data portability
                        <ul>
                            <li>You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</li>
                        </ul>
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" >
                You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.
                Please contact us at privacy@thepantry.life  if you wish to make a request.
                The General Data Protection Regulation also gives you the right to lodge a complaint with a supervisory authority, in particular in the European Union (or European Economic Area) state where you work, normally live or where any alleged infringement of data protection laws occurred. The supervisory authority in the UK is the Information Commissioner who may be contacted at:
            </Typography>
            <Typography variant="body1" align='center' >
                <br />
                <br />
                Information Commissioner’s Office
                <br />Wycliffe House
                <br />Water Lane
                <br />Wilmslow
                <br />Cheshire
                <br />SK9 5AF
                <br />Helpline number: 0303 123 1113
                <br />ICO website: https://www.ico.org.uk
            </Typography>
            <Typography variant="h4" >More about the information we collect and hold: </Typography>
            <Typography component={'span'} variant="body1" >
                We currently collect and process the following information:
            <table>
                <thead>
                    <tr>
                        <th>The information we collect</th>
                        <th>how we collect this information</th>
                        <th>why we collect this information</th>
                        <th>How we use and may share this information</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Information you provide during the “sign-up” process on thepantry.life</th>
                        <td>
                            <ul>
                                <li>This is information you give that is required when setting up your account.</li>
                                <li>This may include your name, address, email address and phone number as well as password details.</li>
                                <li>We use a third party system called Auth0 to manage the “sign-up” process and your account.</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Consent</li>
                                <li>Performance of a contract with you</li>
                                <li>For our legitimate interests (to study how customers use our products/ services, to develop them and grow our business)</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>The minimum information we need to register you with a thepantry.life account is your name, email address and a password.</li>
                                <li>We use this information to identify you and provide you with the services you subscribe to, and where available, account-holder exclusive content.</li>
                                <li>We may also allow you to answer voluntary questions during the registration process (for example your food preferences and family status). We use this information to personalise the services (including any marketing or newsletters you have opted-in to receive) we provide to you and to better understand who our audience is and what content they are interested in.</li>
                                <li>Your Personal Information is provided by you directly to Auth0, who is responsible for the protection of that data. Auth0 then provides us with your Personal Information for the purposes of administering your account.</li>
                                <li>Auth0 is based in the USA and may transfer your personal information outside the EEA. When doing so, it relies on binding corporate rules (BCR) approved by the European data protection authorities or on other lawful measures, such as the EU standard contractual clauses or the Privacy Shield Framework - see its Privacy Policy here.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Information we collect about you from your use of our site</th>
                        <td>
                            <ul>
                                <li>This information is logged by our back end services and used when gathering metrics and protecting our system from external attacks.</li>
                                <li>This may include technical information, IP Location data, information about your visit, such as pages you have visited and other matters.</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Consent</li>
                                <li>Performance of a contract with you</li>
                                <li>For our legitimate interests (to study how customers use our products/ services, to develop them and grow our business)</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>We use this information to identify you and provide you with the services you subscribe to, and where available, account-holder exclusive content.</li>
                                <li>We may also use this information to improve the usability of the Websites and the Services.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Information you provide us to help administer your recipe catalogue - this may be kept private or made public depending on your account attributes.</th>
                        <td>
                            <ul>
                                <li>This includes any recipes you add to our site, as well as any comments or profile information entered on your profile page.</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Consent</li>
                                <li>Performance of a contract with you</li>
                                <li>For our legitimate interests (to study how customers use our products/ services, to develop them and grow our business)</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>We use this information to identify you and provide you with the services you subscribe to, and where available, account-holder exclusive content.</li>
                                <li>We may also use this information to improve the usability of the Websites and the Services.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Information shared and made publicly accessible by you when you comment on recipes and content on our Websites, Services or Social Pages or share content with us via social media using posts, tweets, tagging us or using our hashtags.</th>
                        <td>
                            <ul>
                                <li>When you comment on recipes and content on our Websites, Services or Social Pages or share content with us via social media using posts, tweets, tagging us or using our hashtags, we do not receive any personal information other than that made available to us in your comment, tweet or post (including, if applicable, your social media handle and profile photo).</li>
                                <li>We collect this information from you. </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>For our legitimate interests (to study how customers use our products/ services, to develop them and grow our business)</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>To allow you to comment on recipes and content on our Websites.</li>
                                <li>We may use and re-publish personal information and content made publicly available within comments, tweets or posts. Please see our Terms for Contributions.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Information you provide if you sign up to receive any of our marketing or newsletters (and if you subsequently update your marketing preferences):</th>
                        <td>
                            <ul>
                                <li>This information may include:</li>
                                <ul>
                                    <li>your name;</li>
                                    <li>e-mail address; and</li>
                                    <li>marketing preferences.</li>
                                </ul>
                                <li>We collect this information from you.</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Consent</li>
                                <li>Performance of a contract with you</li>
                                <li>For our legitimate interests (to study how customers use our products/ services, to develop them and grow our business)</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>To promote The Pantry Life and services and deliver you the marketing communications you’re subscribed to.</li>
                                <li>To update your preferences and unsubscribe you if you request us to.</li>
                                <li>To manage our relationship with you, including to let you know about changes to our policies and terms.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Information provided when you complete one of our surveys:</th>
                        <td>
                            <ul>
                                <li>From Survey Monkey (a third party survey platform) - if you respond to one of our surveys via Survey Monkey</li>                                
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>For our legitimate interests (to study how customers use our products/ services, to develop them and grow our business)</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>To collect feedback and suggestions from our customers and to better understand our audience.</li>
                                <li>Our surveys are powered by SurveyMonkey Inc., which is located in the USA. Accordingly, your information will be transferred to the USA. SurveyMonkey Inc. has certified its compliance with the EU-U.S. Privacy Shield Framework. Its Privacy Policy is available here.</li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </Typography >
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
            <Typography variant="h4" ></Typography>
            <Typography variant="body1" ></Typography>
        </div >
    )
}