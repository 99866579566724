import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Box, OutlinedInput, TextField, Typography} from '@material-ui/core';
import MenuIcon from '../../../Toolbar/Menu/CardMenu';
import CardMedia from "@material-ui/core/CardMedia";
import UploadImage from "./UploadImage";

const useStyles = makeStyles(theme => ({
    main: {
        backgroundColor: '#f5f5f5',
        display: "flex",
        flexDirection: "column",
    },
    background: {
        marginTop:"20px",
        flexDirection: "column",
        width: "90vw",
        minWidth: "300px",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "20px",
    },
    details: {
        textAlign: 'left',
    },
    fill: {
        position: "relative",
        display: "flex",
        flexDirection: "row"
    },
    avatar: {
        width: '80px',
        height: '80px',
        marginBottom: "20px"
    },
    about: {
        width: "90vw",
    },
    username: {
        textTransform: "capitalize",
        paddingBottom: "10px"
    },
    scroll: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexGrow: 1,
        height: "100vh",
    },
    down: {
        marginTop: "1px",
    }, header: {
        float:"right",
        marginLeft: "10px",
        display: 'flex',
    },
    inputTitle: {
        marginTop: "15px",
        alignItems: "center",
    },

}));

export default function EditUserFSSmall({commands, user, edit, uploadImage}) {
    const classes = useStyles();
    return (
        <Box display="flex" className={classes.main}>
            <Box className={classes.background} display="flex">
                <div className={classes.fill}>
                    <Box display="flex" style={{flexDirection: "column"}}>
                        <CardMedia
                            className={classes.avatar}
                            image={user.imageData ? user.imageData : process.env.PUBLIC_URL + "/notFoundImage.svg"}
                            style={{borderRadius: "5px"}}
                            title={user.username}
                        >
                            <UploadImage currentImage={user.imageData} saveImage={uploadImage}/>

                        </CardMedia>
                    </Box>
                    <div style={{flexGrow:1}}/>
                    <div className={classes.header}>
                        <TextField className={classes.username}
                                   defaultValue={user.username}
                                   label={"Username"}
                                   rowsMax={1}
                                   inputProps={{ maxLength: 12 }}
                                   onChange={(e) => edit({username: e.target.value})}/>
                        <MenuIcon recipe={user} commands={commands} withoutList={true}/>
                    </div>
                </div>
                <Box className={classes.details} flexGrow={1} display="flex" flexDirection="column">
                    <Typography variant={"subtitle1"} className={classes.inputTitle}>Description:</Typography>
                    <OutlinedInput className={classes.about} multiline
                                   label='About'
                                   defaultValue={user.about}
                                   rows={5}
                                   placeholder={"Some fantasticly detailed information about my love of recipes!"}
                                   onChange={(e) => edit({about: e.target.value})}/>
                </Box>
            </Box>
        </Box>
    )
}

