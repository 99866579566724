import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Avatar, Box, Typography} from '@material-ui/core';
import CardMenu from '../../../Toolbar/Menu/CardMenu';
import SearchBar from "../../../Toolbar/SearchBar/SearchBar";
import Divider from "@material-ui/core/Divider";


const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: '#f5f5f5'
    },
    details: {
        textAlign: 'center',
    },
    fill: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    avatar: {
        margin: '30px',
        width: '90px',
        height: '90px',
    },
    main: {
        flexDirection: "column"
    }
}));


export default function UserFSBig({commands, user}) {
    const classes = useStyles();

    return (
        <Box display="flex" className={classes.main}>
            <Box className={classes.background} display="flex">
                <Box className={classes.fill}>
                    <Avatar alt={user.username} src={user.imageData} className={classes.avatar}/>
                </Box>
                <Box className={classes.details} flexGrow={1} display="flex" flexDirection="column">
                    <Box>
                        <Typography variant="h1">{user.username}'s Pantry</Typography>
                    </Box>
                    <Box>
                        <Typography varient="p">{user.about}</Typography>
                    </Box>
                </Box>
                <Box className={classes.fill}>
                    <CardMenu commands={commands} recipe={user} withoutList={true}/>
                </Box>
            </Box>
            <Divider/>
            <SearchBar userID={user.id}/>
        </Box>
    )
}
