import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import {Box, Button, CardHeader, Icon, TextField, Typography} from '@material-ui/core';
import {AccessTime, Face, Kitchen, Visibility} from '@material-ui/icons';
import MenuIcon from "../../../Toolbar/Menu/CardMenu";


const useStyles = makeStyles(theme => ({
    card: {
        maxWidth:"350px",
        width: "90vw",
        minWidth: "250px",
        height: "520px",
        marginTop: "2vh",
        marginBottom: "5vh",
        borderRadius: 20,
    },

    CardContent: {
        display: 'flex',
        height: "500px",
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '5vw',
        paddingRight: '5vw'
    },
    media: {
        flex: "1 1 auto",
        display: 'Block',
        marginLeft: "auto",
        marginRight: "auto",
        minWidth: "250px",
        minHeight: "250px",
        width: "80vw",
        height: "80vw",
        maxWidth:"300px",
        maxHeight:"300px",
        borderRadius: 20,
    },
    mediaDetails: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        display: 'flex',
        justifyContent: 'space-around',
        background: '#F0F0F0',
    },
    mediaDetail: {
        paddingTop: 5,
        width: 35,
        textAlign: 'center',
        flexDirection: 'column',
    },
    mediaDetailText: {},
    Content: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
    },
    button: {
        maxWidth:"140px",
        minWidth: "100px",
        width: "38vw",
        height: "4.4em"
    },
    buttons: {
        paddingTop:"10px",
        maxWidth: "300px",
        margin: "auto",
        display: "flex",
    },
    title: {
        maxHeight:"4em",
        textTransform: "uppercase",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": 2,
        "-webkitBoxOrient": "vertical"
    }

}));

export default function PresentableRecipeCardSmall({
                                                       commands,
                                                       recipe,
                                                       basketClick,
                                                       userClick,
                                                       recipeClick,
                                                       update,
                                                       inBasket
                                                   }) {
    const classes = useStyles();
    const {recipeName, timeInMinutes, imageData, reqPortions, ingredients, views} = recipe
    return (
        <Card variant="outlined" className={classes.card}>
            <CardHeader
                style={{maxWidth:"300px",alignItems:"flex-start", minHeight: "5em", marginLeft:"auto", marginRight:"auto"}}
                titleTypographyProps={{variant: "subtitle1", className: classes.title}}
                title={recipeName}
                action={commands.length > 0 ? <MenuIcon recipe={recipe} commands={commands}/> : null}
                subheader={
                    <Button onClick={() => userClick(recipe.user.id)} style={{padding: 0}}>
                        <Typography variant="caption" color="textPrimary"
                                    component="p">
                            Recipe By: {recipe.user ? recipe.user.username : "N/A"}
                        </Typography>
                    </Button>
                }
            />
            <CardMedia
                className={classes.media}
                image={imageData ? imageData : process.env.PUBLIC_URL+"/notFoundImage.svg"}
                style={imageData ? {} : {backgroundSize: "50%"}}
                title={recipeName}
            >
                <div className={classes.mediaDetails}>
                    <div className={classes.mediaDetail}>
                        <Icon color="secondary">
                            <AccessTime/>
                        </Icon>
                        <Typography>{timeInMinutes}</Typography>
                    </div>
                    <div className={classes.mediaDetail}>
                        <Icon color="secondary">
                            <Kitchen/>
                        </Icon>
                        <Typography>{ingredients.length}</Typography>
                    </div>
                    <div className={classes.mediaDetail}>
                        <Icon color="secondary">
                            <Visibility/>
                        </Icon>
                        <Typography>{views}</Typography>
                    </div>
                    <div className={classes.mediaDetail}>
                        <Icon color="secondary">
                            <Face/>
                        </Icon>
                        <TextField
                            id="standard-number"
                            type="number"
                            inputProps={{min: 0, max: 99, style: {textAlign: 'center'}}}
                            value={reqPortions}
                            onChange={(x) => update({reqPortions: x.target.value})}
                        />
                    </div>
                </div>
            </CardMedia>
            <CardContent>
                <div className={classes.buttons}>
                    <Box mr={"10px"}>
                        {<Button className={classes.button} variant="contained" color="secondary"
                                 onClick={recipeClick}>View Recipe</Button>}
                    </Box>
                    <Box ml={"10px"}>
                        {inBasket && <Button className={classes.button} variant="contained" color="primary"
                                             onClick={basketClick}>Remove From Basket</Button>}
                        {!inBasket && <Button className={classes.button} variant="contained" color="secondary"
                                              onClick={basketClick}>Add To Basket</Button>}
                    </Box>
                </div>
            </CardContent>
        </Card>
    )
}
