import React, {useEffect} from 'react'

import {useLocation} from 'react-router-dom'
import EditableRecipe from '../Recipes/FullScreen/EditableRecipe/EditableRecipe';
import queryString from 'query-string'
import {emptyRecipe} from '../../data/Recipe/recipeData';
import PresentableRecipe from '../Recipes/FullScreen/PresentableRecipe/PresentableRecipe';
import {useDispatch, useSelector} from "react-redux";
import {selectRecipe} from "../../data/Recipe/RecipeHelper";
import {UpdateSelectedRecipe} from "../../Store/reducers/recipeReducer";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    recipeParent:{
        flexGrow: 1,
        overflowY:'auto'
    }

}));
export default function Recipe() {
    const classes = useStyles()
    const dispatcher = useDispatch()
    const location = useLocation();
    const recipe = useSelector(state => state.recipe.selectedRecipe)
    const recipeLoading = useSelector(state => state.recipe.selectedRecipeUpdating)

    useEffect(() => {
            const recipeParams = queryString.parse(location.search);
            if (recipeParams.recipeId) {
                if (!recipe && !recipeLoading) {
                    selectRecipe(recipeParams.recipeId, recipeParams.reqPortions, dispatcher)
                }
            } else if(!recipe) {
                dispatcher({type: UpdateSelectedRecipe, payload: emptyRecipe()})
            }
        }
        , [location, dispatcher, recipe, recipeLoading])

    if (recipeLoading) {
        return (<div></div>)
    } else
        return (
            <div className={classes.recipeParent}>
                {recipe && !recipe.editMode && <PresentableRecipe/>}
                {recipe && recipe.editMode && <EditableRecipe/>}
            </div>

        )
}
