import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from "@auth0/auth0-react";
import {Provider} from "react-redux";
import {store} from "./Store/store";
import {auth0Audience, auth0ClientId, auth0Domain} from "./data/constants";
import {MuiThemeProvider} from "material-ui";

ReactDOM.render(<Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    audience={auth0Audience}
    cacheLocation={"localstorage"}
    useRefreshTokens={true}
    redirectUri={window.location.origin}
  >
  <MuiThemeProvider>
    <Provider store={store}>
    <BrowserRouter><App/></BrowserRouter>
    </Provider>
  </MuiThemeProvider>
  </Auth0Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

