import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import ToolbarBig from "./SpecificDevice/ToolbarBig";
import {CloseAlertAction} from "../../Store/reducers/alertReducer";
import {useLocation} from "react-router-dom";
import ToolbarSmall from "./SpecificDevice/ToolbarSmall";
import {clearAndNav} from "../../helper/navHelper";
import {setGlobalRecipe} from "../../Store/reducers/globalReducer";


export default function Toolbar() {
    const dispatcher = useDispatch()
    const location = useLocation();
    const alert = useSelector(state => state.alert);
    let [menuOpen, setMenuOpen] = useState(false)
    let [searchOpen, setSearchOpen] = useState(false)

    const homeLink = () => {
        setGlobalRecipe(dispatcher);
        setSearchOpen(false);
        if(location.pathname!==""&& location.pathname!=="/"){
            clearAndNav(dispatcher, "/")
        }

    }
    const screen = useSelector(state => state.global.mobileScreen);

    function toggleMenu() {
        setMenuOpen(!menuOpen)
    }

    function toggleSearch() {
        if (location.pathname !== "/" && location.pathname !== "") {
            setGlobalRecipe(dispatcher)
            clearAndNav(dispatcher, "/")
            setSearchOpen(true)
        } else {
            setSearchOpen(!searchOpen)
        }
    }

    function closeAlert() {
        dispatcher({type: CloseAlertAction})
    }

    return (
        <div>
            {screen && (
                <ToolbarBig homePage={location.pathname === "/"}
                            homeLink={homeLink} toggleSearch={toggleSearch}
                            searchOpen={searchOpen} toggleMenu={toggleMenu} menuOpen={menuOpen} alert={alert}
                            closeAlert={closeAlert}/>)}
            {!screen && (
                <ToolbarSmall homePage={location.pathname === "/"}
                              homeLink={homeLink} toggleSearch={toggleSearch}
                              searchOpen={searchOpen} toggleMenu={toggleMenu} menuOpen={menuOpen} alert={alert}
                              closeAlert={closeAlert}/>)}

        </div>
    )
}


