import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Avatar, Box, Divider, TextField} from '@material-ui/core';
import CardMenu from '../../../Toolbar/Menu/CardMenu';


const useStyles = makeStyles(theme => ({
    background:{
        backgroundColor: '#f5f5f5'
    },
    details: {
        textAlign: 'center',
    },
    fill:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    avatar: {
        margin: '30px',
        width: '90px',
        height: '90px',
    },
    dataInput:{
        width:'90%'
    }
}));


export default function EditUserFSBig({commands,user, edit, uploadImage}) {
    const classes = useStyles();

    return (
        <div>
            <Divider/>
            <Box className={classes.background} display="flex">
                <Box className={classes.fill}>
                    <Avatar alt={user.username} src={user.imageData} className={classes.avatar} />
                    <div>
                    <input type='file' id='single' onChange={uploadImage} />
                    </div>
                </Box>
                <Box className={classes.details} flexGrow={1} display="flex" flexDirection="column">
                    <Box >
                        <TextField className={classes.dataInput} label='UserName' defaultValue={user.username}
                                   onChange={(e)=> edit({username:e.target.value})}/>
                    </Box>
                    <Box>
                        <TextField className={classes.dataInput} multiline label='About' defaultValue={user.about}
                                   onChange={(e)=> edit({about:e.target.value})}/>
                    </Box>
                </Box>
                <Box className={classes.fill}>
                    <CardMenu commands={commands} recipe={user} />
                </Box>
            </Box>
        </div>
    )
}
