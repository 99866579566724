import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Box, Button, IconButton, Tooltip} from '@material-ui/core';
import Creatable from "react-select/creatable/dist/react-select.esm";
import makeAnimated from 'react-select/animated'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import {useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {Help} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    card: {
        width: '70vw',
        minWidth: '100px',
        borderRadius: 20,

    },
    CardContent: {
        display: 'flex',
        flexDirection: "column",
        padding: '10%',
    },
    button: {
        pointerEvents: "none",
    },
    inputTitle: {
        marginTop: "15px",
        alignItems: "center",
    },
    ingEdit:{
        overflowY: "visible",
        "& .MuiPaper-root": {
            overflowY: "visible",
        },
    },
    alert:{
        color:"red"
    }

}));
const animatedComponents = makeAnimated();


export default function IngredientVerifier({
                                               open,
                                               editingIngredient,
                                               selectedIngredient,
                                               ingredientOptions,
                                               loading,
                                               addIngredientToRecipe,
                                               closeDialog,
                                               createNewIngredient,
                                               setSelectedIngredient,
                                               setQty,
                                               setQtyType,
                                               searchIngredients
                                           }) {

    const qtyTypes = useSelector(state => state.global.qtyTypes)
    const {qty, qtyType, ingredientName} = editingIngredient

    const classes = useStyles();
    console.log(editingIngredient)
    return (
        <Dialog
            open={open}
            className={classes.ingEdit}
            onClose={closeDialog}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{ingredientName ? ingredientName : "Ingredient Add"}</DialogTitle>
            <DialogContent dividers={true} className={classes.ingEdit}>
                <Box display={"flex"} style={{alignItems: "center"}}>
                    <Typography variant={"subtitle1"} className={classes.inputTitle}>Ingredient:</Typography>
                    <Tooltip enterTouchDelay={0} title={
                        <React.Fragment>
                            <Typography variant={"subtitle2"} className={classes.subTitle}>
                                Search through all our verified ingredients. <br/>
                                If you need to add a new ingredient we have not seen before,
                                you can create it here, and we will verify it as soon as possible!
                            </Typography>
                        </React.Fragment>
                    }>
                        <IconButton>
                            <Help/>
                        </IconButton>
                    </Tooltip>
                </Box>
                {!ingredientOptions && <Typography variant={"caption"} className={classes.alert}>We could not find any ingredients that match your entry, please try searching or creating it below</Typography>}
                <Creatable
                           closeMenuOnSelect={true}
                           components={animatedComponents}
                           value={selectedIngredient}
                           isLoading={loading}
                           onCreateOption={createNewIngredient}
                           onInputChange={searchIngredients}
                           options={ingredientOptions}
                           onChange={(x) => setSelectedIngredient(x.value)}
                />
                <Box display={"flex"} style={{alignItems: "center"}}>
                <Typography variant={"subtitle1"} className={classes.inputTitle}>Quantity:</Typography>
                <Tooltip enterTouchDelay={0} title={
                    <React.Fragment>
                        <Typography variant={"subtitle2"} className={classes.subTitle}>
                            Enter the quantity, and select the type that best suits your item.
                            Don't worry we should be able to help users convert between most
                            types after you have selected!
                        </Typography>
                    </React.Fragment>
                }>
                    <IconButton>
                        <Help/>
                    </IconButton>
                </Tooltip>
                </Box>
                <Box display={"flex"} style={{alignItems: "center"}}>
                    <div className={classes.qty}>
                        <TextField defaultValue={qty}
                                   onChange={setQty}
                        />
                    </div>
                    {(<Select
                        className={classes.qtyType}
                        defaultValue={qtyType ? qtyType : "ABSOLOUTE"}
                        onChange={setQtyType}
                        inputProps={{'aria-label': 'Without label'}}
                    >
                        {qtyTypes.map(qtyType => (
                            <MenuItem key={qtyType.qtyType} value={qtyType.qtyType}>
                                {qtyType.qtyTypeNice}
                            </MenuItem>
                        ))}
                    </Select>)}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={addIngredientToRecipe} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}