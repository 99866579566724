import React, {useEffect, useState} from 'react'
import {Save} from '@material-ui/icons';
import {updateUser} from '../../../data/User/userData';
import _ from "lodash"
import {pullImage, saveImage} from '../../../data/photo/photoData';
import {useDispatch, useSelector} from "react-redux";
import {EditSelectedUser} from "../../../Store/reducers/userReducer";
import {addAlert} from "../../../Store/reducers/alertReducer";
import EditUserFSBig from "./SpecificDevices/EditUserBig";
import EditUserFSSmall from "./SpecificDevices/EditUserSmall";

export default function EditUserFS() {
    const [commands, setCommands] = useState([]);
    const dispatcher = useDispatch();
    const user = useSelector(state=> state.user.selectedUser)
    const screen = useSelector(state => state.global.mobileScreen);
    useEffect(() => {
            let commands = [{title:"Save Profile", icon: < Save/>, method: ()=>updateUser(user).then(()=>{
                    addAlert(dispatcher, "User Saved", "info")
                    dispatcher({type:EditSelectedUser, payload:{editMode: false}})
                })}];
            setCommands(commands)
    }, [user,dispatcher])

    function editUser(value){
        dispatcher({type:EditSelectedUser, payload:value})
    }

    const debouncedEditUser = _.debounce(editUser, 5000)

    function uploadImage(file) {
        console.log(file)
        saveImage(file)
            .then(res => {
                pullImage(res).then((x) => {
                    editUser({imageData:"data:image/png;base64," + x, imageId:res})
            })})
    }
    console.log(user)
    return (
        <div>
            {screen && (
                <EditUserFSBig user={user} commands={commands} edit={debouncedEditUser} uploadImage={uploadImage}/>)}
            {!screen && ( <EditUserFSSmall user={user} commands={commands} edit={debouncedEditUser} uploadImage={uploadImage}/>)}
        </div>
    )
}
