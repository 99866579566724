export const jsonHeader = {'Content-Type': 'application/json'};
//export const baseURL = 'http://localhost:8080/';
export const debugOn = false;
export const baseURL = process.env.REACT_APP_BASE_URL//'https://pantry-life-full-service.thepantry.life/';
export const amazonTag = process.env.REACT_APP_AMAZON_TAG
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID
export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE
//export const baseURL = "https://pantry-life-full-service-staging.thepantry.life/"
//export const auth0Domain = "dev-z4l5zxwz.eu.auth0.com"
//export const auth0ClientId = "yhJ3p1qPJgDKKb0IKuK76uO3tY2C7ZYI"
//export const auth0Audience = "http://meal-planner-ui.staging.alexforeman.me/"