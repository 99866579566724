import React, {useState} from 'react'
import PresentableRecipe from '../Recipes/RecipeCard/PresentableRecipeCard';
import {Box} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {lookupRecipes, lookupRecipesAsync} from "../../data/Recipe/RecipeHelper";
import InfiniteScroll from 'react-infinite-scroll-component';
import {makeStyles} from "@material-ui/core/styles";
import {useEffectDebugger} from "../../helper/EffectHelper";
import {Reload} from "../../Store/reducers/recipeReducer";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles(theme => ({
    child: {
        flexGrow: 1,
        overflowY: 'auto'
    },
    inProg: {
        position: "absolute",
        left: "50%",
        top: "50%",
        color: "lightgray"
    },
    inProg2: {
        position: "absolute",
        marginLeft: "-37.5px",
        left: "50%",
        color: "lightgray"
    }

}));

let queued
let queuVal
export default function RecipeListAndSearch() {
    const classes = useStyles();
    const recipes = useSelector(state => state.recipe.recipes)
    const [completedInitial, setCompletedInitial] = useState(false);
    const nextPageNumber = useSelector(state => state.recipe.nextPageNumber)
    const moreRecipes = useSelector(state => state.recipe.moreRecipes)
    const searchParams = useSelector(state => state.recipe.search)
    const dispatcher = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffectDebugger(() => {
        if (!searchParams) {
            return;
        }

        if (loading) {
            queued = true
            queuVal = searchParams
            return
        }
        setLoading(true);
        dispatcher({type: Reload})
        lookupRecipesAsync(searchParams, 0, dispatcher)
            .then(
                async () => {
                    setCompletedInitial(true)
                    if (queued) {
                        queued = false
                        await lookupRecipesAsync(queuVal, 0, dispatcher);
                    }
                    setLoading(false)
                })
    }, [searchParams])

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexWrap="wrap"
                 justifyContent='space-around'>
                {(recipes && recipes.length === 0 && !completedInitial) &&
                    <CircularProgress thickness={5} size={75} className={classes.inProg} color="inherit"/>}
                <InfiniteScroll
                    dataLength={recipes.length}
                    next={(x) => lookupRecipes(searchParams, nextPageNumber, dispatcher, x)}
                    hasMore={moreRecipes}
                    loader={<div style={{height: "100px", width: "100%", position: "relative"}}><CircularProgress
                        thickness={5} size={75} className={classes.inProg2} color="inherit"/></div>}
                    scrollableTarget="Scroller"
                >
                    {recipes.map((recipe) => {
                        return (<PresentableRecipe key={recipe.id} recipe={recipe}/>)
                    })}
                </InfiniteScroll>
            </Box>
        </Box>
    )
}
