import React from 'react'
import Basket from './Basket';
import FindUsers from './FindUsers';
import HomePage from './HomePage';
import Ingredients from './Ingredients';
import Recipe from './Recipe'
import User from './User'
import Privacy from '../Footer/Privacy'
import ContactUs from '../Footer/ContactUs'
import TCs from '../Footer/T&Cs'

import { Home, Storefront,AccountCircle, Group, ShoppingBasketOutlined,Subject } from '@material-ui/icons';
import {clearAndNav} from "../../helper/navHelper";

let home = { exact:true, link: "/", title: "Home", icon: <Home />, component: HomePage }
let profile ={ link: "/MyProfile", title: "My Profile", icon: <AccountCircle />, command:(x)=>clearAndNav(x,"/MyProfile"), component: User}
let users ={ link: "/FindUsers", title: "Find Users", icon: <Group />,component: FindUsers }
let ingredient ={ link: "/Ingredients", title: "Ingredient Search", icon: <Storefront />,component: Ingredients }
let basket ={ link: "/Basket", title: "Basket", icon: <ShoppingBasketOutlined />,component: Basket }
let recipe ={ link: "/Recipe", title: "Recipe", icon: <Subject />,component: Recipe }
let user ={ link: "/User", title: "User", icon: <Subject />,component: User }
let privacy ={ link: "/Privacy", title: "Privacy Policy", component: Privacy }
let contactUs ={ link: "/Contact", title: "Contact US", component: ContactUs }
let terms ={ link: "/Terms", title: "Terms & Conditions", component: TCs }

export function getMenuPages(isAuthenticated){
    let pages = [];
    pages.push(home)
    if(isAuthenticated){
        pages.push(profile)
    }
    pages.push(users)
    pages.push(ingredient)
    pages.push(basket)
    return pages;
}

export function getRoutingPages(isAuthenticated){
    let pages = getMenuPages(isAuthenticated);
    pages.push(recipe)
    pages.push(user)
    pages.push(privacy)
    pages.push(contactUs)
    pages.push(terms)

    return pages;
}