import React from 'react'
import UserCardBig from "./SpecificDevices/UserCardBig";
import UserCardSmall from "./SpecificDevices/UserCardSmall";
import {clearAndNav} from "../../../helper/navHelper";
import {useDispatch, useSelector} from "react-redux";


export default function UserCard({ user }) {
    const dispatcher = useDispatch()
    const screen = useSelector(state=> state.global.mobileScreen);

    function recipeClick() {
        clearAndNav(dispatcher, "/User", user, {id:user.id} )
    }


    return (
        <div>
            {screen && (
                <UserCardBig user={user} recipeClick={recipeClick}/>)}
            {!screen && (<UserCardSmall user={user} recipeClick={recipeClick}/>)}

        </div>
    )
}
