import {pullImage} from '../photo/photoData';
import {pullUsers} from './userData';
import {AddUserToUsers, UpdateSelectedUser} from "../../Store/reducers/userReducer";


export function updateSelectedUser(dispatcher, id) {
    pullUsers(id).then(
        (user) => pullAllUserDetails(user[0]).then(
            (populatedUser)=> dispatcher({type:UpdateSelectedUser, payload:populatedUser})
        ))
}


export function populatePublicUsers(dispatcher) {
    pullUsers().then(
        (users) => users.forEach(
            (user)=>pullAllUserDetails(user).then(
                (populatedUser)=> dispatcher({type: AddUserToUsers, payload:populatedUser})
            )
        )
    )
}

export async function pullAllUserDetails(user) {
    if(user) {
        let imageData = await pullImage(user.imageId);
        if(imageData){
            user.imageData = "data:image/png;base64," + imageData;
        }
    }
    return user
}
