import React from 'react'
import ToolbarMenu from "../Menu/ToolbarMenu";
import ShoppingCart from "../ShoppingCart/ShoppingCart";


import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';

import MToolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {Box, Button, Divider} from "@material-ui/core";
import SearchBar from "../SearchBar/SearchBar";
import {RECIPE, setGlobalRecipe, setGlobalUser, USER} from "../../../Store/reducers/globalReducer";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    background: {
        width: "100%",
        backgroundColor: '#f5f5f5'
    },
    flexGrow:{
        flexGrow:1
    },
    alert:{
        zIndex: 9999,
        position:"relative"
    },
    buttons:{
        display:"flex",
        flexDirection: "row",
        alignItems: "stretch",
        backgroundColor: '#f5f5f5'
    },
    button:{
        flexGrow:1,
        borderRadius:0
    }
}));


Alert.propTypes = {
    action: PropTypes.element,
    children: PropTypes.node
};


CloseIcon.propTypes = {fontSize: PropTypes.string};
export default function ToolbarBig({
                                       setSearch,
                                       searchText,
                                       homePage,
                                       toggleMenu,
                                       homeLink,
                                       menuOpen,
                                       alert,
                                       closeAlert
                                   }) {
    const classes = useStyles();
    const recipeUser = useSelector(state => state.global.recipeUser)
    let dispatcher = useDispatch();

    return (
        <div>
            <AppBar color="default" position="static" className={classes.appBar}>
                <MToolbar>
                    <Button>
                        <Typography onClick={homeLink} style={{flex: 1, textDecoration: "None"}} color="textPrimary"
                                    variant="h6" noWrap>
                            The Pantry Life
                        </Typography>
                    </Button>
                    <div className={classes.flexGrow}/>
                    <ShoppingCart/>
                    <IconButton onClick={toggleMenu} edge="start" color="inherit" aria-label="menu">
                        <MenuIcon color="primary"/>
                    </IconButton>
                    <ToolbarMenu isOpen={menuOpen} handleClose={toggleMenu}/>
                </MToolbar>
            </AppBar>
            <Divider/>
            {homePage &&
                <Box className={classes.buttons}>
                    <Button className={classes.button}
                            style={recipeUser === RECIPE ? {backgroundColor: '#34A853'} : {backgroundColor: '#f5f5f5'}}
                            onClick={() => setGlobalRecipe(dispatcher)}>Recipes</Button>
                    <Button className={classes.button}
                            style={recipeUser === USER ? {backgroundColor: '#34A853'} : {backgroundColor: '#f5f5f5'}}
                            onClick={() => setGlobalUser(dispatcher)}>Users</Button>
                </Box>
            }
            {homePage && (<SearchBar/>)}
            <Collapse in={alert.open}>
                <Alert className={classes.alert} severity={alert.severity}
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={closeAlert}
                           >
                               <CloseIcon fontSize="inherit"/>
                           </IconButton>
                       }
                >
                    {alert.message}
                </Alert>
            </Collapse>
        </div>
    )
}


