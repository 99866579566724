import {createStore} from "redux";
import alertReducer from "./reducers/alertReducer";
import shoppingCartReducer from "./reducers/shoppingCartReducer";
import recipeReducer from "./reducers/recipeReducer";
import globalReducer from "./reducers/globalReducer";
import userReducer from "./reducers/userReducer";

const rootReducer = (state = {}, action: Action) => {
    const global = state.global
    return {
        alert: alertReducer(state.alert, {...action, global}),
        cart: shoppingCartReducer(state.cart, {...action, global}),
        recipe: recipeReducer(state.recipe, {...action, global}),
        user: userReducer(state.user, {...action, global}),
        global: globalReducer(state.global, action)
    };
};

export const store = createStore(rootReducer,{},window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());