import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {AccessTime, Kitchen, Visibility} from '@material-ui/icons';
import {Button, Icon} from '@material-ui/core';
import MenuIcon from '../../../../Toolbar/Menu/CardMenu';
import Creatable from "react-select/creatable/dist/react-select.esm";
import makeAnimated from 'react-select/animated'
import NewEditIngredientBig from "../../../Ingredients/SpecificDevice/Big/NewEditIngredientBig";
import {getLabelOptions} from "../../../../../data/Recipe/RecipeHelper";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: '50px'
    },
    card: {
        width: '70vw',
        margin: 'auto',
        minHeight: '70vh',
        marginBottom: '50px',
        minWidth: '100px',
        borderRadius: 20,

    },
    CardContent: {
        display: 'flex',
        flexDirection: "column",
        padding: '10%',
    },
    media: {
        display: 'Block',
        margin: 'auto',
        width: 200,
        height: 225,
        borderRadius: 20,
    },
    mediaDetails: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        display: 'flex',
        justifyContent: 'space-around',
        background: '#F0F0F0',
    },
    mediaDetail: {
        paddingTop: 10,
        width: 35,
        textAlign: 'center',
        flexDirection: 'column',
    },
    about: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 100,
        marginBottom: 10
    },
    top: {
        display: 'flex',
        flexDirection: "row",
        marginBottom: 20
    },
    bottom: {
        display: 'flex',
        flexDirection: "row",
    },
    left: {
        width: 200,
        borderRadius: 20,
        flexBasis: '33.33%',
        display: 'flex',
        flexDirection: "column",
    },
    right: {
        flex: 1,

        flexDirection: 'column',
        paddingLeft: 20,
        display: 'flex',

    },
    menuIcon: {
        float: 'right',
        marginTop: '-10px'
    },
    title: {
        flex: 1,
    },
    header: {
        display: 'flex',
    },
    button: {
        pointerEvents: "none",
    },
    ingEdit: {
        height: '600px'
    }

}));
const animatedComponents = makeAnimated();

export default function EditableRecipeBig({
                                              recipe,
                                              updateRecipe,
                                              uploadImage,
                                              menuCommands,
                                              addIngredients,
                                              bulkIng,
                                              setBulk,
                                              setEditingIngredient,
                                              addSingleIngredient
                                          }) {
    const classes = useStyles();
    const {recipeName, ingredients, imageData, views, method, about, portions, timeInMinutes, labels} = recipe
    const [labelOptions, setLabel] = useState([])
    useEffect(()=>
    {
        getLabelOptions().then(x=> setLabel(x));
    },[])


    function updateMethod(x) {
        return updateRecipe({method: x.target.value});
    }

    function updateTime(x) {
        return updateRecipe({timeInMinutes: x.target.value});
    }

    function updateRecipeName(x) {
        return updateRecipe({recipeName: x.target.value});
    }

    function updateDescription(x) {
        return updateRecipe({about: x.target.value});
    }

    function updatePortions(x) {
        return updateRecipe({portions: x.target.value});
    }

    function updateLabels(e) {
        if(e){
            let labels = e.map(x=>x.label.toUpperCase());
            updateRecipe({labels: labels});
        }
    }

    return (
        <div className={classes.root}>
            <Card variant="outlined" className={classes.card}>
                <CardContent className={classes.CardContent}>
                    <div className={classes.top}>
                        <div className={classes.left}>
                            <CardMedia
                                className={classes.media}
                                image={imageData}
                            >
                                <div className={classes.mediaDetails}>
                                    <div className={classes.mediaDetail}>
                                        <Icon color="secondary">
                                            <AccessTime/>
                                        </Icon>
                                        <TextField
                                            id="standard-number"
                                            type="number"
                                            inputProps={{min: 0, max: 99, style: {textAlign: 'center'}}}
                                            defaultValue={timeInMinutes}
                                            onChange={updateTime}/>
                                    </div>
                                    <div className={classes.mediaDetail}>
                                        <Icon color="secondary">
                                            <Kitchen/>
                                        </Icon>
                                        <Typography>{ingredients.length}</Typography>
                                    </div>
                                    <div className={classes.mediaDetail}>
                                        <Icon color="secondary">
                                            <Visibility/>
                                        </Icon>
                                        <Typography>{views}</Typography>
                                    </div>
                                </div>
                            </CardMedia>
                            <input type='file' id='single' onChange={uploadImage}/>
                        </div>
                        <div className={classes.right}>
                            <div className={classes.header}>
                                <TextField className={classes.title} defaultValue={recipeName}
                                           label={"Recipe Name"}
                                           onChange={updateRecipeName}/>
                                <MenuIcon recipe={recipe} commands={menuCommands}/>
                            </div>
                            <TextField defaultValue={about} label={"Description"} multiline rowsMax={4}
                                       onChange={updateDescription}/>
                        </div>
                    </div>
                    <div className={classes.bottom}>
                        <div className={classes.left}>
                            <TextField
                                id="standard-number"
                                label="No Portions"
                                type="number"
                                defaultValue={portions}
                                onChange={updatePortions}
                            />
                            <br/>
                            <Creatable closeMenuOnSelect={true}
                                       placeholder={"Labels"}
                                       options={labelOptions}
                                       components={animatedComponents}
                                       defaultValue={labels.map((data, idx) => ({label: data, value: idx}))}
                                       isMulti
                                       onChange={updateLabels}
                            />
                            <TextField value={bulkIng} label={"Bulk Ingredients"} multiline rowsMax={4}
                                       onChange={setBulk}/>
                            <Button onClick={addIngredients}>Add Bulk</Button>
                            <br/>
                            <Button onClick={addSingleIngredient}>Add Single</Button>
                            {ingredients.filter(x => x.needsValidating).map((ingredient) => (
                                <NewEditIngredientBig key={ingredient.id} updateRecipe={updateRecipe}
                                                      ingredient={ingredient}
                                                      setEditingIngredient={setEditingIngredient}
                                                      valid={false}/>))}
                            <br/>
                            {ingredients.filter(x => !x.needsValidating).map((ingredient) => (
                                <NewEditIngredientBig key={ingredient.id} updateRecipe={updateRecipe}
                                                      ingredient={{...ingredient, potentialIngredients: [ingredient]}}
                                                      setEditingIngredient={setEditingIngredient} valid={true}/>))}
                        </div>
                        <div className={classes.right}>
                            <TextField label={"method"} defaultValue={method} multiline rowsMax={30}
                                       onChange={updateMethod}/>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
