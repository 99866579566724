import React from 'react'
import {Drawer, List} from '@material-ui/core'
import MenuItem from './MenuItem';
import {useAuth0} from "@auth0/auth0-react";
import {useLocation} from 'react-router-dom'
import Login from '../Login/Login';
import {getMenuPages} from '../../RootPages/RootPages';

export default function ToolbarMenu({ isOpen, handleClose }) {

    const { isAuthenticated } = useAuth0();
    let menuItems = getMenuPages(isAuthenticated);
    const location = useLocation();
    function checkLink(item) {
        return item.link !== location.pathname
    }
    return (
        <div onClick={handleClose}>
            <Drawer anchor="right" open={isOpen} onClose={handleClose}>
                <List>
                    {menuItems.filter(checkLink).map(menuItem => <MenuItem key={menuItem.title} link={menuItem.link}
                        title={menuItem.title}
                        icon={menuItem.icon}
                        command={menuItem.command}
                    />
                    )}
                    <Login/>
                </List>
            </Drawer>
        </div>
    )

}
