import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {AccessTime, Kitchen, Visibility} from '@material-ui/icons';
import {Box, Button, Icon} from '@material-ui/core';
import Ingredient from '../../../Ingredients/Ingredient'
import CardMenu from '../../../../Toolbar/Menu/CardMenu';
import {Chip} from "material-ui";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: '50px'
    },
    card: {
        width: '70vw',
        margin: 'auto',
        minHeight: '70vh',
        marginBottom: '50px',
        minWidth: '100px',
        borderRadius: 20,

    },
    CardContent: {
        display: 'flex',
        flexDirection: "column",
        padding: '10%',
    },
    media: {
        display: 'Block',
        margin: 'auto',
        width: 200,
        height: 225,
        borderRadius: 20,
    },
    mediaDetails: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        display: 'flex',
        justifyContent: 'space-around',
        background: '#F0F0F0',
    },
    mediaDetail: {
        textAlign: 'center',
        flexDirection: 'column',
    },
    about: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 100,
        marginBottom: 10
    },
    top: {
        display: 'flex',
        flexDirection: "row",
        marginBottom: 20
    },
    bottom: {
        display: 'flex',
        flexDirection: "row",
    },
    left: {
        width: 200,
        borderRadius: 20,
        flexBasis: '33.33%',
        display: 'flex',
        flexDirection: "column",
    },
    right: {
        flex: 1,

        flexDirection: 'column',
        paddingLeft: 20,
        display: 'flex',

    },
    header: {
        display: 'flex',
    },
    menuIcon: {
        float: 'right',
        marginTop: '-10px'
    },
    title: {
        flex: 1,
    },
    button: {
        pointerEvents: "none",
    },
    labelBox:{
        flexWrap:"wrap",
        padding:"2px"
    },
    chip:{
        margin:"5px"
    }

}));

export default function PresentableRecipeBig({ chipClick, recipe,userClick, commands, inBasket, basketClick, multiFactor, portionChange }) {
    const classes = useStyles();
    const { recipeName, timeInMinutes, imageData, views, ingredients, method, about,reqPortions, labels } = recipe



    return (
        <div className={classes.root}>
            <Card variant="outlined" className={classes.card}>
                <CardContent className={classes.CardContent}>
                    <div className={classes.top}>
                        <div className={classes.left}>
                            <CardMedia
                                className={classes.media}
                                image={imageData}
                                title={recipeName}
                            >
                                <div className={classes.mediaDetails}>
                                    <div className={classes.mediaDetail}>
                                        <Icon color="secondary">
                                            <AccessTime />
                                        </Icon>
                                        <Typography>{timeInMinutes}</Typography>
                                    </div>
                                    <div className={classes.mediaDetail}>
                                        <Icon color="secondary">
                                            <Kitchen />
                                        </Icon>
                                        <Typography>{ingredients.length}</Typography>
                                    </div>
                                    <div className={classes.mediaDetail}>
                                        <Icon color="secondary">
                                            <Visibility />
                                        </Icon>
                                        <Typography>{views}</Typography>
                                    </div>
                                </div>
                            </CardMedia>
                        </div>
                        <div className={classes.right}>
                            <div className={classes.header}>
                                <Typography className={classes.title} variant="h6" color="textPrimary" component="p">
                                    {recipeName}
                                </Typography>
                                <CardMenu recipe={recipe} commands={commands} />
                            </div>
                            <Button>
                                <Typography onClick={() => userClick(recipe.user.id)} variant="caption" color="textPrimary" component="p">
                                    Recipe By: {recipe.user ? recipe.user.username : "N/A"}
                                </Typography>
                            </Button>
                            <Box display={"flex"} className={classes.labelBox}>
                                {labels.map(label=> <Chip key={label} style={{margin:1}} className={classes.chip} onClick={()=>chipClick(label)} >{label}</Chip>)}
                            </Box>
                            <Typography className={classes.about} variant="body2" color="textSecondary" component="p">
                               {about}
                            </Typography>
                            {inBasket && <Button variant="contained" color="secondary" onClick={basketClick}>Remove From Basket</Button>}
                            {!inBasket && <Button variant="contained" color="secondary" onClick={basketClick}>Add To Basket</Button>}
                        </div>
                    </div>
                    <div className={classes.bottom}>
                        <div className={classes.left}>
                            <TextField
                                id="standard-number"
                                label="No Portions"
                                type="number"
                                defaultValue={reqPortions}
                                onChange={(x)=>portionChange(x.target.value)}
                            />
                            <br />
                            {ingredients.map(x => (<div key={x.ingredientId}><Ingredient  ingredient={x} multiFactor={multiFactor} /><Divider /></div>))}
                        </div>
                        <div className={classes.right}>
                            {method.split("\n").map((x, idx) =>
                                <Typography key={idx} variant="body2" color="textPrimary" component="p">
                                    {x}
                                    <br />
                                </Typography>

                            )}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
