export const UpdateAlertAction = "UPDATE";
export const CloseAlertAction = "CLOSE";

export function addAlert(dispatcher, message, severity, timeout){
    dispatcher({type:UpdateAlertAction, payload:{message:message, severity:severity}})
    if(timeout){
        setTimeout(()=>dispatcher({type:CloseAlertAction}), timeout);
    }else {
        setTimeout(()=>dispatcher({type:CloseAlertAction}), 5000);
    }
}

const initialState = {
    open: false,
    message: "",
    severity: "info",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CloseAlertAction: {
            return {
                ...state,
                open: false,
                message: state.message,
                type: state.type,
            };
        }
        case UpdateAlertAction: {
            const {message, type, severity} = action.payload;
            return {
                ...state,
                message: message,
                type: type,
                severity: severity,
                open: true,
            }
        }
        default:
            return state;
    }
}