import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {ExitToApp, PersonAdd} from '@material-ui/icons';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core'

export default () => {
    const { loginWithRedirect,logout,isAuthenticated} = useAuth0();
    if(isAuthenticated){
        return(
            <ListItem button onClick={() => logout({ returnTo: window.location.origin })}>
                <ListItemIcon><ExitToApp/></ListItemIcon>
                <ListItemText>LogOut</ListItemText>
            </ListItem>
        )
    }

    return(
        <ListItem button onClick={()=>{

            loginWithRedirect().then((c)=>console.log(c))
        }
        } >
            <ListItemIcon><PersonAdd/></ListItemIcon>
            <ListItemText>LogIn</ListItemText>
        </ListItem>
    )

}
