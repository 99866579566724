import React, {useEffect, useState} from 'react'

import PresentableRecipe from '../../Recipes/RecipeCard/PresentableRecipeCard';
import {makeStyles} from '@material-ui/core/styles';

import {Button, Divider, Icon, Typography} from '@material-ui/core';
import Ingredient from '../../Recipes/Ingredients/Ingredient';
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import {amazonTag} from "../../../data/constants";

const useStyles = makeStyles(theme => ({
    parent: {
        blah:theme.spacing(1),
        overflowY: 'auto',
        position: "sticky",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-Around",
        flexGrow: 1,
    },
    miniParent: {
        position: "sticky",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-Around",
    },
    container: {
        paddingTop: "30px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        marginRight: "auto",
        marginLeft: "auto",
        scrollBehavior: "smooth",
        display: "flex",
        maxWidth: "100vw",
        alignContent: "center",
    },
    card: {
        position: "relative",
        display: "flex",
        /*   margin-left: -50px; */
        transition: "0.4s ease-out",
        left: "0px",
        "&:not(:first-child)": {
            marginLeft: "-150px"
        },
        "&:hover": {
            transform: "translateY(-20px)",
        },
        "&:hover ~ &": {
            position: "relative",
            transform: "translateX(200px)",
        },
        "&:hover .close": {
            display: "flex",
        }
    },
    clickLeft: {
        marginTop: "auto",
        marginBottom: "auto",
        top: 0,
        left: "10px",
        bottom: 0,
        width: "50px",
        height: "50px",
        position: "absolute",
        backgroundColor: "black",
        zIndex: "99999",
        borderRadius: '30px',
        opacity: 0.7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',

        color: "white"

    },
    clickRight: {
        marginTop: "auto",
        marginBottom: "auto",
        top: 0,
        bottom: 0,
        right: "10px",
        width: "50px",
        height: "50px",
        position: "absolute",
        backgroundColor: "black",
        zIndex: "99999",
        borderRadius: '30px',
        opacity: 0.7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        color: "white"
    },
    ingredients: {
        width: "50vw",
    },
    overflow: {
        marginLeft: "10px",
        marginRight: "10px",
        overflowY: "scroll",
    },
    ingredientCard: {
        marginBottom: "20px",
        marginTop: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90vw",
        flexGrow: 1,
        overflowY: "scroll",
        padding: "20px",
    },
    addButtons: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        padding: "10px",
    },
    form: {
        flex: "1",
        display: "flex",
        justifyContent: "center",
    },
    button: {
        width: "90%",

    },
    unverifiedIngredients: {
        paddingLeft: "50px",
        paddingRight: "50px",
        fontStyle: "italic"
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },
    paper1: {
        marginTop: theme.spacing(8),
        display: 'flex',
        minWidth:"200px",
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        width: '50vw',
        marginLeft: "auto",
        marginRight: "auto",
        padding: "20px",
        borderRadius: "20px",
    },

}));


export default function CheckoutFSBig({ingredientList, recipes, basket, unverifiedBasket}) {
    const classes = useStyles();
    const [showNav, setShowNav] = useState(window.innerWidth / 125 < recipes.length);
    const [unverifiedIngredient, setUnverified] = useState(ingredientList.filter(x => !x.verfiedID).length !== 0)


    useEffect(() => {
            const x = () => setShowNav(window.innerWidth / 125 < recipes.length)
            window.addEventListener("resize", x)
            return () => window.removeEventListener("resize", x)
        }, [recipes]
    )

    useEffect(() => {
        setUnverified(ingredientList.filter(x => !x.verfiedID).length !== 0)
        }, [ingredientList]
    )

    useEffect(() => {
            setShowNav(window.innerWidth / 125 < recipes.length)
        }, [recipes]
    )

    if(recipes.length<=0){
        return (
            <div className={classes.paper1}>
                <Typography align={"center"}>No Items are in your basket!</Typography>
            </div>
        )
    }
    //return (
    return (
        <div className={classes.parent}>
            <div className={classes.miniParent}>
                {showNav && (<div>
                    <Icon className={classes.clickLeft}
                          onClick={() => document.getElementById('myRecipeContainer').scrollLeft += -300}>
                        <ArrowBack/>
                    </Icon>
                    <Icon className={classes.clickRight}
                          onClick={() => document.getElementById('myRecipeContainer').scrollLeft += 300}>
                        <ArrowForward/>
                    </Icon>
                </div>)}
                <div className={`${classes.container} ${showNav && classes.overflow}`} id={"myRecipeContainer"}>
                    {recipes.map((recipe) => {
                        return (
                            <div className={classes.card}>
                                <PresentableRecipe size={200} checkout={true} key={recipe.id} recipe={recipe}/>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Card className={classes.ingredientCard}>
                <div className={classes.addButtons}>
                    <form className={classes.form} target="_blank" method="POST"
                          action={`https://www.amazon.co.uk/afx/ingredients/landing?tag=${amazonTag}`}>
                        <input type="hidden" name="ingredients" value={basket}/>
                        <Button className={classes.button} variant="contained" color="secondary" type="submit">
                            Checkout
                        </Button>
                    </form>
                    {unverifiedIngredient && (<form className={classes.form} target="_blank" method="POST"
                                                       action={`https://www.amazon.co.uk/afx/ingredients/landing?tag=${amazonTag}`}>
                        <input type="hidden" name="ingredients" value={unverifiedBasket}/>
                        <Button className={classes.button} variant="contained" color="secondary" type="submit">
                            Checkout Unverified
                        </Button>
                    </form>)}
                </div>
                {unverifiedIngredient && (
                    <div className={classes.unverifiedIngredients}><Typography>Your basket contains some ingredients
                        that have not yet been verified by ThePantry.life, Feel free to search for them yourself(they
                        can be found at the bottom of the ingredient list), or you can try the unverfified Chckout option
                        above!</Typography></div>
                )}
                {ingredientList.filter(x => x.verfiedID).map(x => (
                    <div className={classes.paper}><Ingredient key={x.id} ingredient={x} multiFactor={1}
                                                               addToCart={true}
                                                               unverified={!x.verfiedID}/><Divider/></div>))}
                {unverifiedIngredient && (<div>
                    <br/>
                    <Typography variant={"h4"}>Unverified Ingredients</Typography>
                    <br/>
                    {ingredientList.filter(x => !x.verfiedID).map(x => (
                        <div className={classes.paper}><Ingredient key={x.id} ingredient={x} multiFactor={1}
                                                                   addToCart={true}
                                                                   unverified={!x.verfiedID}/><Divider/></div>))}
                </div>)}
            </Card>

        </div>
    )
}
