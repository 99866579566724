import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {ArrowBack, ArrowForward, Help, LibraryAdd} from '@material-ui/icons';
import {Box, Button, IconButton, InputAdornment, OutlinedInput, Tooltip} from '@material-ui/core';
import MenuIcon from '../../../../Toolbar/Menu/CardMenu';
import Creatable from "react-select/creatable/dist/react-select.esm";
import makeAnimated from 'react-select/animated'
import NewEditIngredientBig from "../../../Ingredients/SpecificDevice/Big/NewEditIngredientBig";
import Collapse from "@material-ui/core/Collapse";
import {getLabelOptions, validateDetails, validateIngTab} from "../../../../../data/Recipe/RecipeHelper";
import UploadImage from "../../../../User/EditUser/SpecificDevices/UploadImage";
import {useDispatch} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    card: {
        marginTop: "10px",
        width: '90vw',
        margin: 'auto',
        marginBottom: '50px',
        minWidth: "250px",
        borderRadius: 20,

    },
    CardContent: {
        display: 'flex',
        flexDirection: "column",
    },
    media: {
        minWidth: "250px",
        minHeight: "250px",
        display: 'Block',
        marginTop: '1vh',
        width: "80vw",
        height: "80vw",
        borderRadius: 20,
    },
    about: {
        width: "100%",
        marginBottom: 10
    },
    top: {
        display: 'flex',
        flexDirection: "column",
        marginBottom: 20
    },
    bottom: {
        display: 'flex',
        flexDirection: "row",
    },
    left: {
        width: 200,
        borderRadius: 20,
        flexBasis: '33.33%',
        display: 'flex',
        flexDirection: "column",
    },
    right: {
        flex: 1,

        flexDirection: 'column',
        paddingLeft: 20,
        display: 'flex',

    },
    header: {
        display: 'flex',
    },
    menuIcon: {
        float: 'right',
        marginTop: '-10px'
    },
    title: {
        flex: 1,
        marginBottom: "10px"
    },
    button: {
        width: "33vw",
        fontSize: "0.7em"
    },
    buttons: {
        justifyContent: "space-around",
        margin: "auto",
        paddingTop: "2vh",
        display: "flex",
        paddingBottom: "2vh"
    },
    ingEdit: {
        height: '600px'
    },
    buttons2: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        backgroundColor: '#f5f5f5'
    },
    button2: {
        flexGrow: 1,
        borderRadius: 0
    },
    inputTitle: {
        marginTop: "15px",
        alignItems: "center",
    },
    inProg: {
        marginLeft: "auto",
        marginRight: "auto",
        color: "lightgray"
    },

}));
const animatedComponents = makeAnimated();

export default function EditableRecipeBig({
                                              imageLoading,
                                              recipe,
                                              updateRecipe,
                                              uploadImage,
                                              menuCommands,
                                              addIngredients,
                                              bulkIng,
                                              setBulk,
                                              setEditingIngredient,
                                              addSingleIngredient
                                          }) {
    const classes = useStyles();
    const {recipeName, ingredients, imageData, method, about, portions, timeInMinutes, labels} = recipe
    const [labelOptions, setLabel] = useState([])
    const [detailsTab, setDetailsTab] = useState(true)
    const [ingredientsTab, setIngTab] = useState(false)
    const [methodTab, setMethodTab] = useState(false)
    const dispatcher = useDispatch();
    const DETAILS = "DETAILS";
    const METHOD = "METHOD";
    const ING = "ING";


    function toggleTab(tab) {
        if (tab === DETAILS) {
            setDetailsTab(true)
            setIngTab(false)
            setMethodTab(false)
        }
        if (tab === METHOD) {
            setDetailsTab(false)
            setIngTab(false)
            setMethodTab(true)
        }
        if (tab === ING) {
            setDetailsTab(false)
            setIngTab(true)
            setMethodTab(false)
        }
    }

    useEffect(() => {
        getLabelOptions().then(x => setLabel(x));
    }, [])

    function updateMethod(x) {
        return updateRecipe({method: x.target.value});
    }

    function updateTime(x) {
        return updateRecipe({timeInMinutes: x.target.value});
    }

    function updateRecipeName(x) {
        return updateRecipe({recipeName: x.target.value});
    }

    function updateDescription(x) {
        return updateRecipe({about: x.target.value});
    }

    function updatePortions(x) {
        return updateRecipe({portions: x.target.value});
    }

    function updateLabels(e) {
        if (e) {
            let labels = e.map(x => x.label.toUpperCase());
            updateRecipe({labels: labels});
        }
    }

    function next() {

        if (detailsTab) {
            if (validateDetails(recipe, dispatcher)) {
                toggleTab(ING)
                windowRef.current.scrollIntoView({behavior: 'smooth'})
            }
        } else if (ingredientsTab) {
            if (validateIngTab(recipe, dispatcher)) {
                toggleTab(METHOD)
                windowRef.current.scrollIntoView({behavior: 'smooth'})
            }

        }

    }

    function prev() {
        windowRef.current.scrollIntoView({behavior: 'smooth'})
        if (methodTab) {
            toggleTab(ING)
        } else if (ingredientsTab) {
            toggleTab(DETAILS)
        }

    }

    const windowRef = useRef(null)

    return (
        <div className={classes.root} ref={windowRef}>
            <Box className={classes.buttons2}>
                <Button className={classes.button2}
                        style={detailsTab ? {backgroundColor: '#34A853'} : {backgroundColor: '#f5f5f5'}}
                        onClick={() => toggleTab(DETAILS)}>Details</Button>
                <Button className={classes.button2}
                        style={ingredientsTab ? {backgroundColor: '#34A853'} : {backgroundColor: '#f5f5f5'}}
                        onClick={() => toggleTab(ING)}>Ingredients</Button>
                <Button className={classes.button2}
                        style={methodTab ? {backgroundColor: '#34A853'} : {backgroundColor: '#f5f5f5'}}
                        onClick={() => toggleTab(METHOD)}>Method</Button>
            </Box>
            <Card variant="outlined" className={classes.card}>

                <CardContent className={classes.CardContent}>
                    <div className={classes.header}>
                        <TextField className={classes.title} defaultValue={recipeName}
                                   label={"Recipe Name"}
                                   multiline={true}
                                   rowsMax={2}
                                   onChange={updateRecipeName}/>
                        <MenuIcon recipe={recipe} commands={menuCommands} withoutList={true}/>
                    </div>
                    <Collapse in={detailsTab}>
                        <div className={classes.top}>
                            <Creatable closeMenuOnSelect={true}
                                       placeholder={"Labels"}
                                       components={animatedComponents}
                                       options={labelOptions}
                                       defaultValue={labels.map((data, idx) => ({label: data, value: idx}))}
                                       isMulti
                                       onChange={updateLabels}
                            />
                            {imageLoading &&
                                <CircularProgress thickness={5} size={75} className={classes.inProg} color="inherit"/>}
                            {!imageLoading && <CardMedia
                                className={classes.media}
                                image={imageData ? imageData : process.env.PUBLIC_URL + "/notFoundImage.svg"}
                                style={imageData ? {} : {backgroundSize: "50%"}}
                            >
                                <UploadImage currentImage={imageData} saveImage={uploadImage}/>
                            </CardMedia>}
                            <Typography variant={"subtitle1"} className={classes.inputTitle}>Total Time:</Typography>
                            <div style={{flexGrow: 1}}/>
                            <OutlinedInput
                                endAdornment={<InputAdornment position="end">Minutes</InputAdornment>}

                                id="standard-number"
                                type="number"
                                defaultValue={timeInMinutes}
                                onChange={updateTime}/>

                            <Typography variant={"subtitle1"} className={classes.inputTitle}>Recipe
                                Description:</Typography>
                            <OutlinedInput className={classes.about}
                                           defaultValue={about}
                                           placeholder={"This absolutely fantastic recipe was created by my great " +
                                               "great great grandmother, she was such a dear! and boy did she love baking"}
                                           multiline
                                           rows={10}
                                           onChange={updateDescription}/>
                        </div>
                    </Collapse>

                    <Collapse in={ingredientsTab}>
                        <Typography variant={"subtitle1"} className={classes.inputTitle}>No of Portions:</Typography>
                        <OutlinedInput
                            style={{width: "100%"}}
                            id="standard-number"
                            type="number"
                            defaultValue={portions}
                            onChange={updatePortions}
                        />
                        <Box display={"flex"}>
                            <Typography variant={"subtitle1"} className={classes.inputTitle}>Add
                                Ingredients:</Typography>
                            <Tooltip enterTouchDelay={0} title={
                                <React.Fragment>
                                    <Typography variant={"subtitle2"} className={classes.subTitle}>Either enter all your
                                        ingredients in the box below and
                                        use the "Quick Add" Function: you will then need to confirm each of the
                                        highlighted items below.
                                        <br/>
                                        Or add each item individually using the "Add Single" Function</Typography>
                                </React.Fragment>
                            }>
                                <IconButton>
                                    <Help/>
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <OutlinedInput className={classes.about}
                                       value={bulkIng}
                                       placeholder={"1 beef tomatoe\n1 buffalo mozzarella\n1 bunch basil\n3 tbsp olive oil "}
                                       multiline rows={5}
                                       onChange={setBulk}/>
                        <div className={classes.buttons}>
                            <Button className={classes.button} variant="contained" onClick={addIngredients}>Quick
                                Add</Button>
                            <Button className={classes.button} variant="contained" onClick={addSingleIngredient}>Add
                                Single</Button>
                        </div>
                        <Box display={"flex"}>
                            <Typography variant={"subtitle1"} className={classes.inputTitle}>Ingredient
                                List:</Typography>
                            <Tooltip enterTouchDelay={0} title={
                                <React.Fragment>
                                    <Typography variant={"subtitle2"} className={classes.subTitle}>
                                        Highlighted items need to be confirmed!
                                        <br/>
                                        Please click the search icon next to them to ensure the correct
                                        ingredient has been selected.
                                    </Typography>
                                </React.Fragment>
                            }>
                                <IconButton>
                                    <Help/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {ingredients.filter(x => x.needsValidating).map((ingredient) => (
                            <NewEditIngredientBig key={ingredient.ingredientId} updateRecipe={updateRecipe}
                                                  ingredient={ingredient}
                                                  setEditingIngredient={setEditingIngredient}
                                                  valid={false}/>))}
                        <br/>
                        {ingredients.filter(x => !x.needsValidating).map((ingredient) => (
                            <NewEditIngredientBig key={ingredient.ingredientId} updateRecipe={updateRecipe}
                                                  ingredient={{...ingredient, potentialIngredients: [ingredient]}}
                                                  setEditingIngredient={setEditingIngredient} valid={true}/>))}
                    </Collapse>
                    <Collapse in={methodTab}>
                        <Typography variant={"subtitle1"} className={classes.inputTitle}>Method:</Typography>
                        <OutlinedInput
                            className={classes.about}
                            defaultValue={method}
                            multiline
                            placeholder={"1. Do this\n\n2. Do that\n\n3. Nearly Ready!\n\n4. Oh My, what a fantastic meal I have created! "}
                            rows={20}
                            onChange={updateMethod}/>
                    </Collapse>
                    <Box display="flex" style={{paddingTop: "10px"}}>
                        {!detailsTab && <IconButton style={{color: '#34A853'}} variant="contained"
                                                    onClick={prev}><ArrowBack/></IconButton>}
                        {!methodTab &&
                            <IconButton style={{color: '#34A853', marginLeft: "auto"}} variant="contained"
                                        onClick={next}><ArrowForward/></IconButton>}
                        {methodTab && <IconButton style={{marginLeft: "auto"}} variant="contained"
                                                  onClick={() => menuCommands[0].method(recipe)}><LibraryAdd/></IconButton>}
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
}
