import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Button} from '@material-ui/core';


const useStyles = makeStyles(theme => ({

    holder: {
        display: 'flex',
        flexDirection: 'row',
    },

    right: {
        height: 'fit-content',
        flex: 1,
        right: 0,
        flexDirection: 'row',
        marginRight: 0,
        paddingLeft: 20,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 'auto',
    },
    qty: {
        paddingRight: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    unverified:{
        color: "red"
    }

}));
export default function IngredientBig({ingredient, multiFactor, addToCart,unverified, capitalize, handleQty,update }) {
    const classes = useStyles();
    return (
        <div className={`${classes.holder} ${ unverified ? classes.unverified : ""}` }>
            <div className={classes.qty}>
                <Typography className={classes.left}
                            variant="body1">{capitalize(ingredient.name)}</Typography>
            </div>
            <div className={classes.right}>
                <div className={classes.qty}>
                    <Typography variant="body1">{handleQty(ingredient.qty * multiFactor)}</Typography>
                </div>
                {ingredient.validTypes && <Select
                    MenuProps={{MenuListProps:{
                        'dense': true
                    }}}
                    value={ingredient.qtyType}
                    onChange={(x)=>update(x.target.value)}
                    inputProps={{'aria-label': 'Without label'}}
                >
                    {ingredient.validTypes.map(qtyType => (
                        <MenuItem key={qtyType.qtyType} value={qtyType.qtyType}>
                            {qtyType.qtyTypeNice}
                        </MenuItem>
                    ))}
                </Select>}
                {addToCart && <Button disabled={ingredient.amazonUrl!==null} variant="contained" color="primary" target="_blank"
                                                               onClick={x => window.open(ingredient.amazonUrl, 'newwindow', 'width=1000,height=1000')}
                >Buy Now</Button>}
            </div>
        </div>
    )


}
