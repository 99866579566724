import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import {Icon, TextField, Typography} from '@material-ui/core';
import {Face, Forward} from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    card: {
        overflow: "visible",
        borderRadius: 20,
        position:"relative"
    },
    CardContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10%',
    },
    media: {
        display: 'Block',
        margin: 'auto',
        borderRadius: 20,
    },
    title: {
        height: "3rem",
        overflow: "hidden"
    },
    portions: {
        display: "flex",
        paddingTop:"10px",
        flexDirection: "row",
        alignItems: 'center',

    },
    close: {
        display: "none",
        position: "absolute",
        top: '-5px',
        right: '-5px',
        width: '30px',
        height: '30px',
        boxShadow: "0px 10 10px 10px rgba( 0, 0, 0, .25 )",
        borderRadius: "20px",
        background: "black",
        "&:before, &:after": {
            content: '""',
            width: "55%",
            height: "2px",
            background: "white",
            position: "absolute",
            top: "48%",
            left: "22%",
            transform: "rotate(-45deg)",
            transition: "all 0.3s ease-out"
        },
        "&:after": {
            transform: "rotate(45deg)",
            transition: "all 0.3s ease-out"
        },
        "&:hover": {
            "&:before, &:after": {
                transform: "rotate(180deg)",
            }
        }
    },
    recipeButton:{
        marginLeft:"auto",
        "&:hover": {
            transform:"scale(1.2)"
        }
    },


}));

export default function CheckoutRecipeCardBig({ size, recipe, basketClick, recipeClick, update}) {
    const classes = useStyles();
    const {recipeName,  imageData,reqPortions} = recipe

    return (
        <Card variant="outlined"  style={{width:size*1.25}} className={classes.card}>

            <CardContent  className={classes.CardContent}>
                <Typography className={classes.title}>
                    {recipeName}
                </Typography>
                <CardMedia
                    style={{width:size, height:size*1.125}}
                    className={classes.media}
                    image={imageData}
                    title={recipeName}
                />
                <div className={classes.portions}>
                    <Icon color="secondary">
                        <Face/>
                    </Icon>
                    <TextField
                        id="standard-number"
                        type="number"
                        inputProps={{min: 0, max: 99, style: {textAlign: 'center'}}}
                        value={reqPortions}
                        onChange={(x) => update({reqPortions: x.target.value})}
                    />
                    <Icon onClick={recipeClick} color="secondary" className={classes.recipeButton}>
                        <Forward className={classes.recipeClick}/>
                    </Icon>
                </div>

            </CardContent>
            <div className={`close ${classes.close}`} onClick={basketClick}>
            </div>
        </Card>
    )
}
