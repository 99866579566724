import React from 'react'
import RecipeListAndSearch from '../Recipes/RecipeListAndSearch';
import {useSelector} from "react-redux";
import {RECIPE, USER} from "../../Store/reducers/globalReducer";
import UserListAndSearch from "../User/UserListAndSearch";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    flex: {
        display: "flex",
        flexDirection: "Column",
        flexGrow: 1,
    },
    child: {
        flexGrow: 1,
        overflowY: 'auto'
    },
}));
export default function HomePage() {
    const classes = useStyles();
    const recipeUser = useSelector(state => state.global.recipeUser)

    return (
        <div className={classes.child} id="Scroller">
            <div  hidden={recipeUser!==RECIPE}>
            <RecipeListAndSearch/>
            </div>
            <div hidden={recipeUser!==USER}>
                <UserListAndSearch/>
            </div>
        </div>
    )

}
