import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import MToolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {useSelector} from "react-redux";


const useStyles = makeStyles(theme => ({
    appBar: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        top: 'auto',
        bottom: 0
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    copyright: {
        backgroundColor: 'white'
    },
    footer: {
        marginTop: 'auto',
        height:'100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    addMargin:{

    }
}));

export default function Footer() {
    const classes = useStyles();
    const screen = useSelector(state=> state.global.mobileScreen);


    return (
            <AppBar color="default" className={classes.appBar}>
                {screen &&
                    <MToolbar className={classes.toolbar}>
                        <Typography><Link component={RouterLink} to="/Contact">Contact Us</Link></Typography>
                        <Typography><Link component={RouterLink} to="/Terms">Terms & Conditions</Link></Typography>
                        <Typography><Link component={RouterLink} to="/Privacy">Privacy Policy</Link></Typography>
                    </MToolbar>
                }

                <Typography variant="body2" className={classes.copyright} align="center">As an Amazon Associate I earn from
                    purchases.</Typography>
                <Typography variant="body2" className={classes.copyright} align="center">
                    {'Copyright © '}
                    <Link color="inherit"
                          href="https://find-and-update.company-information.service.gov.uk/company/13087131">
                        The Pantry Life
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </AppBar>

)
}

