import {pullConversionFactors} from "../../data/Ingredient/ingredientData";

export const UpdateConversionFactors = "UPDATECONVERSIONFACTORS";
export const UpdateUser = "UPDATEUSER"
export const Nav = "NAV"
export const screenSetter = "SCREENSETTER";
export const recipeUser = "RECIPEUSER";
export const RECIPE = "RECIPE"
export const USER = "USER"

export async function asyncInitGlobalState(dispatcher) {
    await pullConversionFactors().then(x => dispatcher({type: UpdateConversionFactors, payload: x}))
}

export async function setUser(user, dispatcher) {
    await dispatcher({type: UpdateUser, payload: user})
}

export async function setGlobalRecipe(dispatcher, recipeOrUser) {
    await dispatcher({type: recipeUser, payload: RECIPE})
}
export async function setGlobalUser(dispatcher, recipeOrUser) {
    await dispatcher({type: recipeUser, payload: USER})
}


const initialState = {
    page: undefined,
    recipeUser: "RECIPE",
    mobileScreen: false,
    conversionFactors: {},
    qtyTypes: [],
    user: undefined
};

export default function (state = initialState, action) {
    switch (action.type) {
        case recipeUser:{
            return {
                ...state,
                recipeUser: action.payload
            }
        }
        case screenSetter: {
            return {
                ...state,
                mobileScreen: action.payload
            }
        }
        case UpdateConversionFactors: {
            return {
                ...state,
                conversionFactors: action.payload,
                qtyTypes: Object.values(action.payload["ALL"])
            };
        }
        case UpdateUser: {
            return {
                ...state,
                user: action.payload,
            };
        }
        case Nav: {
            return {
                ...state,
                page: action.payload
            }
        }
        default:
            return state;
    }
}
