import React, {useEffect} from 'react'

import {useLocation} from 'react-router-dom'
import UserFS from '../User/UserFs/UserFS';
import queryString from 'query-string'
import {useDispatch, useSelector} from "react-redux";
import {pullAllUserDetails, updateSelectedUser} from "../../data/User/userHelper";
import EditUserFS from "../User/EditUser/EditUserFS";
import {getLoggedInUser} from "../../data/User/userData";
import {UpdateSelectedUser} from "../../Store/reducers/userReducer";
import {setGlobalRecipe} from "../../Store/reducers/globalReducer";


export default function User() {
    const location = useLocation();
    const dispatcher = useDispatch();
    const user = useSelector(state => state.user.selectedUser)
    const userLoading = useSelector(state => state.user.selectedUserUpdating)

    useEffect(()=>
    {
        setGlobalRecipe(dispatcher)
    }
    ,[dispatcher])

    useEffect(() => {
        if (!userLoading) {
            let userParams = queryString.parse(location.search);
            if(userParams.id){
                updateSelectedUser(dispatcher,userParams.id)
            }
            else {
                getLoggedInUser().then(x=>pullAllUserDetails(x).then((e)=>dispatcher({type:UpdateSelectedUser, payload:e})))
            }
        }
    }, [location,userLoading, dispatcher])

    let userMode = (user&&!user.editMode) ?<UserFS/> : user ?<EditUserFS/>:<div/>;

    return (
        userMode

    )
}
