import React from 'react'
import {Link} from 'react-router-dom'
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core'
import {useDispatch} from "react-redux";

export default function MenuItem({title, link, icon, command}) {
    const dispatcher = useDispatch()
    const CustomLink = React.forwardRef((props, ref) => <Link to={link} {...props} />);
    if (command) {
        return (
            <ListItem button onClick={()=>command(dispatcher)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{title}</ListItemText>
            </ListItem>
        )
    }
    return (
        <ListItem button component={CustomLink}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{title}</ListItemText>
        </ListItem>
    )
}
