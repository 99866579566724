import { get, post} from '../../helper/restHelper';
import { debug } from '../../helper/logHelper';
import {jsonHeader} from '../constants'
let debugOn = false;

export async function  pullViews(id,type,minutes) {
    try {
        let url = 'View?id='+ id + '&viewType='+type+'&minutes='+ minutes
        debug("Pulling Views",debugOn)
        debug(url,debugOn)
        let data = (await get(url)).json()
        debug(data,debugOn)
        return(data)
    } catch (error) {
        console.log(error)
    }
}

export async function incrementView(id,type){
    try {
        
        let data = {id:id, viewType: type}
        let url = 'View?id='+ id + '&viewType='+type
        debug("incrementView",debugOn)
        debug(url,debugOn)
        await post("View",JSON.stringify(data),jsonHeader)           
        debug("Incremented View",debugOn)
    } catch (error) {
        console.log(error)
    }
}