import {populateSingleRecipe} from "../../data/Recipe/RecipeHelper";
import {pullShoppingInformation} from "../../data/Store/storeData";
import {combineIngredientAndDetails} from "../../data/Ingredient/ingredientHelper";


export const AddItemToCartAction = "ADDTOCART";
export const UpdateItemInCart = "UPDATEITEMINCART"
export const UpdateCartData = "UPDATECARTDATA";
export const RemoveItemFromCartAction = "REMOVEFROMCART";

export function addRecipeToCart(dispatcher, recipeId, portions) {
    populateSingleRecipe(recipeId, portions, dispatcher, AddItemToCartAction)
}

export function updateCart(dispatcher, recipes, company) {
    pullShoppingInformation(recipes, company).then(x => dispatcher({type: UpdateCartData, payload: x}))
}

export function removeItemFromCart(dispatcher, id) {
    dispatcher({type: RemoveItemFromCartAction, payload: {recipeId: id}})
}

const initialState = {
    recipesInCart: JSON.parse(localStorage.getItem("recipesInCart")) || [],
    sizeOfCart: JSON.parse(localStorage.getItem("sizeOfCart")) || 0,
    ingredientsInCart: JSON.parse(localStorage.getItem("ingredientsInCart")) || [],
    store: "AMAZON",
    baskets: JSON.parse(localStorage.getItem("baskets")) || [],
    unverifiedBasket: localStorage.getItem("unverifiedBasket") || "",
};

function localStorageSave(state) {
    localStorage.setItem("baskets", JSON.stringify(state.baskets))
    localStorage.setItem("unverifiedBasket", state.unverifiedBasket)
    localStorage.setItem("ingredientsInCart", JSON.stringify(state.ingredientsInCart))
    localStorage.setItem("recipesInCart", JSON.stringify(state.recipesInCart))
    localStorage.setItem("sizeOfCart", JSON.stringify(state.sizeOfCart))
}

export default function (state = initialState, action) {
    switch (action.type) {
        case AddItemToCartAction: {
            action.payload.ingredients = combineIngredientAndDetails(action.payload.ingredients, action.payload.ingredientDetails, action.global.conversionFactors);
            const recipes = [action.payload].concat(state.recipesInCart)
            const newState = {
                ...state,
                recipesInCart: recipes,
                sizeOfCart: recipes.length
            }
            localStorageSave(newState)
            return newState;
        }
        case UpdateItemInCart: {
            const updatedRecipe = action.payload;
            const recipes = [...state.recipesInCart]
            const index = recipes.findIndex((x) => x.id === updatedRecipe.id);
            const newRecipe = {...recipes[index], ...updatedRecipe}
            recipes[index] = newRecipe
            const newState = {
                ...state,
                recipesInCart: recipes,
            }
            localStorageSave(newState)
            return newState;
        }
        case RemoveItemFromCartAction: {
            const {recipeId} = action.payload;
            const recipes = state.recipesInCart.filter(x => x.id !== recipeId)
            const newState = {
                ...state,
                recipesInCart: recipes,
                sizeOfCart: recipes.length
            }
            localStorageSave(newState)
            return newState;
        }
        case UpdateCartData: {
            action.payload.ingredientItems = combineIngredientAndDetails(action.payload.ingredientItems, action.payload.ingredientDetails, action.global.conversionFactors);
            let basket = action.payload.basket;
            let baskets = [];
            const chunkSize = 30;
            for (let i = 0; i < basket.ingredients.length; i += chunkSize) {
                const chunk = basket.ingredients.slice(i, i + chunkSize);
                baskets.push(JSON.stringify({ingredients:chunk}))
            }
            const newState = {
                ...state,
                ingredientsInCart: action.payload.ingredientItems,
                baskets: baskets,
                unverifiedBasket: JSON.stringify(action.payload.unverifiedBasket),
            }
            localStorageSave(newState)
            return newState;
        }
        default:
            return state;
    }
}
