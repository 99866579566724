import {combineIngredientAndDetails} from "../../data/Ingredient/ingredientHelper";
import {_} from "ag-grid-community";

export const AddRecipeToRecipes = "ADDRECIPETORECIPE";
export const UpdatePageNum = "UPDATEPAGE";
export const ClearRecipes = "CLEARRECIPES";
export const EditRecipeInRecipes = "EDITRECIPEINRECIPE";
export const RemoveRecipeFromRecipes = "REMOVERECIPEFROMRECIPES";
export const UpdateSelectedRecipe = "UPDATEDSELECTEDRECIPE"
export const SetRecipeUpdating = "SELECTEDRECIPEUPDATING"
export const SearchForRecipe = "SEARCHFORRECIPE"
export const EditSelected = "EDITSELECTED"
export const EditIngredientOnSelected = "EDITINGREDIENTONSELECTED"
export const MoreRecipes = "MORERECIPES"
export const Reload = "RELOAD"

const initialState = {
    recipes: [],
    search: undefined,
    searchText: "",
    selectedRecipe: undefined,
    selectedRecipeUpdating: false,
    nextPageNumber: 0,
    moreRecipes: false,
};

export default function (state = initialState, action) {

    switch (action.type) {
        case UpdatePageNum: {
            return {
                ...state,
                nextPageNumber: action.payload
            };
        }
        case MoreRecipes: {
            return {
                ...state,
                moreRecipes: action.payload
            };
        }
        case AddRecipeToRecipes: {
            const recipe = action.payload;

            recipe.ingredients = combineIngredientAndDetails(recipe.ingredients, recipe.ingredientDetails, action.global.conversionFactors);
            if (action.global.user) {
                recipe.isEditable = action.global.user.sub === recipe.userId
            }
            let recipes = state.recipes
            if (!recipes.some(x => x.id === recipe.id)) {
                recipes = recipes.concat(recipe)
            }
            return {
                ...state,
                recipes: recipes
            };
        }
        case EditRecipeInRecipes: {
            const updatedRecipe = action.payload;
            const recipes = [...state.recipes]
            const index = recipes.findIndex((x) => x.id === updatedRecipe.id);
            const newRecipe = {...recipes[index], ...updatedRecipe}
            recipes[index] = newRecipe
            return {
                ...state,
                recipes: recipes,
            };
        }
        case SearchForRecipe: {
            if (state.search !== action.payload) {
                let value = null
                if (action.payload) {
                    value = action.payload.term
                    if (action.payload.type !== "ALL")
                        value = `${action.payload.type}:${action.payload.term}`
                }
                return {
                    ...state,
                    search: action.payload,
                    searchText: value,
                    recipes: []
                };
            }
            break;
        }
        case UpdateSelectedRecipe: {
            const recipe = action.payload;
            recipe.ingredients = combineIngredientAndDetails(recipe.ingredients, recipe.ingredientDetails, action.global.conversionFactors);
            if (action.global.user) {
                recipe.isEditable = action.global.user.sub === recipe.userId
            }
            return {
                ...state,
                selectedRecipe: recipe,
                selectedRecipeUpdating: false
            }
        }
        case SetRecipeUpdating: {
            return {
                ...state,
                selectedRecipeUpdating: true
            }
        }
        case RemoveRecipeFromRecipes: {
            return {
                ...state,
                recipes: state.recipes.filter(x => x.id !== action.payload.id),
            }
        }
        case ClearRecipes: {
            return {
                ...state,
                recipes: [],
            }
        }
        case EditSelected: {
            return {
                ...state,
                selectedRecipe: {...state.selectedRecipe, ...action.payload}
            }
        }
        case EditIngredientOnSelected: {
            const recipe = {...state.selectedRecipe}
            if (action.payload.delete) {
                recipe.ingredients = recipe.ingredients.filter(x => x.ingredientId !== action.payload.id && x.id !== action.payload.id);
            } else if (action.payload.add) {
                recipe.ingredients = recipe.ingredients.concat(action.payload)
            } else {
                const newIng = _.deepCloneObject(recipe.ingredients);
                let index = newIng.findIndex((x) => x.ingredientId === action.payload.id);
                newIng[index].qty = action.payload.qty;
                newIng[index].qtyType = action.payload.qtyType;
                recipe.ingredients = newIng
            }
            return {
                ...state,
                selectedRecipe: recipe
            }
        }

        default:
            return state;
    }
}
