import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import {Icon} from '@material-ui/core';
import {ShoppingBasketOutlined} from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';
import {Link} from 'react-router-dom'
import {useSelector} from "react-redux";

export default function ShoppingCart() {
const sizeOfCart = useSelector(state=>state.cart.sizeOfCart);
    return (
        <IconButton style={{padding: 4}} color="inherit" component={Link} to='/Basket' >
            <Badge color='error' badgeContent={sizeOfCart}>
                <Icon><ShoppingBasketOutlined color='secondary'/></Icon>
            </Badge>
        </IconButton>
    )
}
