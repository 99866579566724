import { get, put,post, deleteRequest } from '../../helper/restHelper';
import { debug } from '../../helper/logHelper';
import {jsonHeader} from '../constants'

let debugOn = false;
export async function pullUserRecipes(userId) {
    try {
        let param = {userId: userId};
        debug("Pulling Recipes",debugOn)
        debug(JSON.stringify(param),debugOn)
        let data = (await post('AdvRecipe',JSON.stringify(param), jsonHeader)).json()
        debug(data,debugOn)
        return(data)
    } catch (error) {
        console.log(error)
        return([])
    }
}

export async function searchRecipeData(searchTerm, searchBasis,id, sortBasis,pageNumber) {
    try {

        let param = {
                pageSize: 10,
                pageNumber: pageNumber,
                searchTerm: searchTerm,
                searchBasis: searchBasis,
                sortBasis: sortBasis,
                userId:id,
                sortAsc: true
            };

        debug("Pulling Recipes",debugOn)
        debug(param,debugOn)
        let data = (await post('RecipeSearch',JSON.stringify(param), jsonHeader)).json()
        debug(data,debugOn)
        return(data)
    } catch (error) {
        console.log(error)
        return([])
    }
}

export async function togglePubicPrivate(recipe) {
    try {
        debug("toggling public private for recipe: "+ recipe.id,debugOn)
        let response = await get('Recipe/'+recipe.id+'/Toggle');
        if(response.status!== 200){
            return false
        }
    } catch (error) {
        console.log(error)
        return([])
    }
}

export async function pullSingleRecipe(id) {
    try {
        debug("Pulling Recipe" + id,debugOn)
        let data = await get('Recipe?recipeID='+ id)
        debug(data,debugOn)
        if(data.status===200){
            return [true, await data.json()];
        }
        else {
            return [false,await data.json()]
        }
    } catch (error) {
        return([])
    }
}

export async function pullPublicRecipes() {
    try {
        debug("Pulling Recipes",debugOn)
        let data = (await get('Recipe?public=true')).json()
        debug(data,debugOn)
        return(data)
    } catch (error) {
        console.log(error)
        return([])
    }
}

export async function saveRecipe(recipe){
    debug("Updating recipe", debugOn)
    debug(recipe, debugOn)
    let response = await post('Recipe', JSON.stringify(recipe), jsonHeader);
    if(response.status !== 200){
        return {errorMessage:"Failed to save recipe, please try again!"}
    }
}

export async function addRecipe(recipe){
    debug("Updating recipe", debugOn)
    debug(recipe, debugOn)
    let response = await put('Recipe', JSON.stringify(recipe), jsonHeader);
    if(response.status === 200){
        let newRecipeId = response.json()
        return(newRecipeId)
    }
    else{
        return {errorMessage:"Failed to save recipe, please try again!"}
    }
}

export async function deleteRecipe(recipe) {
    try {
        debug("Deleting" + recipe.id ,debugOn)
        await deleteRequest(`Recipe?recipeID=` + recipe.id);
    } catch (error) {
        console.log(error)
    }
}

export async function pullLabels() {
    try {
        debug("Pulling Labels",debugOn)
        let data = (await get('Labels')).json()
        debug(data,debugOn)
        if(!data){
            return [];
        }
        return data
    } catch (error) {
        console.log(error)
        return([])
    }
}

export function emptyRecipe() {
    let newRecipe = {
        id: undefined,
        recipeName: "",
        portions: 1,
        timeInMinutes: 0,
        method: "",
        ingredients: [],
        labels: [],
        imageUrl: "",
        imageData:"",
        views:0,
        about:"",
        editMode: true,
        edit:true
    }
    return newRecipe
}