import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {MoreVert} from '@material-ui/icons';
import {IconButton, Tooltip} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    menuIcon: {
        marginTop:"auto",
        marginBottom:"auto",
        float: 'right'
    },
}));

export default function CardMenu({recipe, commands, withoutList}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmationDialog, setconfirmationDialog] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [action, setAction] = useState();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function confirm() {
        action.method(recipe)
        setconfirmationDialog(false)
        setConfirmationText("")
    }

    function handleMenuClick(menuItem) {
        handleClose();
        if (menuItem.conf) {
            setAction(menuItem);
            setConfirmationText(menuItem.conf);
            setconfirmationDialog(true);
        } else {
            menuItem.method(recipe)
        }
    }

    if (withoutList) {
        return (<div>
            <Dialog
                open={confirmationDialog}
                onClose={() => {
                    setconfirmationDialog(false)
                }}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{confirmationText}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => confirm()} color="primary">
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
            {commands.map((menuItem) =>
                <Tooltip key={menuItem.title} title={menuItem.title}>
                    <MenuItem onClick={() => handleMenuClick(menuItem)}>
                        {menuItem.icon}
                    </MenuItem>
                </Tooltip>
            )}
        </div>)
    }

    return (
        <div>
            <Dialog
                open={confirmationDialog}
                onClose={() => {
                    setconfirmationDialog(false)
                }}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{confirmationText}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => confirm()} color="primary">
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton className={classes.menuIcon} onClick={handleClick} aria-label="settings">
                <MoreVert/>

            </IconButton>
            <Menu
                variant={"selectedMenu"}
                MenuListProps={{
                    'dense': true
                }}
                id="Edit Menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {commands.map((menuItem) => <MenuItem key={menuItem.title} onClick={() => handleMenuClick(menuItem)}>
                    {menuItem.icon}{menuItem.title}
                </MenuItem>)}
            </Menu>
        </div>
    )
}
