import React, {useEffect, useState} from 'react'
import ShoppingCart from "../ShoppingCart/ShoppingCart";


import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import SearchIcon from '@material-ui/icons/Search'

import MToolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {Box, Button, Divider} from "@material-ui/core";
import SearchBar from "../SearchBar/SearchBar";
import {RECIPE, setGlobalRecipe, setGlobalUser, USER} from "../../../Store/reducers/globalReducer";
import {useDispatch, useSelector} from "react-redux";
import {AccountCircle, AddCircle, ArrowDropDown, ExitToApp, PersonAdd} from "@material-ui/icons";
import {useAuth0} from "@auth0/auth0-react";
import {clearAndNav, nav} from "../../../helper/navHelper";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {UpdateSelectedRecipe} from "../../../Store/reducers/recipeReducer";
import {emptyRecipe} from "../../../data/Recipe/recipeData";

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    background: {
        width: "100%",
        backgroundColor: '#f5f5f5'
    },
    flexGrow: {
        flexGrow: 1
    },
    alert: {
        zIndex: 9999,
        position: "relative"
    },
    buttons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        backgroundColor: '#f5f5f5'
    },
    button: {
        flexGrow: 1,
        borderRadius: 0
    }

}));


Alert.propTypes = {
    action: PropTypes.element,
    children: PropTypes.node
};


CloseIcon.propTypes = {fontSize: PropTypes.string};

export default function ToolbarSmall({
                                         homePage,
                                         homeLink,
                                         toggleSearch,
                                         searchOpen,
                                         alert,
                                         closeAlert
                                     }) {
    const classes = useStyles();
    const recipeUser = useSelector(state => state.global.recipeUser)
    let dispatcher = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [userCommands, setUserCommands] = useState([]);
    const [navCommands, setNavCommands] = useState([]);
    const {loginWithRedirect, logout, isAuthenticated} = useAuth0();


    useEffect(() => {
        let tempCommands = [];

        tempCommands.push({
            title: "My Profile",
            icon: <AccountCircle/>,
            method: () => clearAndNav(dispatcher, "/MyProfile")
        })
        tempCommands.push({
            title: "Logout",
            icon: <ExitToApp/>,
            method: () => logout({returnTo: window.location.origin})
        });

        setUserCommands(tempCommands)

        tempCommands = [];
        tempCommands.push({
            title: "Contact Us",
            method: () => clearAndNav(dispatcher, "/Contact")
        })
        tempCommands.push({
            title: "T's & C's",
            method: () => clearAndNav(dispatcher, "/Terms")
        });
        tempCommands.push({
            title: "Privacy Policy",
            method: () => clearAndNav(dispatcher, "/Privacy")
        });

        setNavCommands(tempCommands)


    }, [dispatcher, logout])

    function createRecipe() {
        dispatcher({type: UpdateSelectedRecipe, payload: emptyRecipe()})
        nav(dispatcher, "/Recipe")
    }

    function login() {
        loginWithRedirect().then((c) => console.log(c))
    }


    return (
        <div>
            <AppBar color="default" position="static" className={classes.appBar}>
                <MToolbar disableGutters={true}>
                    <Button onClick={homeLink}>
                        <Typography  style={{flex: 1, textDecoration: "None"}} color="textPrimary"
                                    variant="subtitle1" noWrap>
                            The Pantry Life
                        </Typography>
                    </Button>
                    <IconButton onClick={(e) => setAnchorEl2(e.currentTarget)} style={{padding: 0}} edge="start">
                        <ArrowDropDown color="primary" />
                    </IconButton>
                    <Menu
                        MenuListProps={{
                            'dense': true
                        }}
                        variant={"selectedMenu"}
                        id="PantryLife"
                        anchorEl={anchorEl2}
                        open={Boolean(anchorEl2)}
                        onClose={() => setAnchorEl2(null)}
                    >
                        {navCommands.map((menuItem) => <MenuItem key={menuItem.title} onClick={() => {
                            menuItem.method();
                            setAnchorEl2(null)
                        }}>
                            {menuItem.icon}{menuItem.title}
                        </MenuItem>)}
                    </Menu>
                    <div className={classes.flexGrow}/>
                    <IconButton style={{padding: 3}} onClick={toggleSearch}  color="inherit"
                                aria-label="menu">
                        <SearchIcon color="primary"/>
                    </IconButton>
                    {isAuthenticated &&
                        <IconButton style={{padding: 3}} color="primary" onClick={createRecipe}>
                            <AddCircle/>
                        </IconButton>
                    }
                    <ShoppingCart/>
                    {!isAuthenticated &&
                        <IconButton onClick={login}>
                            <PersonAdd/>
                        </IconButton>
                    }
                    {isAuthenticated &&


                        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                            <AccountCircle color="primary" />
                        </IconButton>

                    }
                    <Menu
                        variant={"selectedMenu"}
                        transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
                        id="Edit Menu"
                        MenuListProps={{
                            'dense': true
                        }}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        {userCommands.map((menuItem) => <MenuItem key={menuItem.title} onClick={() => {
                            menuItem.method();
                            setAnchorEl(null)
                        }}>
                            {menuItem.icon}{menuItem.title}
                        </MenuItem>)}
                    </Menu>

                </MToolbar>
            </AppBar>
            <Divider/>
            {homePage &&
                <Box className={classes.buttons}>
                    <Button className={classes.button}
                            style={recipeUser === RECIPE ? {backgroundColor: '#34A853'} : {backgroundColor: '#f5f5f5'}}
                            onClick={() => setGlobalRecipe(dispatcher)}>Recipes</Button>
                    <Button className={classes.button}
                            style={recipeUser === USER ? {backgroundColor: '#34A853'} : {backgroundColor: '#f5f5f5'}}
                            onClick={() => setGlobalUser(dispatcher)}>Users</Button>
                </Box>
            }
            {homePage && (<Collapse in={searchOpen}>
                <SearchBar/>
            </Collapse>)}
            <Collapse in={alert.open}>
                <Alert className={classes.alert} severity={alert.severity}
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={closeAlert}
                           >
                               <CloseIcon fontSize="inherit"/>
                           </IconButton>
                       }
                >
                    {alert.message}
                </Alert>
            </Collapse>
        </div>
    )
}


