import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import {Icon, Typography} from '@material-ui/core';
import {Visibility} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    card: {
        width: 500,
        margin: 30,
        borderRadius: 20,

    },
    CardContent: {
        display: 'flex',
        padding: '10%',
    },
    media: {
        display: 'Block',
        margin: 'auto',
        width: 200,
        height: 225,
        borderRadius: 20,
    },
    mediaDetails: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        display: 'flex',
        justifyContent: 'space-around',
        background: '#F0F0F0',
    },
    mediaDetail: {

        paddingTop:10,
        width:35,
        textAlign: 'center',
        flexDirection: 'column',
    },
    about: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 100,
        marginBottom: 10
    },
    left: {
        borderRadius: 20,
        flexBasis: '33.33%',
        display: 'flex',
    },
    right: {
        flex: 1,

        flexDirection: 'column',
        paddingLeft: 20,
        display: 'flex',

    },
    button: {
        pointerEvents: "none",
    }

}));


export default function UserCardBig({ user, recipeClick }) {

    const classes = useStyles();

    return (
        <Card variant="outlined" className={classes.card} onClick={recipeClick}>
            <CardContent className={classes.CardContent}>
                <div className={classes.left}>
                    <CardMedia
                        className={classes.media}
                        image={user.imageData}
                        title={user.username}
                    >
                        <div className={classes.mediaDetails}>
                            <div className={classes.mediaDetail}>
                                <Icon color="secondary">
                                    <Visibility />
                                </Icon>
                                <Typography>10</Typography>
                            </div>
                        </div>
                    </CardMedia>
                </div>
                <div className={classes.right}>
                    <Typography variant="h6" color="textPrimary" component="p">
                        {user.username}
                    </Typography>
                    <Typography className={classes.about} variant="body2" color="textSecondary" component="p">
                        {user.about}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
}
