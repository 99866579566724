import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        width: '50vw',
        marginLeft: "auto",
        marginRight: "auto",
        padding: "2vw",
        borderRadius: "20px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
export default function ContactUs() {
    const classes = useStyles();
    const [name, setName] = useState("")
    const [email, setemail] = useState("")
    const [message, setmessage] = useState("")

    function handleSubmit(e) {
        e.preventDefault()
        let mail = {
            "name": name,
            "mail": email,
            "message": message
        }
        console.log(mail)
    }

    return (
        <div className={classes.paper}>
            <form className={classes.form} >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    onChange={(x) => setName(x.target.value)}
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={(x) => setemail(x.target.value)}
                    name="name"
                    label="name"
                    id="name"
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="message"
                    onChange={(x) => setmessage(x.target.value)}
                    label="message"
                    multiline={true}
                    id="message"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSubmit}
                >Submit</Button>
            </form>
        </div>
    )
}