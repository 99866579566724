import React, {useCallback, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, IconButton, Slider, Typography} from '@material-ui/core';
import {Edit} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Cropper from "react-easy-crop";
import {getCroppedImg, getRotatedImage} from "../../../../helper/ImageHelper";
import {getOrientation} from "get-orientation";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles(theme => ({
        cropContainer: {
            position: 'relative',
            width: '100%',
            height: 200,
            background: '#333',

        },
        uploadImage: {
            position: "relative"
        },
        exitIcon:{
            position: "absolute",
            right: 0,
        }
    }
));
const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

export default function UploadImage({currentImage, saveImage}) {
    const classes = useStyles();
    const [editImage, setEditImage] = useState(false);
    const [imageSrc, setImageSrc] = React.useState(currentImage ? currentImage : process.env.PUBLIC_URL + "/notFoundImage.svg")
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])


    /*const onClose = useCallback(() => {
        setCroppedImage(null)
    }, [])*/

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            console.log(file)
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            setImageSrc(imageDataUrl)
        }
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    async function getNewImage() {
        console.log("Starting")
        const cropped = getCroppedImg(imageSrc, croppedAreaPixels, rotation);

        //console.log(reader.readAsDataURL(croppedArea))
        return cropped
    }

    const fileRef = useRef(null)
    return (
        <div>
            <IconButton onClick={() => setEditImage(true)} style={{
                backgroundColor: "rgba(220, 220, 220, 0.5)",
                color: "black",
                width: "100%",
                height: "100%",
                padding: 0,
                borderRadius: 0,
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px"
            }}>
                <Edit/>
            </IconButton>
            <Dialog
                className={classes.uploadImage}
                open={editImage}
                onClose={() => setEditImage(false)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle style={{width: "70vw"}}>
                    Upload + Edit Image
                </DialogTitle>
                <IconButton className={classes.exitIcon} onClick={() => setEditImage(false)}>
                    <CloseIcon/>
                </IconButton>

                <DialogContent>
                    <Box display={"flex"}
                         style={{
                             flexDirection: "column",
                             alignItems: "center"
                         }}>
                        <div className={classes.cropContainer}>
                            <Cropper
                                image={imageSrc}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                aspect={1 / 1}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </div>

                        <Button variant={"contained"} color={"secondary"}
                                style={{marginTop: "10px", marginBottom: "10px"}}
                                onClick={() => fileRef.current.click()}>Upload
                            Image</Button>
                        <input ref={fileRef} hidden={true} type="file" onChange={onFileChange} accept="image/*"/>
                        <Typography>Rotation</Typography>
                        <Slider value={rotation} max={360} onChange={(x, v) => setRotation(v)}/>
                        <Typography>Zoom</Typography>
                        <Slider value={zoom} onChange={(x, v) => setZoom(v)}/>
                        <Button style={{marginBottom: "10px"}} color={"secondary"} variant={"contained"}
                                onClick={() => getNewImage().then((x) => {
                                    saveImage(x);
                                    setEditImage(false)
                                })}> Save</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

