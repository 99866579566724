import React from 'react'

import {useDispatch, useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {AddShoppingCart} from "@material-ui/icons";
import {editIngredient} from "../../data/Recipe/RecipeHelper";
import {convertType} from "../../data/Ingredient/ingredientHelper";
import {amazonTag} from "../../data/constants";

const useStyles = makeStyles(theme => ({
    holder: {
        display: 'flex',
        flexDirection: 'row',
    },

    right: {
        height: 'fit-content',
        flex: 1,
        right: 0,
        flexDirection: 'row',
        marginRight: 0,
        paddingLeft: 20,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 'auto',
    },
    qty: {
        paddingRight: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    unverified: {
        color: "red"
    }

}));
export default function Ingredient({company, brandID, ingredient, multiFactor, addToCart, unverified}) {
    const classes = useStyles();
    const conversionFactors = useSelector(state => state.global.conversionFactors)
    const dispatcher = useDispatch()

    function update(qtyType) {
        editIngredient(dispatcher, {
            id: ingredient.ingredientId,
            qtyType: qtyType,
            qty: convertType(ingredient, qtyType, conversionFactors)
        })

    }

    function capitalize(str) {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function handleQty(qty) {
        //No DP if more than 10 whats the point?!
        if (qty > 10) {
            qty = Math.round((qty + Number.EPSILON))
        }
        //if less than 10 2dp
        else if (qty < 10) {
            qty = Math.round((qty + Number.EPSILON) * 100) / 100
        }
        return qty
    }
    function createAmazonURL()
    {
        if(company==="AMAZON"){
            return(`https://www.amazon.co.uk/dp/${ingredient.amazonBasicId}?almBrandId=${brandID}?tag=${amazonTag}`)
        }
        else if(company==="MORRISONS"){
            return(`https://www.amazon.co.uk/dp/${ingredient.morissonsBasicId}?almBrandId=${brandID}?tag=${amazonTag}`)
        }

    }

    return (
        <div className={`${classes.holder} ${unverified ? classes.unverified : ""}`}>
            <div className={classes.qty}>
                <Typography className={classes.left}
                            variant="body1">{capitalize(ingredient.name)}</Typography>
            </div>
            <div className={classes.right}>
                <div className={classes.qty}>
                    <Typography variant="body1">{handleQty(ingredient.qty * multiFactor)}</Typography>
                </div>
                {ingredient.validTypes && <Select
                    MenuProps={{
                        MenuListProps: {
                            'dense': true
                        }
                    }}
                    value={ingredient.qtyType}
                    onChange={(x) => update(x.target.value)}
                    inputProps={{'aria-label': 'Without label'}}
                >
                    {ingredient.validTypes.map(qtyType => (
                        <MenuItem key={qtyType.qtyType} value={qtyType.qtyType}>
                            {qtyType.qtyTypeNice}
                        </MenuItem>
                    ))}
                </Select>}
                {addToCart &&
                    <IconButton variant="contained" color="primary" target="_blank"
                                onClick={x => window.open(createAmazonURL(), 'newwindow', 'width=1000,height=1000')}
                    >
                        <AddShoppingCart/>
                    </IconButton>
                }
            </div>
        </div>
    )


}
