import React, {useEffect, useState} from 'react'
import PresentableRecipeBig from './SpecificDevice/PresentableRecipeBig'
import {Delete, Edit} from '@material-ui/icons';
import {deleteRecipe} from "../../../../data/Recipe/recipeData";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addAlert} from "../../../../Store/reducers/alertReducer";
import {addRecipeToCart, removeItemFromCart} from "../../../../Store/reducers/shoppingCartReducer";
import {deleteRecipeFromCache, editSelectedRecipe, prepareRecipeForEditing} from "../../../../data/Recipe/RecipeHelper";
import {_} from "ag-grid-community";
import PresentableRecipeSmall from "./SpecificDevice/PresentableRecipeSmall";
import {clearAndNav} from "../../../../helper/navHelper";

export default function PresentableRecipe() {
    const dispatcher = useDispatch()
    const recipe = useSelector(state => state.recipe.selectedRecipe)
    const {recipesInCart} = useSelector(state=> state.cart);
    const screen = useSelector(state=> state.global.mobileScreen);
    const user = useSelector(state=>state.global.user);
    const [commands, setCommands] = useState([] );
    const [inBasket,setInBasket] = useState()
    const [multiFactor, setMultiFactor] = useState(recipe.reqPortions / recipe.portions);
    const history = useHistory();

    useEffect(()=> {
        setMultiFactor(recipe.reqPortions / recipe.portions)
    },[recipe.reqPortions,recipe.portions])

    useEffect(() => {
        let commandsBuilder = []
            //if (recipe.isEditable) {
        if(user && user.sub ===recipe.userId){
                commandsBuilder.push({
                    title: "Edit",
                    icon: <Edit/>,
                    method: () => prepareRecipeForEditing(dispatcher, recipe)
                });
                commandsBuilder.push({
                    title: "Delete",
                    conf: "Are you sure you want to delete this recipe?",
                    icon: <Delete/>,
                    method: (x) => deleteRecipe(x).then(() => {
                        deleteRecipeFromCache(recipe.id, dispatcher)
                        history.push({pathname: '/MyProfile'});
                        addAlert(dispatcher, "Deleted Recipe", "info")
                    })
                });

            if (user) {
                //commandsBuilder.push({title: "Clone Recipe", icon: <FileCopy/>, method: () => console.log("copy")})
            }
        }
        setCommands(commandsBuilder)
    }, [recipe,user,dispatcher, history])

    useEffect(() => {
        setInBasket(recipesInCart.some(e=> e.id === recipe.id))
    }, [recipesInCart, recipe.id])

    function portionChange(value) {
            editSelectedRecipe(dispatcher, {reqPortions: value})
    }
    let debouncedPortionChange = _.debounce(portionChange, 500)

    function basketClick() {
        if (inBasket) {
            removeItemFromCart(dispatcher, recipe.id)
        } else {
            addRecipeToCart(dispatcher, recipe.id, recipe.reqPortions)
        }
    }

    function userClick(id) {
        const params = {id:id}
        clearAndNav(dispatcher, "/User", null, params)
    }



    function chipClick(label) {
        clearAndNav(dispatcher, "/", {},{type:"LABEL", term:label} )
    }

    return (
        <div>
            {screen && (
                <PresentableRecipeBig userClick={userClick} chipClick={chipClick} recipe={recipe} commands={commands} basketClick={basketClick} inBasket={inBasket}
                                      multiFactor={multiFactor} portionChange={debouncedPortionChange}/>)}
            {!screen && (<PresentableRecipeSmall userClick={userClick} chipClick={chipClick} recipe={recipe} commands={commands} basketClick={basketClick} inBasket={inBasket}
                                               multiFactor={multiFactor} portionChange={debouncedPortionChange}/>)}


        </div>
    )
}
