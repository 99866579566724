import {baseURL} from '../data/constants';
import {debug} from '../helper/logHelper';

let debugOn = false;
let accessToken;
let isAuthenticated = false

export function setAccessToken(toset) {
    console.trace(toset)
    accessToken = toset;
}
export function setAuth(isAuth) {
    console.trace(isAuth)
    isAuthenticated = isAuth;
}

export async function getProps(method, body, additionalHeaders) {
    let props = {};
    let headers = {};
    let access = "";
    if (isAuthenticated) {
        try {
            access = await accessToken({timeoutInSeconds: 1});

        } catch (err) {
            try {
                access = await accessToken({timeoutInSeconds: 1, ignoreCache: true});
            } catch (err) {
                console.log("failed Again")
            }

        }
        if (access) {
            headers = Object.assign(headers, {Authorization: `Bearer ` + access,});
        }
    }

    if (additionalHeaders) {
        headers = Object.assign(headers, additionalHeaders);
    }
    props.headers = headers


    if (method) {
        props.method = method
    }
    if (body) {
        props.body = body;
    }
    debug(props, debugOn)
    return props
}

export async function get(url) {
    let data = await fetch(baseURL + url,
        await getProps()
    )
    debug(data, debugOn)

    return data;
}

export async function put(url, body, headers) {
    let data = await fetch(baseURL + url,
        await getProps('PUT', body, headers)
    )
    debug(data, debugOn)
    return data;
}

export async function post(url, body, headers) {
    let data = await fetch(baseURL + url,
        await getProps('POST', body, headers)
    )
    debug(data, debugOn)
    return data;
}

export async function deleteRequest(url, body, headers) {
    let data = await fetch(baseURL + url,
        await getProps('DELETE', body, headers)
    )
    debug(data)
    return data;
}
