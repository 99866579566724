import { get, put,post } from '../../helper/restHelper';
import { debug } from '../../helper/logHelper';
import {jsonHeader} from '../constants'

let debugOn = false;

export async function togglePubicPrivate() {
    try {
        debug("toggling public private",debugOn)
        await get('User/Toggle')
    } catch (error) {
        return([])
    }
}

export async function pullLoggedInUser() {
    try {
        debug("Pulling Users",debugOn)
        let data
        data = (await get('LoggedInUser')).json()
        debug(data,debugOn)
        return(data)
    } catch (error) {
        console.log(error)
        return([])
    }
}

export async function pullUsers(id) {
    try {
        debug("Pulling Users",debugOn)
        let data
        if(id){
            let param = {id: id};
            debug(param,debugOn)
            data = (await post('AdvUser',JSON.stringify(param), jsonHeader)).json()
        }
        else{
            data = (await get('User')).json()
        }
        debug(data,debugOn)
        return(data)
    } catch (error) {
        console.log(error)
        return([])
    }
}

export async function getLoggedInUser(){
    debug("Getting Logged In User", debugOn)
    let data = (await get('LoggedInUser')).json()
    debug(data, debugOn);
    return(data)
}

export async function addUser(user){
    debug("Updating user", debugOn)
    debug(user, debugOn)
    let data = (await post('User', JSON.stringify(user), jsonHeader)).text()
    debug(data, debugOn);
    return(data)
}

export async function updateUser(user){
    console.log(user)
    if(!user.id){
        addUser(user)
    }
    debug("Updating User", debugOn)
    debug(user, debugOn)
    user.version = user.version +1
    let data = (await put('User', JSON.stringify(user), jsonHeader)).text()
    debug(data, debugOn);
    return(data)
}

export function emptyRecipe() {
    let newUser = [{
        userName: "",
        about: "",
        version: 0,
    }]
    return newUser
}