export const AddUserToUsers = "ADDUSERTOUSER";
export const RemoveUserFromUsers = "REMOVEUSERFROMUSER";
export const UpdateSelectedUser = "UPDATESELECTEDUSER"
export const SearchUsers = "SEARCHUSER"
export const UserUpdating = "SETUSERUPDATING"
export const EditSelectedUser = "EDITSELECTEDUSER"


const initialState = {
    users: [],
    search: "",
    inFocusUsers: [],
    selectedUser:undefined,
    selectedUserUpdating: false
};

export function filterUser(user, search){
    if(search){
        search = search.toLowerCase()
        return  user.username.toLowerCase().includes(search)
    }
    else
        return true

}

export default function (state = initialState, action) {
    switch (action.type) {
        case AddUserToUsers: {
            const user = action.payload;
            let users = state.users
            if(!users.some( x=> x.id === user.id)){
                users= users.concat(user)
            }
            let inFocusUsers = state.inFocusUsers
            if(filterUser(user, state.search)&&!inFocusUsers.some( x=> x.id === user.id)){
                inFocusUsers = inFocusUsers.concat(user)
            }
            return {
                ...state,
                users: users,
                inFocusUsers: inFocusUsers
            };
        }
        case SearchUsers: {
            return{
                ...state,
                search: action.payload,
                inFocusUsers: state.users.filter((user)=> user.username.toLowerCase().includes(action.payload))
            };
        }
        case UpdateSelectedUser:{
            return {
                ...state,
                selectedUser: action.payload,
                selectedUserUpdating:false
            }
        }
        case UserUpdating:{
            return {
                ...state,
                selectedUserUpdating:true
            }
        }
        case RemoveUserFromUsers:{
            return {
                ...state,
                users: state.users.filter(x=> x.id!==action.payload.id),
                inFocusUsers: state.inFocusUsers.filter(x=>x.id!==action.payload.id)
            }
        }
        case EditSelectedUser:{
            return {
                ...state,
                selectedUser: {...state.selectedUser, ...action.payload}
            }
        }
        default:
            return state;
    }
}
