import React from 'react'
import PrivacyBig from './SpecificDevice/Big/Privacy'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    privacyParent:{
        flexGrow: 1,
        overflowY:'auto',
        paddingBottom:'20px'
    }

}));

export default function Privacy() {
    const classes = useStyles()
    return (
        <div className={classes.privacyParent}>
            <PrivacyBig/>
        </div>
    )
}