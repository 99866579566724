import React, {useEffect, useState} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useAuth0} from "@auth0/auth0-react";
import {Route, useHistory, useLocation} from 'react-router-dom'
import {getRoutingPages} from './NewComponents/RootPages/RootPages';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Toolbar from './NewComponents/Toolbar/Toolbar'
import backgroundImage from './NewComponents/RootPages/back.png'
import {setAccessToken, setAuth} from './helper/restHelper';
import Footer from './NewComponents/Footer/Footer';
import {useDispatch, useSelector} from "react-redux";
import {asyncInitGlobalState, setUser} from "./Store/reducers/globalReducer";
import {nav, screenAdjuster} from "./helper/navHelper";
import queryString from "query-string";

const theme = createMuiTheme({
  palette: {
    default: {
      main: "#FFFFFF",
    },
    primary: {
      main: "#000000"
    },
    secondary: {
      main: "#34A853"
    },
    error: {
      main: "#FCAC1E"
    }
  },

});
const useStyles = makeStyles(theme => ({
  root: {
    blah:theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: 1,
    width: "100%",
    height: "98vh",
    backgroundImage: `url(${backgroundImage})`,
    boxSizing: 'border-box',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    backgroundPosition: 'top right'
  },
  footer:{
    bottom:0
  },
  app:{
    overflow: "hidden"
  }

}));

function App() {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [userLoading, setUserLoading] = useState(true)
  const [routes, setRoutes] = useState(getRoutingPages(false));
  const [GSL, setGSL] = useState(true)
  const classes = useStyles();
  const dispatcher = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const page = useSelector(state => state.global.page)

  useEffect(()=>
  {
    if(page) {
      history.push({
        pathname: page.path,
        search: page.search,
        state: page.state
      })
    }
  },[page, history])

  useEffect(()=>{
    setAccessToken(getAccessTokenSilently);
    setAuth(isAuthenticated)
    setRoutes(getRoutingPages(isAuthenticated));
    if(!isLoading){
      setUser(user, dispatcher).then(()=>setUserLoading(false))
    }

  },[user, isLoading,dispatcher, isAuthenticated, getAccessTokenSilently])

  useEffect(()=>
  {
    setAccessToken(getAccessTokenSilently);
    asyncInitGlobalState(dispatcher).then(()=>setGSL(false))

    const params = queryString.parse(location.search);
    const path = location.pathname
    nav(dispatcher, path, null, params)

    screenAdjuster(dispatcher, window.matchMedia("(min-width: 768px)").matches)
    const x = () => {
       clearTimeout()
       setTimeout(()=>screenAdjuster(dispatcher, window.matchMedia("(min-width: 768px)").matches),500)
      }
    window.addEventListener("resize", x)
    return ()=> window.removeEventListener("resize", x)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (isLoading||GSL||userLoading) return (
    <Backdrop open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <Toolbar />
            {routes.map((route) => <Route key={route.title} exact={route.exact} path={route.link} component={route.component} />)}
          <div style={{flexGrow:1}}/>
          <Footer className={classes.footer}/>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
