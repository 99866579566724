import React, {useEffect, useState} from 'react'

import PresentableRecipe from '../../Recipes/RecipeCard/PresentableRecipeCard';
import {makeStyles} from '@material-ui/core/styles';

import {Button, Divider, Icon, IconButton, Tooltip, Typography} from '@material-ui/core';
import {ArrowBack, ArrowForward, Help} from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import {amazonTag} from "../../../data/constants";
import Ingredient from "../Ingredient";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";
import {MenuItem} from "material-ui";

const useStyles = makeStyles(theme => ({
    parent: {
        overflowY: "auto",
        position: "sticky",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-Around",
        flexGrow: 1
    },
    miniParent: {
        position: "sticky",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-Around",
    },
    container: {
        paddingTop: "30px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        marginRight: "auto",
        marginLeft: "auto",
        scrollBehavior: "smooth",
        display: "flex",
        maxWidth: "100vw",
        alignContent: "center",
    },
    card: {
        position: "relative",
        display: "flex",
        /*   margin-left: -50px; */
        transition: "0.4s ease-out",
        left: "0px",
        "&:not(:first-child)": {
            marginLeft: "-50px"
        },
        "&:hover": {
            transform: "translateY(-20px)",
        },
        "&:hover ~ &": {
            position: "relative",
            transform: "translateX(75px)",
        },
        "&:hover .close": {
            display: "flex",
        }
    },
    clickLeft: {
        marginTop: "auto",
        marginBottom: "auto",
        top: 0,
        left: "10px",
        bottom: 0,
        width: "30px",
        height: "30px",
        position: "absolute",
        backgroundColor: "black",
        zIndex: "99999",
        borderRadius: '30px',
        opacity: 0.7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',

        color: "white"

    },
    clickRight: {
        marginTop: "auto",
        marginBottom: "auto",
        top: 0,
        bottom: 0,
        right: "10px",
        width: "30px",
        height: "30px",
        position: "absolute",
        backgroundColor: "black",
        zIndex: "99999",
        borderRadius: '30px',
        opacity: 0.7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        color: "white"
    },
    ingredients: {
        width: "50vw",
    },
    overflow: {
        marginLeft: "10px",
        marginRight: "10px",
        overflowY: "scroll",
    },
    ingredientCard: {
        display:"flex",
        flexDirection:"column",
        marginBottom: "20px",
        marginTop: "20px",
        marginLeft: "auto",
        borderRadius: "10px",
        marginRight: "auto",
        width: "80vw",
        flexGrow: 1,
        padding: "20px",
    },
    addButtons: {
        height: "4em",
        justifyContent: "space-around",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        padding: "10px",
    },
    form: {
        flex: "1",
        display: "flex",
        justifyContent: "center",
    },
    button: {
        height: "100%",
        width: "40%",
    },
    ingredientList: {
        paddingBottom:"30px",
        height: "100%",
        overflowY: "scroll",
        overflowX:"hidden"

    },
    unverifiedIngredients: {
        paddingLeft: "20px",
        paddingRight: "20px",
        fontStyle: "italic"
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },
    paper1: {
        marginTop: theme.spacing(8),
        display: 'flex',
        minWidth: "200px",
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        width: '50vw',
        marginLeft: "auto",
        marginRight: "auto",
        padding: "20px",
        borderRadius: "20px",
    },

}));


export default function CheckoutFSBig({ingredientList, recipes, baskets, unverifiedBasket, setCompany, company, brandID}) {
    const classes = useStyles();
    const [showNav, setShowNav] = useState(window.innerWidth / 60 < recipes.length);
    const [unverifiedIngredient, setUnverified] = useState(ingredientList.filter(x => !x.verfiedID).length !== 0)
    const [unverifiedOpen, setUnverifiedOpen] = useState(false);
    useEffect(() => {
            const x = () => setShowNav(window.innerWidth / 60 < recipes.length)
            window.addEventListener("resize", x)
            return () => window.removeEventListener("resize", x)
        }, [recipes]
    )

    useEffect(() => {
            setShowNav(window.innerWidth / 60 < recipes.length)
        }, [recipes]
    )

    useEffect(() => {
            setUnverified(ingredientList.filter(x => !x.verfiedID).length !== 0)
        }, [ingredientList]
    )
    if (recipes.length <= 0) {
        return (
            <div className={classes.paper1}>
                <Typography align={"center"}>No Items are in your basket!</Typography>
            </div>
        )
    }
console.log(baskets)
    //return (
    return (
        <div className={classes.parent}>
            <div className={classes.miniParent}>
                {showNav && (<div>
                    <Icon className={classes.clickLeft}
                          onClick={() => document.getElementById('myRecipeContainer').scrollLeft += -300}>
                        <ArrowBack/>
                    </Icon>
                    <Icon className={classes.clickRight}
                          onClick={() => document.getElementById('myRecipeContainer').scrollLeft += 300}>
                        <ArrowForward/>
                    </Icon>
                </div>)}
                <div className={`${classes.container} ${showNav && classes.overflow}`} id={"myRecipeContainer"}>
                    {recipes.map((recipe) => {
                        return (
                            <div className={classes.card}>
                                <PresentableRecipe size={100} checkout={true} key={recipe.id} recipe={recipe}/>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Card className={classes.ingredientCard}>
                <Select
                    labelId="Store-label"
                    id="Store"
                    value={company}
                    label="Store"
                    onChange={(e)=>setCompany(e.target.value)}
                >
                    <MenuItem value={"AMAZON"}>Amazon Fresh</MenuItem>
                    <MenuItem value={"MORRISONS"}>Amazon Morisons</MenuItem>
                </Select>
                {baskets.length>1 && <Tooltip enterTouchDelay={0} title={
                    <React.Fragment>
                        <Typography variant={"subtitle2"} className={classes.subTitle}>
                           You have a lot of ingredients so we need to add in stages!
                            Please go through each Button Below!
                        </Typography>
                    </React.Fragment>
                }>
                    <IconButton>
                        <Help/>
                    </IconButton>
                </Tooltip>}

                <div className={classes.addButtons}>
                    {baskets && baskets.map((basket, idx)=>
                    <form style={unverifiedIngredient?{}:{width: "100%", height: "100%"}} className={unverifiedIngredient?classes.button:""} target="_blank" method="POST"
                          action={`https://www.amazon.co.uk/afx/ingredients/landing?almBrandId=${brandID}`}>
                        <input type="hidden" id="afx-ingredients-verified-hidden-input" name="ingredients" value={basket}/>
                        <input type="hidden" id="afx-ingredients-verified-hidden-input-associateTag" name="associateTag" value={amazonTag}/>
                        <Button style={{width: "100%", height: "100%"}} variant="contained" color="secondary"
                                type="submit">
                            Checkout {idx>0 ?idx:""}
                        </Button>
                    </form>
                    )}
                    {unverifiedIngredient &&
                        (
                            <Button className={classes.button} onClick={() => setUnverifiedOpen(true)}
                                    variant="contained" color="secondary" type="submit">
                                Unavailable Ingredients
                            </Button>
                        )}
                </div>

                <div className={classes.ingredientList}>
                    {ingredientList.filter(x => x.verfiedID).map(x => (
                        <div className={classes.paper}><Ingredient company={company} brandID={brandID} key={x.id} ingredient={x} multiFactor={1}
                                                                   addToCart={true}
                                                                   unverified={!x.verfiedID}/><Divider/></div>))}
                </div>
                <Dialog
                    open={unverifiedOpen}
                    className={classes.ingEdit}
                    onClose={() => setUnverifiedOpen(false)}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">Unavailable Ingredients
                        <Tooltip enterTouchDelay={0} title={
                            <React.Fragment>
                                <Typography variant={"subtitle2"} className={classes.subTitle}>
                                    Unfortunatly some of your items are not available from amazon!
                                    <br/>
                                    Please try searching for these manually on amazon or take this list and buy elsewhere!
                                    <br/>
                                    Don't worry we know this sucks, soon we will let you add suggested alternatives
                                </Typography>
                            </React.Fragment>
                        }>
                            <IconButton>
                                <Help/>
                            </IconButton>
                        </Tooltip>
                    </DialogTitle>
                    <DialogContent >
                    <div className={classes.ingredientList}>
                        {ingredientList.filter(x => !x.verfiedID).map(x => (
                            <div className={classes.paper}><Ingredient key={x.id} ingredient={x} multiFactor={1}
                                                                       addToCart={false}
                                                                       /><Divider/>
                            </div>))}
                    </div>
                    </DialogContent>

                </Dialog>

            </Card>
        </div>
    )
}
