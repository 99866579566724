import React, {useEffect, useReducer, useState} from 'react'
import {addIngredient, searchIngredients, validateIngredients} from '../../../../data/Ingredient/ingredientData';
import {pullImage, saveImage} from '../../../../data/photo/photoData';
import {LibraryAdd} from '@material-ui/icons';
import EditableRecipeBig from './SpecificDevice/EditableRecipeBig'
import {addRecipe, saveRecipe} from '../../../../data/Recipe/recipeData';
import queryString from "query-string";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addAlert} from "../../../../Store/reducers/alertReducer";
import {editIngredient, editSelectedRecipe, selectRecipe, validateRecipe} from "../../../../data/Recipe/RecipeHelper";
import {v4 as uuidv4} from 'uuid';
import EditableRecipeSmall from "./SpecificDevice/EditableRecipeSmall";
import IngredientVerifier from "./SpecificDevice/IngredientVerifier";


export default function EditableRecipe() {
    const recipe = useSelector(state => state.recipe.selectedRecipe)
    const dispatcher = useDispatch()
    const [commands, setCommands] = useState([]);
    const [ingredientVerifierOpen, setIngredientVerifierOpen] = useState(false);
    const [bulkIng, setbulkIngredient] = useState("")
    const [editingIngredient, setIngredient] = useState({name: "", potentialIngredients: [], qtyType: "", qty: 0})
    const [ingredientOptions, setOptions] = useState(null)
    const [lodingOptions, setLoadingOptions] = useState(false)
    const [imageLoading, setImageLoading] = useState(false)
    const [selectedIngredient, setSelected] = useReducer((i, x) => (x ? {label: x.name, value: x} : null), null,)
    const screen = useSelector(state => state.global.mobileScreen);
    const history = useHistory();

    function setbulkIng(x) {
        setbulkIngredient(x ? x.target.value : "");
    }

    useEffect(() => {
        let potentialIngredients = editingIngredient.potentialIngredients;
        if (potentialIngredients === null) {
            setOptions(null)
            setSelected(null)
        } else {
            let options = potentialIngredients.map((ing) => ({label: ing.name, value: ing}));
            setOptions(options)
            setSelected(potentialIngredients[0])
        }
    }, [editingIngredient])

    useEffect(() => {
        let tempCommands = []
        if (recipe.id) {
            tempCommands.push({
                title: "Save", icon: <LibraryAdd/>, method: () => {
                    let validated = validateRecipe(recipe, dispatcher);
                    if (validated) {
                        saveRecipe(recipe).then((x) => {
                            if (x && x.errorMessage) {
                                addAlert(dispatcher, x.errorMessage, "error")
                            } else {
                                editSelectedRecipe(dispatcher, {editMode: false})
                                selectRecipe(recipe.id, recipe.portions, dispatcher)
                                addAlert(dispatcher, "Saved Recipe", "info")
                            }
                        })
                    }
                }

            });
        } else {
            tempCommands.push({
                title: "Save", icon: <LibraryAdd/>, method: () => {

                    let validated = validateRecipe(recipe, dispatcher);
                    if (validated) {
                        addRecipe(recipe).then((x) => {
                            if (x && x.errorMessage) {
                                addAlert(dispatcher, x.errorMessage, "error")
                            } else {
                                editSelectedRecipe(dispatcher, {editMode: false})
                                history.push({
                                    pathname: '/Recipe',
                                    search: queryString.stringify({id: x.id, portions: x.portions}),
                                    state: {recipe}
                                })
                                selectRecipe(x.id, x.portions, dispatcher)
                                addAlert(dispatcher, "Added Recipe", "info")
                            }
                        })
                    }
                }
            });
        }
        setCommands(tempCommands)
    }, [recipe, dispatcher, history])

    function addIngredients() {
        if (!bulkIng) {
            addAlert(dispatcher, "Please enter some ingredients before trying to add", "error")
            return
        }
        validateIngredients(bulkIng).then((data) => {
            let unparsable = data.unparsableLines.join('\n')

            setbulkIngredient(unparsable)
            if (unparsable) {
                addAlert(dispatcher, "We were unable to understand some of your ingredients! Make sure you have added the qty. If you are still struggling, try adding them manually. Or take a look at the FAQ!")
            }
            Object.keys(data.potentialItems).forEach(x => {
                var uuid = uuidv4()
                let potentialItem = data.potentialItems[x];
                let ingredient = {
                    potentialIngredients: potentialItem.potentialIngredients,
                    name: x,
                    ingredientName: x,
                    qty: potentialItem.qty,
                    qtyType: potentialItem.qtyType,
                    id: uuid,
                    ingredientId: uuid,
                    add: true,
                    needsValidating: true
                }
                updateRecipe(ingredient, true)
            })
        })
    }

    function addIngredientToRecipe() {
        if (!selectedIngredient) {
            addAlert(dispatcher, "Please select an ingredient before confirming", "error")
            return
        }
        if (!editingIngredient.qty || editingIngredient.qty === 0) {
            addAlert(dispatcher, "Please enter a valid Qty", "error")
            return
        }
        if (!editingIngredient.qtyType) {
            addAlert(dispatcher, "Please enter a valid Qty Type", "error")
            return
        }
        //firstDelete current item
        let ing = {
            qty: editingIngredient.qty,
            qtyType: editingIngredient.qtyType,
            initId: editingIngredient.id,
            ingredientName: selectedIngredient.value.name,
            ingredientId: selectedIngredient.value.id,
            name: selectedIngredient.value.name,
            id: selectedIngredient.value.id
        }
        console.log(editingIngredient)
        console.log(selectedIngredient)
        if (editingIngredient.needsValidating|| editingIngredient.initId!==editingIngredient.id) {
            updateRecipe({delete: true, id: editingIngredient.id}, true);
            ing.add = true;
        }
        if (editingIngredient.single) {
            ing.add = true;
        }
        updateRecipe(ing, true)


        closeDialog()
    }

    function uploadImage(e) {
        setImageLoading(true);
        saveImage(e)
            .then(res => {
                pullImage(res).then((x) => {
                    editSelectedRecipe(dispatcher,
                        {
                            imageData: "data:image/png;base64," + x,
                            imageId: res
                        })
                    setImageLoading(false);
                })
            })
    }

    function updateRecipe(value, ingredient) {
        if (!ingredient) {
            editSelectedRecipe(dispatcher, value)
        } else {
            console.log(value)
            editIngredient(dispatcher, value)
        }
    }

    function createNewIngredient(e) {
        addIngredient({name: e}).then(() => searchIngredients(e).then((x) => {
            setIngredient({...editingIngredient, potentialIngredients: x});
            setSelected(x[0]);
        }))
    }

    function addEmptyIngredient() {
        setIngredient({name: "Single Ingredient Add", potentialIngredients: [], qtyType: "ABSOLOUTE", qty: 0, single: true});
        setIngredientVerifierOpen(true)

    }

    function searchAndLoad(e) {
        setLoadingOptions(true);
        if (e.length > 2) searchIngredients(e).then((x) => {
            if (x.length > 0) {
                setIngredient({...editingIngredient, potentialIngredients: x});
                setSelected(x[0])
            }
        })
        setTimeout(() => setLoadingOptions(false), 1000)
    }

    function setAndOpenVerifier(x) {
        setIngredient(x)
        openDialog()
    }


    function setQty(x) {
        setIngredient({...editingIngredient, qty: x.target.value})
    }

    function setQtyType(x) {
        setIngredient({...editingIngredient, qtyType: x.target.value});
    }

    function closeDialog() {
        setIngredientVerifierOpen(false);
    }

    function openDialog() {
        setIngredientVerifierOpen(true);
    }
    console.log(recipe)
    return (
        <div>


            {screen && (
                <EditableRecipeBig imageLoading={imageLoading}
                                   recipe={recipe} updateRecipe={updateRecipe}
                                   uploadImage={uploadImage} menuCommands={commands}
                                   addIngredients={addIngredients} bulkIng={bulkIng} setBulk={setbulkIng}
                                   addSingleIngredient={addEmptyIngredient}
                                   setEditingIngredient={setAndOpenVerifier}/>)}
            {!screen && (<EditableRecipeSmall imageLoading={imageLoading} recipe={recipe} updateRecipe={updateRecipe}
                                              uploadImage={uploadImage} menuCommands={commands}
                                              addIngredients={addIngredients} bulkIng={bulkIng} setBulk={setbulkIng}
                                              addSingleIngredient={addEmptyIngredient}
                                              setEditingIngredient={setAndOpenVerifier}/>)}

            <IngredientVerifier open={ingredientVerifierOpen} editingIngredient={editingIngredient}
                                selectedIngredient={selectedIngredient}
                                ingredientOptions={ingredientOptions} loading={lodingOptions}
                                addIngredientToRecipe={addIngredientToRecipe}
                                closeDialog={closeDialog} createNewIngredient={createNewIngredient}
                                setSelectedIngredient={setSelected} setQty={setQty} setQtyType={setQtyType}
                                searchIngredients={searchAndLoad}/>
        </div>
    )
}
