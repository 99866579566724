import {get, put} from '../../helper/restHelper';
import {debug} from '../../helper/logHelper';
import imageCompression from 'browser-image-compression';

let debugOn = false;

export async function pullImage(id) {
    if (!id) {
        id = "5f68995398861c2ba84ba876";
    }
    try {
        debug("Pulling image" + id,debugOn)
        let data = (await get('Photo?id=' + id))
            if(data.status===200){
                let image = data.text();
                debug(image,debugOn)
                return image
            }
            else return ""
    } catch (error) {
        console.log(error)
    }
}

export async function saveImage(file) {
    console.log("converting to file")
    const options = {
        maxSizeMB: 0.2,
        useWebWorker: true,
        maxWidthOrHeight:800,
    }
    debug("Saving Image" + file,debugOn)
    file = await imageCompression.getFilefromDataUrl(file,"test")
    console.log("converted to file")
    console.log("compressing")
    const compressedFile = await imageCompression(file, options);
    console.log("compressed")
    let formData = new FormData();
    formData.append('image', compressedFile);
    try {
        console.log("sending to server")
        let data = (await put('Photo', formData)).text()
        console.log("sent to server")
        debug(data,debugOn)
        return(data)
    } catch (error) {
        console.log(error)
    }
}