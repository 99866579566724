import {makeStyles} from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import {Button, CardHeader, Typography} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    card: {
        display: "flex",
        flexDirection: "column",
        maxWidth:"350px",
        width: "90vw",
        //height: "70vh",
        maxHeight: "700px",
        marginTop: "2vh",
        marginBottom: "5vh",
        borderRadius: 20,
    },

    CardContent: {
        display: 'flex',
        height: "68vh",
        //maxHeight: "680px",
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '5vw',
        paddingRight: '5vw'
    },
    media: {
        maxWidth:"289px",
        maxHeight:"280px",
        flex: "1 1 auto",
        display: 'Block',
        marginLeft: "auto",
        marginRight: "auto",
        width: "80vw",
        height: "80vw",
        borderRadius: 20,
        paddingTop: "2vh",
        paddingBottom: "2vh"
    },
    Content: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
    },
    button: {
        width: "100%",
        height: "4.4em",
        display: "block"
    },
    buttons: {
        margin: "auto",
        paddingTop: "2vh",
        display: "flex",
        paddingBottom: "2vh"
    },
    about:{
        height: "3em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        '-webkitLineClamp': 2,
        '-webkitBoxOrient': "vertical"
    },
    title: {
        alignSelf:"stretch",
        textTransform: "uppercase",
        height: "4.2em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkitLineClamp": 2,
        "-webkitBoxOrient": "vertical"
    }

}));

export default function UserCardSmall({user, recipeClick}) {
    const classes = useStyles();
    return (
        <Card variant="outlined" className={classes.card}>
            <CardHeader
                style={{maxWidth:"300px"}}
                titleTypographyProps={{variant: "subtitle1", className: classes.title}}
                title={user.username?user.username:user.id}
            />
            <CardMedia
                className={classes.media}
                image={user.imageData ? user.imageData : process.env.PUBLIC_URL+"/notFoundImage.svg"}
                title={user.username}
            />
            <CardContent>
                <Typography className={classes.about} variant="body2" color="textSecondary" component="p">
                    {user.about}
                </Typography>
                <div className={classes.buttons}>
                    <Button className={classes.button} variant="contained" color="secondary" onClick={recipeClick}>View
                        Profile</Button>
                </div>
            </CardContent>
        </Card>
    )
}