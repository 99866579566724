import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {Edit, ScreenShare, StopScreenShare} from '@material-ui/icons';
import {togglePubicPrivate} from '../../../data/User/userData';
import {useDispatch, useSelector} from "react-redux";
import {EditSelectedUser} from "../../../Store/reducers/userReducer";
import UserFSBig from "./SpecificDevices/UserFsBig";
import UserFSSmall from "./SpecificDevices/UserFsSmall";
import RecipeListAndSearch from "../../Recipes/RecipeListAndSearch";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    test: {
        blah: theme.spacing(1),
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden"
    },
    fill:{
        flexGrow: 1,
        backgroundColor:'Black'
    },
    big:{
        height:"10000px",
        overflowY:"auto"
    },
    child: {
        flexGrow: 1,
        overflowY: 'auto'
    },
}))
export default function UserFS() {
    const classes = useStyles();
    const history = useHistory();
    const [commands, setCommands] = useState([]);
    const loggedInUser = useSelector(state=> state.global.user);
    const dispatcher = useDispatch();
    const user = useSelector(state=>state.user.selectedUser);
    const screen = useSelector(state=> state.global.mobileScreen);


    useEffect(() => {
        if(true){
        //if (loggedInUser && (!user.id || loggedInUser.sub === user.id)) {
            let commands = [{ title: "Edit Profile", icon: < Edit />, method: ()=>dispatcher({type: EditSelectedUser, payload:{editMode:true}}) }];
            if (user.publicUser)
                (
                    commands.push({ title: "Make Profile Private", conf: "Are you sure you want to make your profile private? all Recipes will also become private", icon: <StopScreenShare />, method: togglePubicPrivate })
                )
            else {
                commands.push({ title: "Make Profile Public", conf: "Are you sure you want to make your profile public?", icon: <ScreenShare />, method: togglePubicPrivate })
            }
            setCommands(commands)
        }
    }, [loggedInUser,dispatcher, history, user])

    return (
        <div className={classes.child} id="Scroller">
            {screen && (
                <UserFSBig user={user} commands={commands}/>)}
            {!screen && (<UserFSSmall user={user} commands={commands}/>)}
                <RecipeListAndSearch/>
        </div>
    )
}
