import {ClearRecipes, SearchForRecipe} from "../Store/reducers/recipeReducer";
import {Nav, screenSetter} from "../Store/reducers/globalReducer";
import queryString from "query-string";
import {UpdateSelectedUser} from "../Store/reducers/userReducer";

export function clearAndNav(dispatcher, url, state, searchParams){
    let searchText = queryString.stringify(searchParams);
    dispatcher({type: UpdateSelectedUser, payload:null})
    dispatcher({type: ClearRecipes})
    dispatcher({type: SearchForRecipe, payload:null})
    dispatcher({type: Nav, payload:{path:url, state:state, search:searchText}})
}

export function nav(dispatcher, url, state, searchParams){
    let searchText = queryString.stringify(searchParams);
    dispatcher({type: Nav, payload:{path:url, state:state, search:searchText}})
}

export function screenAdjuster(dispatcher, mobile){
    dispatcher({type: screenSetter, payload:mobile})
}