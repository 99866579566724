import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import {Box, Button, Icon, TextField, Typography} from '@material-ui/core';
import {AccessTime, Face, Kitchen, Visibility} from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    card: {
        width: 500,
        margin: 30,
        borderRadius: 20,

    },
    CardContent: {
        display: 'flex',
        padding: '10%',
    },
    media: {
        display: 'Block',
        margin: 'auto',
        width: 200,
        height: 225,
        borderRadius: 20,
    },
    mediaDetails: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        display: 'flex',
        justifyContent: 'space-around',
        background: '#F0F0F0',
    },
    mediaDetail: {

        paddingTop: 10,
        width: 35,
        textAlign: 'center',
        flexDirection: 'column',
    },
    about: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexGrow: 1,
        marginBottom: 10
    },
    left: {
        borderRadius: 20,
        flexBasis: '33.33%',
        display: 'flex',
    },
    right: {
        flex: 1,

        flexDirection: 'column',
        paddingLeft: 20,
        display: 'flex',

    },
    button: {
        width: "100px",
        height: "100%"
    },
    buttons: {
        display: "flex",
    }

}));

export default function PresentableRecipeCardBig({
                                                     commands,
                                                     recipe,
                                                     basketClick,
                                                     recipeClick,
                                                     userClick,
                                                     update,
                                                     inBasket
                                                 }) {
    const classes = useStyles();
    const {recipeName, timeInMinutes, imageData, views, ingredients, reqPortions} = recipe

    return (
        <Card variant="outlined" className={classes.card}>
            <CardContent className={classes.CardContent}>
                <div className={classes.left}>
                    <CardMedia
                        className={classes.media}
                        image={imageData}
                        title={recipeName}
                    >
                        <div className={classes.mediaDetails}>
                            <div className={classes.mediaDetail}>
                                <Icon color="secondary">
                                    <AccessTime/>
                                </Icon>
                                <Typography>{timeInMinutes}</Typography>
                            </div>
                            <div className={classes.mediaDetail}>
                                <Icon color="secondary">
                                    <Kitchen/>
                                </Icon>
                                <Typography>{ingredients.length}</Typography>
                            </div>
                            <div className={classes.mediaDetail}>
                                <Icon color="secondary">
                                    <Visibility/>
                                </Icon>
                                <Typography>{views}</Typography>
                            </div>
                            <div className={classes.mediaDetail}>
                                <Icon color="secondary">
                                    <Face/>
                                </Icon>
                                <TextField
                                    id="standard-number"
                                    type="number"
                                    inputProps={{min: 0, max: 99, style: {textAlign: 'center'}}}
                                    value={reqPortions}
                                    onChange={(x) => update({reqPortions: x.target.value})}
                                />
                            </div>

                        </div>
                    </CardMedia>
                </div>
                <div className={classes.right}>
                    <Typography variant="h6" color="textPrimary" component="p">
                        {recipeName}
                    </Typography>
                    <Button>
                        <Typography onClick={() => userClick(recipe.user.id)} variant="caption" color="textPrimary" component="p">
                            Recipe By: {recipe.user ? recipe.user.username : "N/A"}
                        </Typography>
                    </Button>
                    <Typography className={classes.about} variant="body2" color="textSecondary" component="p">
                        {recipe.about}
                    </Typography>
                    <div className={classes.buttons}>
                        <Box mr={"10px"}>
                            {<Button variant="contained" className={classes.button} color="secondary"
                                     onClick={recipeClick}>View Recipe</Button>}
                        </Box>
                        <Box ml={"10px"}>
                            {inBasket && <Button className={classes.button} variant="contained" color="primary"
                                                 onClick={basketClick}>Remove From Basket</Button>}
                            {!inBasket && <Button className={classes.button} variant="contained" color="secondary"
                                                  onClick={basketClick}>Add To Basket</Button>}
                        </Box>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
