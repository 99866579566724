import {pullLabels, pullPublicRecipes, pullSingleRecipe, pullUserRecipes, searchRecipeData} from "./recipeData";
import {pullImage} from "../photo/photoData";
import {pullViews} from "../view/viewData";
import {pullUsers} from "../User/userData";
import {
    AddRecipeToRecipes,
    ClearRecipes,
    EditIngredientOnSelected,
    EditSelected,
    MoreRecipes,
    RemoveRecipeFromRecipes,
    SearchForRecipe,
    SetRecipeUpdating,
    UpdatePageNum,
    UpdateSelectedRecipe
} from "../../Store/reducers/recipeReducer";
import {addAlert} from "../../Store/reducers/alertReducer";

export function selectRecipe(recipeId, reqPortions, dispatcher) {
    dispatcher({type: SetRecipeUpdating})
    populateSingleRecipe(recipeId, reqPortions, dispatcher, UpdateSelectedRecipe)
}

export function editSelectedRecipe(dispatcher, editedParams) {
    dispatcher({type: EditSelected, payload: editedParams})
}

export function editIngredient(dispatcher, editedParams) {
    dispatcher({type: EditIngredientOnSelected, payload: editedParams})
}

export function prepareRecipeForEditing(dispatcher, recipe) {
    let adjustedIngredients = [...recipe.ingredients];
    for (var i = 0; i < adjustedIngredients.length; i++) {
        adjustedIngredients[i].qty = adjustedIngredients[i].qty * (recipe.reqPortions / recipe.portions)
    }
    const editedParams = {portions: recipe.reqPortions, editMode: true, ingredients: adjustedIngredients}
    dispatcher({type: EditSelected, payload: editedParams})
}

export function deleteRecipeFromCache(recipeId, dispatcer) {
    dispatcer({type: RemoveRecipeFromRecipes, payload: recipeId})
}

export function populateSingleRecipe(recipeId, reqPortions, dispatcher, action) {
    pullSingleRecipe(recipeId).then(
        ([success, data]) => {
            if(!success){

                addAlert(dispatcher, "Could not pull Recipe: "+ data.message, "error")
            }
            else {
                pullAllRecipeDetails(data[0], reqPortions).then(
                    populatedRecipe =>
                        dispatcher({type: action, payload: populatedRecipe})
                )
            }
        }
    )
}

export function populatePublicRecipes(dispatcher, action) {
    populateRecipes(pullPublicRecipes, dispatcher, action)
}

export function populateUserRecipes(userId, dispatcher, action) {
    populateRecipes(() => pullUserRecipes(userId), dispatcher, action)
}

export function populateRecipes(pullFunction, dispatcher, action) {
    pullFunction().then(
        (recipes) => recipes.forEach(recipe => pullAllRecipeDetails(recipe).then(
            populatedRecipe => dispatcher({type: action, payload: populatedRecipe})
        )));
}

export function lookupRecipes(search,pageNumber, dispatcher, x) {

    searchRecipeData(search.term, search.type,search.id, 'NONE', pageNumber)
        .then(
            (response) => {
                dispatcher({type: UpdatePageNum, payload: response.currentPage + 1});
                dispatcher({type: MoreRecipes, payload: response.hasMore});
                if (response.recipes) {
                    response.recipes.forEach(recipe => pullAllRecipeDetails(recipe).then(
                        populatedRecipe => dispatcher({type: AddRecipeToRecipes, payload: populatedRecipe})
                    ))
                }
            })
}

export async function lookupRecipesAsync(search, pageNumber, dispatcher, x) {
    let response = await searchRecipeData(search.term, search.type, search.id, 'NONE', pageNumber)
    dispatcher({type: ClearRecipes})
    dispatcher({type: UpdatePageNum, payload: response.currentPage + 1});
    dispatcher({type: MoreRecipes, payload: response.hasMore});
    if (response.recipes) {
        let mapOfPromises = response.recipes.map(recipe => pullAllRecipeDetails(recipe).then(
            populatedRecipe => dispatcher({type: AddRecipeToRecipes, payload: populatedRecipe})));
        await Promise.all(mapOfPromises)
    }
}


export function searchRecipeDebounce(search, dispatcher, id, newPage, history) {
    if(search.includes(":")){
        let split = search.split(":");
        let param = {type: split[0], term:split[1],id:id}
        dispatcher({type: SearchForRecipe, payload: param })
    }
    else{
        dispatcher({type: SearchForRecipe, payload: {type: "ALL", term: search,id:id}})
    }
    if(newPage){
        history.push(newPage)
    }
}

export function searchRecipe(search, dispatcher, id, newPage, history) {
    if(search.includes(":")){
        let split = search.split(":");
        let param = {type: split[0], term:split[1],id:id}
        dispatcher({type: SearchForRecipe, payload: param })
    }
    else{
        dispatcher({type: SearchForRecipe, payload: {type: "ALL", term: search,id:id}})
    }
    if(newPage){
        history.push(newPage)
    }
}

export function filterRecipe(recipe, search) {
    if (search) {
        search = search.toLowerCase()
        return recipe.recipeName.toLowerCase().includes(search) ||
            recipe.ingredients.some(ingredient => ingredient.ingredientName.toLowerCase().includes(search))
    } else
        return (true)

}

export async function getLabelOptions() {
    let labels = await pullLabels();
    let labelOptions = labels.map(label => ({label: label, value: label}));
    return labelOptions;
}


async function pullAllRecipeDetails(recipe, reqPortions) {
    if (reqPortions) {
        recipe.reqPortions = reqPortions
    } else {
        recipe.reqPortions = recipe.portions
    }
    let results = await Promise.all([pullImage(recipe.imageId),
        pullViews(recipe.id, "RECIPE", 1440),
        pullUsers(recipe.userId)]);

    if (results[0]) {
        recipe.imageData = "data:image/png;base64," + results[0]
    }
    recipe.views = results[1]
    recipe.user = results[2][0]


    return recipe
}

export function validateDetails(recipe, dispatcher) {
    if (!recipe.about) {
        addAlert(dispatcher, "You have not entered a recipe description!", "error")
        return false
    }
    if (!recipe.recipeName) {
        addAlert(dispatcher, "No Recipe Name! Are you mad!", "error")
        return false
    }
    if (!recipe.timeInMinutes || recipe.timeInMinutes < 1) {
        addAlert(dispatcher, "You may be able to create this recipe in no time, but i dont think anyone else will!", "error")
        return false
    }
    return true
}

export function validateIngTab(recipe, dispatcher) {
    if (recipe.ingredients.length === 0) {
        addAlert(dispatcher, "You have not entered any ingredients!", "error")
        return false
    }
    if (recipe.ingredients.some(x => x.needsValidating)) {
        addAlert(dispatcher, "Some of your ingredients need validating!", "error")
        return false
    }
    if (!recipe.portions || recipe.portions < 1) {
        addAlert(dispatcher, "Not sure who eats 0 portions...", "error")
        return false
    }
    return true
}


export function validateMethod(recipe, dispatcher) {
    if (!recipe.method) {
        addAlert(dispatcher, "You have not entered a method", "error")
        return false
    }
    return true
}

export function validateRecipe(recipe, dispatcher) {
    if(!validateDetails(recipe, dispatcher))
        return false
    if(!validateIngTab(recipe, dispatcher))
        return false
    if(!validateMethod(recipe,dispatcher))
        return false
    return true;
}
