import React, {useEffect} from 'react'
import {Box} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {populatePublicUsers} from "../../data/User/userHelper";
import UserCard from "./UserCard/UserCard";


export default function UserListAndSearch() {
    const dispatcher = useDispatch();
    const users = useSelector(state=> state.user.users);
    useEffect(() => {
        populatePublicUsers(dispatcher)
    }, [dispatcher])

    return (
        <Box display="flex" flexWrap="wrap"
             justifyContent='space-around'>
            {users.map((user) => {
                return (<UserCard key={user.id} user={user}/>)
            })}
        </Box>
    )
}
